import React, { PureComponent } from "react";
import _ from "lodash";
import { Segment, Statistic, List, Grid, Message } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";

import QuoRadialProgressChart from "../charts/QuoRadialProgressChart.react";
import QuoBlockProgressChart from "../charts/QuoBlockProgressChart.react";

export default class CourseProgressSubPanel extends PureComponent {
    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let pageStyle = {
            noCourseSection: {
                margin: "60px auto",
                width: isPortrait ? "100%" : "60%",
                textAlign: "center"
            },
            completionStyle: {
                background: "grey",
                padding: "10px"
            },
            statusSection: {
                base: {
                    padding: isPortrait ? "10px 0" : "15px 15px 0 15px"
                },
                grid: {
                    margin: "30px 0",
                    color: "#000000"
                },
                statuslabel: {
                    color:
                        this.props.data != null && this.props.data.complete
                            ? "#666666"
                            : "#dddddd"
                },
                status: {
                    fontSize: isPortrait ? "1em" : "1.25em",
                    color:
                        this.props.data != null && this.props.data.complete
                            ? "#000000"
                            : "#ffffff",
                    fontWeight: "bolder"
                }
            },
            highlightsSection: {
                padding: "0"
            },
            accessSection: {
                base: {
                    width: isPortrait ? "100%" : "760px",
                    textAlign: "center",
                    padding: "0 15px"
                },
                message: {
                    width: isPortrait ? "100%" : "660px",
                    fontWeight: "bold"
                }
            },
            summarySection: {
                base: {
                    width: isPortrait ? "100%" : "660px",
                    textAlign: "center",
                    padding: "0"
                },
                radial: {
                    width: isPortrait ? "130px" : "150px",
                    display: "inline-block",
                    padding: "0"
                },
                radialHeader: {
                    margin: "0"
                },
                details: {
                    width: isPortrait ? "100%" : "500px",
                    display: "inline-block",
                    padding: "0 0 0 15px",
                    textAlign: "left",
                    fontWeight: "bold"
                },
                detailHeader: {
                    width: "128px",
                    display: "inline-block"
                }
            }
        };
        if (this.props.data == null) {
            return (
                <div style={pageStyle.noCourseSection}>
                    {isPortrait ?
                        <h3>Select a course to browse the progress data</h3>
                        :
                        <h1>Select a course to browse the progress data</h1>
                    }
                </div>
            );
        } else if (!this.props.data.started) {
            return (
                <Segment basic style={pageStyle.statusSection.base}>
                    <h2>{_.toUpper(this.props.data.name)}</h2>
                    <br />
                    {/* <img src="assets/images/defaults/nodata.jpg" alt="no data" /> */}
                    <h3> The learner has not started this course yet...</h3>
                </Segment>
            );
        } else {
            let hours = Math.floor(this.props.data.time_spent / 3600);
            let minutes = Math.floor(this.props.data.time_spent / 60) % 60;
            let seconds = this.props.data.time_spent % 60;
            let result = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
            return (
                <div>
                    <Segment basic style={pageStyle.statusSection.base}>
                        <h2>{_.toUpper(this.props.data.name)}</h2>
                        {isPortrait ?
                            <div>
                                <div
                                    style={{
                                        background:
                                            this.props.data.complete
                                                ? "olive"
                                                : this.props.data.started
                                                    ? "orange"
                                                    : "red",
                                        padding: "10px"
                                    }}
                                >
                                    <h3>
                                        <span
                                            style={
                                                pageStyle.statusSection
                                                    .statuslabel
                                            }
                                        >
                                            Status:
                                        </span>
                                        <br />
                                        <span
                                            style={
                                                pageStyle.statusSection.status
                                            }
                                        >
                                            {this.props.data.complete
                                                ? "COMPLETE"
                                                : this.props.data.started
                                                    ? "IN PROGRESS"
                                                    : "NOT STARTED"}
                                        </span>
                                    </h3>
                                </div>
                                <br />
                                <div style={pageStyle.completionStyle}>
                                    <h3>
                                        {this.props.data.sequential
                                            ? "Sequentially accessed course content"
                                            : "Freely accessed course content"}
                                    </h3>
                                </div>
                                <br />
                                <div style={pageStyle.completionStyle}>
                                    <h3>
                                        {this.props.data.has_final
                                            ? "Final assessment based completion"
                                            : "Coverage based completion"}
                                    </h3>
                                </div>
                            </div>
                            :
                            <Grid
                                columns={3}
                                celled
                                style={pageStyle.statusSection.grid}
                            >
                                <Grid.Row>
                                    <Grid.Column
                                        color={
                                            this.props.data.complete
                                                ? "olive"
                                                : this.props.data.started
                                                    ? "orange"
                                                    : "red"
                                        }
                                    >
                                        <h3>
                                            <span
                                                style={
                                                    pageStyle.statusSection
                                                        .statuslabel
                                                }
                                            >
                                                Status:
                                            </span>
                                            <br />
                                            <span
                                                style={
                                                    pageStyle.statusSection.status
                                                }
                                            >
                                                {this.props.data.complete
                                                    ? "COMPLETE"
                                                    : this.props.data.started
                                                        ? "IN PROGRESS"
                                                        : "NOT STARTED"}
                                            </span>
                                        </h3>
                                    </Grid.Column>
                                    <Grid.Column color="grey">
                                        <h3>
                                            {this.props.data.sequential
                                                ? "Sequentially accessed course content"
                                                : "Freely accessed course content"}
                                        </h3>
                                    </Grid.Column>
                                    <Grid.Column color="grey">
                                        <h3>
                                            {this.props.data.has_final
                                                ? "Final assessment based completion"
                                                : "Coverage based completion"}
                                        </h3>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>}
                    </Segment>
                    <Segment basic style={pageStyle.summarySection.base}>
                        <Segment basic style={pageStyle.summarySection.radial}>
                            {isPortrait ?
                                <h4 style={pageStyle.summarySection.radialHeader}>
                                    Content <br /> Coverage
                                </h4>
                                :
                                <h3 style={pageStyle.summarySection.radialHeader}>
                                    Content <br /> Coverage
                                </h3>
                            }
                            <QuoRadialProgressChart
                                width={isPortrait ? 130 : 150}
                                height={isPortrait ? 110 : 130}
                                color={getAppConfig().colors.accents.primary}
                                value={this.props.data.content_coverage}
                                top={55}
                                left={67}
                            />
                        </Segment>
                        <Segment basic style={pageStyle.summarySection.details}>
                            <List>
                                <List.Item>
                                    <div
                                        style={
                                            pageStyle.summarySection
                                                .detailHeader
                                        }
                                    >
                                        Content Decks
                                    </div>
                                    <QuoBlockProgressChart
                                        maxBlocks={
                                            this.props.data.deck_status.qdfs
                                                .count
                                        }
                                        complete={
                                            this.props.data.deck_status.qdfs
                                                .complete
                                        }
                                        color={
                                            getAppConfig().colors.accents
                                                .primary
                                        }
                                    />
                                </List.Item>
                                <List.Item>
                                    <div
                                        style={
                                            pageStyle.summarySection
                                                .detailHeader
                                        }
                                    >
                                        SCORM Content
                                    </div>
                                    <QuoBlockProgressChart
                                        maxBlocks={
                                            this.props.data.deck_status
                                                .scormdecks.count
                                        }
                                        complete={
                                            this.props.data.deck_status
                                                .scormdecks.complete
                                        }
                                        color={
                                            getAppConfig().colors.accents
                                                .primary
                                        }
                                    />
                                </List.Item>
                                <List.Item>
                                    <div
                                        style={
                                            pageStyle.summarySection
                                                .detailHeader
                                        }
                                    >
                                        PDF Documents
                                    </div>
                                    <QuoBlockProgressChart
                                        maxBlocks={
                                            this.props.data.deck_status.docdecks
                                                .count
                                        }
                                        complete={
                                            this.props.data.deck_status.docdecks
                                                .complete
                                        }
                                        color={
                                            getAppConfig().colors.accents
                                                .primary
                                        }
                                    />
                                </List.Item>
                                <List.Item>
                                    <div
                                        style={
                                            pageStyle.summarySection
                                                .detailHeader
                                        }
                                    >
                                        Learning Videos
                                    </div>
                                    <QuoBlockProgressChart
                                        maxBlocks={
                                            this.props.data.deck_status.videcks
                                                .count
                                        }
                                        complete={
                                            this.props.data.deck_status.videcks
                                                .complete
                                        }
                                        color={
                                            getAppConfig().colors.accents
                                                .primary
                                        }
                                    />
                                </List.Item>
                                <List.Item>
                                    <div
                                        style={
                                            pageStyle.summarySection
                                                .detailHeader
                                        }
                                    >
                                        Assessments
                                    </div>
                                    <QuoBlockProgressChart
                                        maxBlocks={
                                            this.props.data.deck_status
                                                .assessments.count
                                        }
                                        complete={
                                            this.props.data.deck_status
                                                .assessments.complete
                                        }
                                        color={
                                            getAppConfig().colors.accents
                                                .primary
                                        }
                                    />
                                </List.Item>
                                <List.Item>
                                    <div
                                        style={
                                            pageStyle.summarySection
                                                .detailHeader
                                        }
                                    >
                                        Surveys
                                    </div>
                                    <QuoBlockProgressChart
                                        maxBlocks={
                                            this.props.data.deck_status.surveys
                                                .count
                                        }
                                        complete={
                                            this.props.data.deck_status.surveys
                                                .complete
                                        }
                                        color={
                                            getAppConfig().colors.accents
                                                .primary
                                        }
                                    />
                                </List.Item>
                                <List.Item>
                                    <div
                                        style={
                                            pageStyle.summarySection
                                                .detailHeader
                                        }
                                    >
                                        Games
                                    </div>
                                    <QuoBlockProgressChart
                                        maxBlocks={
                                            this.props.data.deck_status.games
                                                .count
                                        }
                                        complete={
                                            this.props.data.deck_status.games
                                                .complete
                                        }
                                        color={
                                            getAppConfig().colors.accents
                                                .primary
                                        }
                                    />
                                </List.Item>
                                <List.Item>
                                    <div
                                        style={
                                            pageStyle.summarySection
                                                .detailHeader
                                        }
                                    >
                                        Certificates
                                    </div>
                                    <QuoBlockProgressChart
                                        maxBlocks={
                                            this.props.data.deck_status
                                                .certdecks.count
                                        }
                                        complete={
                                            this.props.data.deck_status
                                                .certdecks.complete
                                        }
                                        color={
                                            getAppConfig().colors.accents
                                                .primary
                                        }
                                    />
                                </List.Item>
                            </List>
                        </Segment>
                    </Segment>
                    <Segment basic style={pageStyle.accessSection.base}>
                        {this.props.data.complete && (
                            <Message
                                positive
                                style={pageStyle.accessSection.message}
                            >
                                Course completed on{" "}
                                {new Date(
                                    this.props.data.completed_at
                                ).toLocaleString("en-IN")}{" "}
                            </Message>
                        )}
                        {!this.props.data.started && (
                            <Message
                                negative
                                style={pageStyle.accessSection.message}
                            >
                                This course has not been started yet
                            </Message>
                        )}
                        {!this.props.data.complete && this.props.data.started && (
                            <Message
                                positive={
                                    new Date() -
                                    new Date(
                                        this.props.data.last_accessed_at
                                    ) <
                                    604800000
                                }
                                negative={
                                    this.props.data.last_accessed_at === null ||
                                    new Date() -
                                    new Date(
                                        this.props.data.last_accessed_at
                                    ) >
                                    604800000
                                }
                                style={pageStyle.accessSection.message}
                            >
                                {this.props.data.last_accessed_at === null ? (
                                    <div>
                                        Access data is currently unavailable
                                    </div>
                                ) : (
                                    <div>
                                        Last accessed the platform on{" "}
                                        {new Date(
                                            this.props.data.last_accessed_at
                                        ).toLocaleString("en-IN")}{" "}
                                    </div>
                                )}
                            </Message>
                        )}
                    </Segment>
                    <Segment basic style={pageStyle.highlightsSection}>
                        <Statistic.Group widths="three" size={isPortrait ? "tiny" : {}}>
                            <Statistic>
                                <Statistic.Value>
                                    {this.props.data.view_attempts}
                                </Statistic.Value>
                                <Statistic.Label>Views</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value
                                    text
                                    style={{ width: isPortrait ? "" : "180px", margin: "0 auto", paddingTop: "5%" }}
                                >
                                    {result}
                                </Statistic.Value>
                                <Statistic.Label>Time Spent</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {this.props.data.points}
                                </Statistic.Value>
                                <Statistic.Label>
                                    {isPortrait ? "Points" : "Points Collected"}
                                </Statistic.Label>
                            </Statistic>
                        </Statistic.Group>
                    </Segment>
                </div >
            );
        }
    }
}
