import React from "react";
import Separator from "../../contentBlocks/Separator.react";
import _ from "lodash";
import Text from "../../contentBlocks/Text.react";
import { Button } from "config/initializers/styles.app";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import { getAppConfig } from "config/client.config";
import { Translations } from "config/app.translations";
export default class InternalLink extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        color: PropTypes.string,
    };

    handleClick = /* istanbul ignore next - Action Creator */ () => {
        if (this.props.gotoSlide) {
            this.props.gotoSlide(this.props.data.slideLink, true);
        }
    };

    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: "80%",
            textAlign: "center",
            paddingTop: "50px",
        };
        let defaultLandscapeStyle = {
            margin: "50px auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
            padding: "25px",
        };
        let buttonStyle = {
            marginTop: "10px",
        };
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div style={defaultStyle}>
                            <Text
                                keyName="paragraph"
                                text={this.props.data.paragraph}
                                color={this.props.colors.baseText}
                                fontSize="large"
                                animation="fadeIn,100"
                            />
                            <Separator
                                keyName="separator"
                                color={this.props.colors.primary}
                                animation="fadeIn,100"
                            />
                            <Button
                                primary
                                fluid
                                size="huge"
                                onClick={this.handleClick}
                                style={{ display: "initial" }}
                            >
                                Go
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    backgroundColor: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <Text
                                        keyName="paragraph"
                                        text={this.props.data.paragraph}
                                        color={this.props.colors.baseText}
                                        fontSize="large"
                                        animation="transition.expandIn,500,600"
                                    />
                                    <br />
                                    <a
                                        href={this.props.data.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button
                                            primary
                                            fluid
                                            size="huge"
                                            style={buttonStyle}
                                            onClick={this.handleClick}
                                        >
                                            {
                                                _.find(Translations, {
                                                    language: getAppConfig()
                                                        .language,
                                                }).templateActions.go
                                            }
                                        </Button>
                                    </a>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
