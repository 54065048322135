import React, { PureComponent } from "react";
import _ from "lodash";
import { Segment } from "semantic-ui-react";
import { Loading } from "../../../appComponents/loading/Loading.react.jsx";
import ErrorPage from "common/globals/error/ErrorPage.react.jsx";
import ReactTable from "react-table";
import "react-table/react-table.css";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";

const dateOpts = {
    year: "numeric",
    month: "short",
    day: "numeric",
};
const timeOpts = {
    hour: "2-digit",
    minute: "2-digit",
};

export default class ActivityLog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fetchCompleted: false,
            error: null,
            logData: null,
        };
    }
    componentDidMount() {
        this.props.actions
            .fetchLearnerLog(this.props.user.id)
            .then((data) => {
                this.setState({
                    fetchCompleted: true,
                    logData: _.reverse(data),
                });
            })
            .catch(
                /* istanbul ignore next - tested in the render */(e) => {
                    this.setState({
                        fetchCompleted: true,
                        error: true,
                    });
                }
            );
    }
    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let pageStyle = {
            base: {
                width: isPortrait ? "100%" : "720px",
                margin: isPortrait ? "50px 0px" : "120px 40px",
                padding: isPortrait ? "10px" : "50px",
            },
            tableHeader: {
                background: "#767676",
                color: "#ffffff",
            },
            header: {
                background: getAppConfig().colors.accents.primary,
                color: "#000000",
            },
        };
        let fullColumns = [
            {
                Header: "Event Time",
                accessor: "time",
                Cell: ({ value }) =>
                    `${isPortrait ?
                        new Date(value).toLocaleDateString(
                            dateOpts
                        )
                        :
                        new Date(value).toLocaleDateString(
                            "en-US",
                            dateOpts
                        )} ${new Date(value).toLocaleTimeString(
                            "en-US",
                            timeOpts
                        )}`,
                style: { textAlign: "left" },
                width: 160,
            },
            {
                Header: "Event",
                accessor: "event",
                Cell: ({ value }) => value,
                style: { textAlign: "left", whiteSpace: "unset" },
            },
        ];
        if (!this.state.fetchCompleted) return <Loading />;
        else if (this.state.fetchCompleted && !this.state.error) {
            return (
                <Segment>
                    <Segment raised style={pageStyle.base}>
                        <ReactTable
                            ref={(r) => (this.reactTable = r)}
                            data={this.state.logData}
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id;
                                return row[id] !== undefined
                                    ? String(row[id])
                                        .toLowerCase()
                                        .includes(filter.value.toLowerCase())
                                    : true;
                            }}
                            columns={fullColumns}
                            pageSizeOptions={[5, 10]}
                            defaultPageSize={10}
                            className="-striped -highlight activity-table"
                        />
                    </Segment>
                </Segment>
            );
        } else {
            return <ErrorPage msg="Sorry, could not fetch the content" />;
        }
    }
}
