import React from "react";
import PropTypes from "prop-types";
import Text from "./Text.react";
import { VelocityTransitionGroup } from "velocity-react";
class Caption extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string,
            primary: PropTypes.string,
            primaryVariation: PropTypes.string,
            secondary: PropTypes.string,
            baseText: PropTypes.string,
            primaryText: PropTypes.string,
            secondaryText: PropTypes.string,
        }).isRequired,
        text: PropTypes.string.isRequired,
        animation: PropTypes.string,
        textAlign: PropTypes.string,
    };

    static defaultProps = {
        animation: "fadeIn, 500",
        textAlign: "center",
        fontSize: "large",
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let captionStyle = {
            marginTop: "10px",
            padding: isPortrait ? "10px 20px" : "0",
            width: "100%",
            textAlign: this.props.textAlign,
        };
        let animation = this.props.animation.split(",");

        return (
            <VelocityTransitionGroup
                enter={{
                    animation: animation[0],
                    duration: animation[1],
                    delay: animation[2],
                }}
                leave={{ animation: "fadeOut", duration: "0" }}
                runOnMount
            >
                <div style={captionStyle}>
                    <Text
                        keyname="caption-text"
                        text={this.props.text}
                        color={this.props.colors.baseText}
                        fontSize={this.props.fontSize}
                        animation="fadeIn,100"
                    />
                </div>
            </VelocityTransitionGroup>
        );
    }
}

export default Caption;
