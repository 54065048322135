// Import from NPM
// -------------------------------------
import React from "react";
import PropTypes from "prop-types";
import Text from "./Text.react";
import DividerIcon from "./DividerIcon";
import { resolveImage } from "config/app.helpers";
import { ComponentInputs } from "schema/FormSchema.react";
export default class SlideHeader extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        height: PropTypes.string,
        fontSize: PropTypes.string,
    };

    static defaultProps = {
        title: "",
        subtitle: "",
        height: undefined,
        fontSize: "large",
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let hasTitle = this.props.title.length > 0 && this.props.title !== " ";
        let hasSubtitle =
            hasTitle &&
            this.props.subtitle.length > 0 &&
            this.props.subtext !== " ";
        let headerHeight = 0;
        if (this.props.height !== undefined) headerHeight = this.props.height;
        else if (hasTitle && hasSubtitle)
            headerHeight = isPortrait ? "25%" : "100%";
        else if (hasTitle) headerHeight = isPortrait ? "20%" : "";
        else headerHeight = 0;

        let headerContainerStyle = {
            width: "100%",
            height: headerHeight,
            marginBottom: "20px",
        };

        let headerStyle = {
            height: "100%",
            width: "100%",
            backgroundColor: this.props.colors.primary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: hasTitle && hasSubtitle ? (isPortrait ? "6% 0" : "") : "",
        };

        let headerContentStyle = {
            width: "90%",
            fontSize: "1em",
            color: this.props.colors.primaryText,
            padding: isPortrait ? 0 : "30px 0",
        };
        let dividerStyle = {
            display: hasTitle ? "block" : "none",
        };

        let hasPresenter =
            this.props.data.presenter !== undefined &&
            this.props.data.presenter.id !== "default43";
        return (
            <div style={headerContainerStyle}>
                <div style={headerStyle}>
                    <div style={headerContentStyle}>
                        {isPortrait ? (
                            <Text
                                keyName={"header-" + this.props.slideId}
                                text={hasTitle ? this.props.title : ""}
                                color={this.props.colors.primaryText}
                                animation="transition.expandIn,250,250"
                                fontSize={
                                    this.props.height === "60%"
                                        ? this.props.fontSize
                                        : hasTitle &&
                                          this.props.title.length >
                                              ComponentInputs.title.idealLength
                                        ? "large"
                                        : "huge"
                                }
                                isHeader={true}
                                style={{
                                    marginBottom: "0.5em",
                                    padding:
                                        hasTitle && !hasSubtitle ? "30px" : "0",
                                }}
                            />
                        ) : (
                            <Text
                                keyName={"header-" + this.props.slideId}
                                text={hasTitle ? this.props.title : ""}
                                color={this.props.colors.primaryText}
                                animation="transition.expandIn,250,250"
                                fontSize={
                                    this.props.height === "60%"
                                        ? "ultra"
                                        : "huger"
                                }
                                isHeader={true}
                                style={{
                                    marginBottom: "0.5em",
                                    textAlign: "left",
                                }}
                            />
                        )}
                        {isPortrait ? (
                            <Text
                                keyName={"subheader-" + this.props.slideId}
                                text={hasSubtitle ? this.props.subtitle : ""}
                                color={this.props.colors.primaryText}
                                animation="transition.expandIn,250,250"
                                fontSize={
                                    hasSubtitle &&
                                    this.props.subtitle.length >
                                        ComponentInputs.subtitle.idealLength
                                        ? "normal"
                                        : "large"
                                }
                                style={{
                                    textAlign: "center",
                                    width: "90%",
                                    margin: "10px auto",
                                }}
                            />
                        ) : (
                            <Text
                                keyName={"subheader-" + this.props.slideId}
                                text={hasSubtitle ? this.props.subtitle : ""}
                                color={this.props.colors.primaryText}
                                animation="transition.expandIn,250,250"
                                fontSize="large"
                                style={{
                                    textAlign: "left",
                                    width: "80%",
                                    marginTop: "20px",
                                }}
                            />
                        )}
                    </div>
                </div>
                <div style={dividerStyle}>
                    <DividerIcon
                        {...this.props}
                        icon={
                            (this.props.height === "60%" && hasPresenter) ||
                            (!isPortrait && hasPresenter) ||
                            this.props.noIcon
                                ? ""
                                : this.props.data.icon
                        }
                        keyNames={[
                            "divider-" + this.props.slideId,
                            "icon-" + this.props.slideId,
                        ]}
                        animation="transition.bounceLeftIn,500,transition.expandIn,750"
                    />
                </div>
                {hasPresenter && isPortrait && (
                    <img
                        src={resolveImage(
                            this.props.data.presenter.id,
                            this.props.imageLibrary
                        )}
                        alt="Presenter"
                        style={{
                            position: "fixed",
                            bottom: "50px",
                            right: "0",
                            width: this.props.largePresenter ? "70%" : "50%",
                            zIndex: "998",
                        }}
                    />
                )}
            </div>
        );
    }
}
