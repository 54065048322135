import React from "react";
import Text from "../../contentBlocks/Text.react";
import PropTypes from "prop-types";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import { Grid } from "semantic-ui-react";
import { resolveImage } from "config/app.helpers";
import { ComponentInputs } from "schema/FormSchema.react";

export default class Paragraph extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            paragraph: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        textAlign: PropTypes.string,
        mobileView: PropTypes.bool,
        desktopView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let hasPresenter =
            this.props.data.presenter !== undefined &&
            this.props.data.presenter.id !== "default43";
        let defaultStyle = {
            position: "relative",
            margin: isPortrait ? (hasPresenter ? "0" : "0px auto") : "0 30px",
            width: isPortrait
                ? hasPresenter
                    ? "55%"
                    : "90%"
                : !isPortrait && !hasPresenter
                ? "90%"
                : "100%",
            textAlign: isPortrait && !hasPresenter ? "center" : "left",
            height: isPortrait ? "auto" : "100%",
            minHeight: isPortrait ? "0" : "190px",
            padding: isPortrait
                ? hasPresenter
                    ? "15px"
                    : "15px 0"
                : this.props.desktopView
                ? "10px 0"
                : "40px 0",
            fontSize:
                hasPresenter && this.props.data.paragraph.length > 128
                    ? "0.75em"
                    : "1em",
        };
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                            largePresenter={this.props.data.title === ""}
                        />
                        <div style={defaultStyle}>
                            <Text
                                keyName={"paragraph-" + this.props.slideId}
                                text={this.props.data.paragraph}
                                color={this.props.colors.baseText}
                                animation="transition.slideDownIn,1000,750"
                                fontSize={
                                    this.props.data.title !== "" &&
                                    this.props.data.paragraph &&
                                    this.props.data.paragraph.length >
                                        ComponentInputs.paragraph.idealLength
                                        ? "normal"
                                        : "large"
                                }
                            />
                        </div>
                    </div>
                ) : (
                    <Grid style={{ height: "100%" }}>
                        <Grid.Row>
                            <SlideHeader
                                {...this.props}
                                title={this.props.data.title}
                                subtitle={this.props.data.subtitle}
                            />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={10}>
                                <div style={defaultStyle}>
                                    <Text
                                        keyName={
                                            "paragraph-" + this.props.slideId
                                        }
                                        text={this.props.data.paragraph}
                                        color={this.props.colors.baseText}
                                        animation="transition.slideDownIn,1000,750"
                                        fontSize={"large"}
                                    />
                                </div>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                {hasPresenter && (
                                    <img
                                        src={resolveImage(
                                            this.props.data.presenter.id,
                                            this.props.imageLibrary
                                        )}
                                        alt="Presenter"
                                        style={{
                                            position: "absolute",
                                            width: "85%",
                                            zIndex: "999",
                                            bottom: "20px",
                                            left: "35px",
                                        }}
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
