import React from "react";
import PropTypes from "prop-types";
import { Button } from "config/initializers/styles.app";
import _ from "lodash";

import { Translations } from "config/app.translations";

import { getAppConfig } from "config/client.config";
import { VelocityTransitionGroup } from "velocity-react";

import HandleButton from "./HandleButton.react";

export default class HandleButtonGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bullets: this.props.bullets,
            items: _.shuffle(this.props.bullets)
        };
    }

    static propTypes = {
        bullets: PropTypes.array,
        animation: PropTypes.string,
        callback: PropTypes.func,
        purpose: PropTypes.string
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.bullets !== prevState.bullets) {
            return {
                bullets: nextProps.bullets,
                items: _.shuffle(nextProps.bullets)
            };
        }
        return null;
    }

    swap(to, from) {
        let swapArray = this.state.items;
        let b = swapArray[to];
        swapArray[to] = swapArray[from];
        swapArray[from] = b;
        this.setState({ items: swapArray });
    }

    upClick = index => {
        if (index > 0 && index <= this.state.items.length - 1) {
            this.swap(index, index - 1);
        }
    };
    downClick = index => {
        if (index >= 0 && index < this.state.items.length - 1) {
            this.swap(index, index + 1);
        }
    };
    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let submitStyle = {
            ...getAppConfig().secondaryButtonStyle
        };
        let animation = this.props.animation.split(",");
        let getOptions = _.map(this.state.items, (option, index) => {
            return (
                <HandleButton
                    key={"handle-button-" + index}
                    sequence={index}
                    text={typeof option === "string" ? option : option.text}
                    year={option.year}
                    colors={this.props.colors}
                    upClick={this.upClick}
                    downClick={this.downClick}
                />
            );
        });
        let fullHeight = {
            height: "100%",
            width: "100%",
            padding: isPortrait ? "0px" : "auto"
        };
        let templateTranslations = _.find(Translations, {
            language: "en"
        }).templateActions;

        return (
            <div style={fullHeight}>
                <VelocityTransitionGroup
                    enter={{
                        animation: animation[0],
                        duration: animation[1],
                        stagger: animation[2]
                    }}
                    leave={{ animation: "fadeOut", duration: "0" }}
                    runOnMount
                >
                    {getOptions}
                </VelocityTransitionGroup>
                <br />
                <VelocityTransitionGroup
                    enter={{
                        animation: "transition.bounceUpIn",
                        duration: "500",
                        delay: this.props.bullets.length * 500
                    }}
                    leave={{ animation: "fadeOut", duration: "0" }}
                    runOnMount
                >
                    <Button
                        onClick={() => this.props.callback(this.state.items)}
                        style={submitStyle}
                    >
                        {/*istanbul ignore next - Initialization function only */
                        this.props.purpose === "quiz"
                            ? templateTranslations.checkAnswer
                            : templateTranslations.submitAnswer}
                    </Button>
                </VelocityTransitionGroup>
            </div>
        );
    }
}
