import React from "react";
import Text from "../../contentBlocks/Text.react";
import VideoBox from "globals/videobox/VideoBox.react";
import PropTypes from "prop-types";

export default class VideoFullParagraph extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            paragraph: PropTypes.string,
            video: PropTypes.string,
        }).isRequired,
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
        }).isRequired,
        slideId: PropTypes.number,
    };
    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let layoutStyle = {
            height: "100%",
            width: "100%",
            position: "relative",
        };

        let videoboxStyle = {
            width: "100%",
            height: "100%",
            position: "relative",
            overflow: "hidden",
        };

        let overlayStyle = {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: this.props.colors.primary,
            opacity: "0.65",
        };

        let headerStyle = {
            position: "absolute",
            top: "50%",
            left: "0",
            textAlign: "center",
            background: this.props.colors.base,
            color: this.props.colors.baseText,
            transform: "translate(0,-50%)",
            padding: "20px",
            opacity: "0.85",
        };

        return (
            <div className="full-height">
                <div style={layoutStyle} className="full-video-main">
                    <div style={videoboxStyle}>
                        <VideoBox
                            url={this.props.data.video}
                            autoplay={true}
                            loop={true}
                            hideButtons={true}
                        />
                    </div>
                    <div style={overlayStyle} />
                    <div style={headerStyle}>
                        <Text
                            keyName={"paragraph-" + this.props.slideId}
                            text={this.props.data.paragraph}
                            color={this.props.colors.baseText}
                            fontSize={isPortrait ? "big" : "large"}
                            animation="transition.shrinkIn,500,500"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
