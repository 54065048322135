import React from "react";
import PropTypes from "prop-types";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import { resolveImage } from "config/app.helpers";

export default class Title extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
        }).isRequired,
        fontSize: PropTypes.string,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let hasPresenter =
            this.props.data.presenter !== undefined &&
            this.props.data.presenter.id !== "default43";

        return (
            <div className="full-height">
                <SlideHeader
                    {...this.props}
                    title={this.props.data.title}
                    fontSize={isPortrait ? "huge" : "ultra"}
                    height="60%"
                    largePresenter={true}
                />
                {hasPresenter && !isPortrait && (
                    <img
                        src={resolveImage(
                            this.props.data.presenter.id,
                            this.props.imageLibrary
                        )}
                        alt="Presenter"
                        style={{
                            position: "absolute",
                            left: "65%",
                            width: "35%",
                            bottom: "50px",
                            zIndex: "998",
                        }}
                    />
                )}
            </div>
        );
    }
}
