function fetchMyProfile(user_id) {
  return (dispatch, getState, Request) => {
    return Request.fetch(
      "/api/v2/users/" + user_id + "/myprofile"
    )
      .then(response => {
        return response.json();
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };
}

class MyProfileAction {
  static fetchMyProfile = fetchMyProfile;
}

export { MyProfileAction };
