import React from "react";
import ImageGroup from "../../contentBlocks/ImageGroup.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
export default class Image extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            image: PropTypes.object,
        }).isRequired,
        slideId: PropTypes.number,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: isPortrait ? "-20px auto" : "auto",
            width: "100%",
            textAlign: "center",
            height: "0",
            paddingBottom: "75%",
            overflow: "hidden",
            position: "relative",
        };
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div style={defaultStyle}>
                            <ImageGroup
                                {...this.props}
                                keyName={"image-" + this.props.slideId}
                                size="medium"
                                images={
                                    this.props.data.image === undefined
                                        ? this.props.data.images
                                        : [this.props.data.image]
                                }
                                animation="transition.expandIn,300,100"
                                style={{ height: "100%" }}
                            />
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <ImageGroup
                                    {...this.props}
                                    keyName={"image-" + this.props.slideId}
                                    size="medium"
                                    images={
                                        this.props.data.image === undefined
                                            ? this.props.data.images
                                            : [this.props.data.image]
                                    }
                                    animation="transition.expandIn,300,100"
                                    style={{ height: "100%" }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
