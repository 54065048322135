// Import from NPM
// -------------------------------------
import React from "react";
import { Icon, Image } from "semantic-ui-react";
import { hashHistory } from "react-router";
import { connect } from "react-redux";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";
import { CoursesAction } from "../../../redux/Courses.action.react";

export class Footer extends React.Component {

    goBackOneLevel = /* istanbul ignore next - react-router function */ (a) => {
        hashHistory.push(a)
    };

    render() {
        let path = this.props.routing.locationBeforeTransitions && this.props.routing.locationBeforeTransitions.pathname ?
            this.props.routing.locationBeforeTransitions.pathname : "";

        let readingdeck = path.startsWith("/decks/");

        let barStyle = {
            position: "fixed",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "60px",
            background: this.props.transparent
                ? "transparent"
                : getAppConfig().colors.menuColor,
            zIndex: "1001",
        };
        let backBtnStyle = {
            marginTop: "15px",
            height: "40px",
            fontSize: "1.7em",
            background: "transparent",
            color: getAppConfig().colors.iconColor,
            cursor: "pointer",
        };
        let menuIconStyle = {
            display: "flex",
            gap: "0 36%",
            float: "right",
            marginRight: "26%"
        };
        let logoImageStyle = {
            width: "32%",
            position: "absolute",
            bottom: "12px",
            borderRadius: "10px",
            marginLeft: "3%"
        }

        if (this.props.auth.user && this.props.auth.isUserLoggedIn) {
            return (
                <div style={barStyle}>
                    {!readingdeck && (
                        <Image
                            alt="clientLogo"
                            src={this.props.courses !== undefined && Object.keys(this.props.courses).length !== 0 &&
                                this.props.courses[this.props.auth.user.courses[0]].image !== ""
                                ? `${getAppConfig().apiUrls.apiUrl}${this.props.courses[this.props.auth.user.courses[0]].image}`
                                : "assets/images/configurable/missing_course.jpg"}
                            style={logoImageStyle}
                            onClick={() => {
                                if (this.props.auth.inductor) {
                                    this.goBackOneLevel(`/content/${this.props.inductor}/${this.props.auth.user.courses[0]}`)
                                } else {
                                    this.goBackOneLevel(`/courses/${this.props.auth.user.courses[0]}`);
                                }
                            }}
                        />
                    )}
                    <div style={menuIconStyle}>
                        <Icon
                            name="user"
                            style={backBtnStyle}
                            onClick={() => this.goBackOneLevel("/rewards/profile")}
                        >
                            <div
                                style={{ marginLeft: "-2px", fontSize: "0.45em", marginTop: "2px" }}
                            >
                                Profile
                            </div>
                        </Icon>

                        <Icon
                            name="trophy"
                            style={backBtnStyle}
                            onClick={() => this.goBackOneLevel("/rewards/leaderboard")}
                        >
                            <div style={{ marginLeft: "-7.5px", fontSize: "0.45em", marginTop: "2px" }}>Rankings</div>
                        </Icon>
                        <Icon
                            name="bell"
                            style={backBtnStyle}
                            onClick={() => this.goBackOneLevel("/notifications")}
                        >
                            <div style={{ marginLeft: "-10.5px", fontSize: "0.45em", marginTop: "2px" }}>Messages</div>
                        </Icon>
                    </div>
                </div>
            );
        }
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        courses: state.courses,
        routing: state.routing
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            getLeaderboard: (courseId) => {
                return dispatch(CoursesAction.getLeaderboard(courseId));
            },
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
