import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Document, Page } from "react-pdf";
import { Icon } from "semantic-ui-react";
import { Loading } from "mobile/appComponents/loading/Loading.react";
import { getAppConfig } from "config/client.config";
import 'react-pdf/dist/Page/AnnotationLayer.css';
export default class PdfScroller extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            blobData: "",
            numPages: null,
            pageNumber: 1,
            pageScale: 10,
            showInfo: true,
            fullScreenOpen: false,
            showControl: false,
            noPdfMessage: "loading pdf please wait...",
            seenPages: 0,
        };
        this.messageDom = this.messageDom.bind(this);
    }

    static propTypes = {
        url: PropTypes.string.isRequired,
    };

    componentDidMount() {
        this.fetchAsset()
            .then((blob) => {
                if (blob !== 0) {
                    this.setState({
                        blobData: blob,
                        noPdfMessage: "Loading PDF",
                        fetching: false,
                    });
                }
            })
            .catch((err) => {
                this.setState({ noPdfMessage: "No pdf found.." });
            });
    }

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages }, () =>
            this.props.handleCompletion(
                this.state.seenPages + 1,
                this.state.numPages
            )
        );
    };

    fetchAsset() {
        let pdfData = this.props.url;
        if (pdfData) {
            if (pdfData.indexOf(";base64,") > -1) {
                return Promise.resolve(pdfData);
            } else {
                let deckId = this.props.deckId;
                let url =
                    getAppConfig().apiUrls.assetUrl.endsWith('/') ?
                        `${getAppConfig().apiUrls.assetUrl}public/qdf/documents/${deckId}/${pdfData}.pdf` :
                        `${getAppConfig().apiUrls.assetUrl}/public/qdf/documents/${deckId}/${pdfData}.pdf`;

                return Promise.resolve(url);
            }
        } else {
            return Promise.reject(0);
        }
    }

    messageDom(message) {
        return <div style={{ width: "100vw" }}>{message}</div>;
    }

    handleRangeChange = (e) =>
        this.setState({ pageScale: parseInt(e.target.value, 0) });
    handleFullScreenOpen = () =>
        this.setState({
            fullScreenOpen: !this.state.fullScreenOpen,
            pageScale: 10,
        });
    handleControlOpen = () =>
        this.setState({ showControl: !this.state.showControl });

    handleScroll = (event) => { };

    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let wrapperStyle = {
            height: "100%",
            width: "100%",
            textAlign: "center",
            position: "relative",
            background: "#666",
            overflow: "hidden",
        };
        let defaultStyle = {
            height: "100%",
            width: "100%",
        };
        let controlStyle = {
            position: "fixed",
            bottom: "0",
            height: "7vh",
            padding: "15px 10px",
            width: "100%",
            background: "#333333",
            zIndex: "1004",
            display: this.state.showControl ? "block" : "none",
        };
        let zoomSlider = {
            width: isPortrait ? "85%" : "100%",
            float: "left",
            display: !this.state.fullScreenOpen ? "block" : "none",
        };
        let fullScreenIcon = {
            float: "right",
            color: "white",
        };
        let modalStyle = {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            overflow: "auto hidden",
            padding: "0",
            margin: "0",
            zIndex: "1003",
            background: "#666666",
        };
        let rotated = {
            position: "absolute",
            top: "22vh",
            left: "22vh",
            width: "100vw",
            height: "100vh",
            transform: "rotate(-90deg)",
        };
        let unrotated = {
            display: "block",
            height: "100%",
            overflowY: "scroll",
            overflowX: isPortrait ? "scroll" : "hidden",
        };
        let pdfDocument = (
            <Document
                file={this.state.blobData}
                onLoadSuccess={this.onDocumentLoad}
                loading={this.messageDom("loading pdf please wait...")}
                noData={this.messageDom(this.state.noPdfMessage)}
            >
                {_.times(this.state.numPages, (num) => {
                    return (
                        <Page
                            key={"page-" + num}
                            pageNumber={num + 1}
                            width={
                                this.state.fullScreenOpen
                                    ? window.innerHeight
                                    : isPortrait
                                        ? (window.innerWidth *
                                            this.state.pageScale) /
                                        10
                                        : 900
                            }
                            onClick={this.handleControlOpen}
                        />
                    );
                })}
            </Document>
        );
        if (this.state.fetching) return <Loading />;
        else
            return (
                <div style={wrapperStyle}>
                    <div
                        style={
                            this.state.fullScreenOpen
                                ? modalStyle
                                : defaultStyle
                        }
                        onScroll={this.handleScroll}
                    >
                        <div
                            style={
                                this.state.fullScreenOpen ? rotated : unrotated
                            }
                        >
                            {pdfDocument}
                        </div>
                    </div>
                    {isPortrait && (
                        <div style={controlStyle}>
                            <div style={zoomSlider}>
                                <input
                                    type="range"
                                    min="5"
                                    max="25"
                                    step="5"
                                    value={this.state.pageScale}
                                    className="slider"
                                    onChange={this.handleRangeChange}
                                />
                            </div>
                            <Icon
                                name="expand"
                                size="large"
                                style={fullScreenIcon}
                                onClick={this.handleFullScreenOpen}
                            />
                        </div>
                    )}
                </div>
            );
    }
}
