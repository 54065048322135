// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";

// Import Components
// -------------------------------------
import Text from "../../contentBlocks/Text.react";
import ImageGrid from "../../contentBlocks/ImageGrid.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import PropTypes from "prop-types";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

export default class PickPicture extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            question: PropTypes.string,
            subtitle: PropTypes.string,
            imageOpts: PropTypes.array,
        }).isRequired,
        colors: PropTypes.shape({
            baseText: PropTypes.string.isRequired,
        }).isRequired,
        slideId: PropTypes.number,
        trackInteraction: PropTypes.func.isRequired,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };
    handleClick = (index) => {
        this.props.trackInteraction(index);
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;

        let defaultStyle = {
            margin: "-20px auto",
            width: "100%",
            height: "100%",
            textAlign: "center",
            position: "relative",
        };
        let questionStyle = {
            margin: "20px auto",
            width: "90%",
        };
        let defaultLandscapeStyle = {
            margin: "0.2px auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
        };
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        let imageOpts =
            this.props.data.images !== undefined
                ? this.props.data.images
                : this.props.data.imageOpts;
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={title}
                            subtitle={subtitle}
                        />
                        <div style={defaultStyle}>
                            <ImageGrid
                                {...this.props}
                                keyName={"image-" + this.props.slideId}
                                size="medium"
                                images={_.map(imageOpts, "image")}
                                animation="transition.expandIn,500,500"
                                callback={this.handleClick}
                            />
                            <div style={questionStyle}>
                                <Text
                                    keyName={"question-" + this.props.slideId}
                                    text={this.props.data.question}
                                    color={this.props.colors.baseText}
                                    fontSize="large"
                                    animation="transition.bounceUpIn,500,1500"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingLeft: "0",
                                    paddingBottom: "3%",
                                }}
                            >
                                <div style={defaultLandscapeStyle}>
                                    <ImageGrid
                                        {...this.props}
                                        keyName={"image-" + this.props.slideId}
                                        size="medium"
                                        images={_.map(imageOpts, "image")}
                                        animation="transition.expandIn,500,500"
                                        callback={this.handleClick}
                                    />
                                    <div style={questionStyle}>
                                        <Text
                                            keyName={
                                                "question-" + this.props.slideId
                                            }
                                            text={this.props.data.question}
                                            color={this.props.colors.baseText}
                                            fontSize="large"
                                            animation="transition.bounceUpIn,500,1500"
                                        />
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
