import { REHYDRATE } from "redux-persist/constants";
import {
    CREATE_SESSION,
    RESUME_SESSION,
    SYNC_SESSION,
} from "appRedux/helpers/reduxConstants";
import { aggregateSessionHistory } from "./helpers/aggregatorFunctions";

const initialSessionState = {
    deviceId: "",
    sessionId: "",
    sessionDate: "",
    sessionUser: "",
    sessionOffline: false,
    totalSessions: 0,
    timeSpent: 0,
    sessionResumes: 0,
    sessionAccessors: [],
    sessionHistory: { f: {}, y: {}, q: {}, m: {} },
};

const SessionReducer = (state = initialSessionState, action) => {
    switch (action.type) {
        /* istanbul ignore next - react-persist function */
        case REHYDRATE:
            let session = action.payload.session;
            if (session) {
                return { ...state, ...session };
            } else {
                return state;
            }
        case SYNC_SESSION:
            return Object.assign({}, state, {
                totalSessions: action.data.sessions.sessions,
                timeSpent: action.data.sessions.timeSpent,
            });
        case CREATE_SESSION:
            return Object.assign({}, state, {
                deviceId: action.data.deviceId,
                sessionId: action.data.id,
                sessionDate: action.data.date,
                sessionUser: action.data.user,
                totalSessions: state.totalSessions + 1,
                timeSpent: state.timeSpent,
                sessionResumes: 0,
                sessionAccessors:
                    state.sessionAccessors.indexOf(action.data.user) !== -1
                        ? state.sessionAccessors
                        : [...state.sessionAccessors, action.data.user],
                sessionHistory: aggregateSessionHistory(
                    state.sessionHistory,
                    action.data.sessionDate
                ),
            });
        case RESUME_SESSION:
            return Object.assign({}, state, {
                sessionResumes: state.sessionResumes + 1,
            });
        default:
            return state;
    }
};

export { SessionReducer };
