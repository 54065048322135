import React, { Component } from "react";
import { Statistic, Icon, Divider } from "semantic-ui-react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import { getAppConfig } from "config/client.config";
import { Loading } from "../../../appComponents/loading/Loading.react.jsx";
import { hashHistory } from "react-router";

export class CourseAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchCompleted: false,
            error: "",
            progressData: null,
        };
    }

    componentDidMount() {
        this.props.fetchLearnerProgress(this.props.user.id)
            .then(data => {
                this.setState({
                    fetchCompleted: true,
                    progressData: data,
                });
            }).catch((error) => {
                this.setState({
                    fetchCompleted: true,
                    error: error.response.message
                });
            });
    }

    handleSectionSelection = (filter) => {
        if (filter === "Completed") {
            hashHistory.push(`/articles?category=library&filter=completed`)
        } else if (filter === "Started") {
            hashHistory.push(`/articles?category=library&filter=started`)
        } else {
            hashHistory.push(`/articles?category=library&filter=notstarted`)
        }
    }

    render() {
        let _this = this;

        let colors = [
            getAppConfig().colors.accents.primary,
            getAppConfig().colors.accents.secondary,
            "#d5d5d5"
        ];

        let renderLabelContent = (props) => {
            const { percent, x, y, midAngle } = props;
            if (percent !== 0)
                return (
                    <g transform={`translate(${x}, ${y})`} textAnchor={(midAngle < -90 || midAngle >= 90) ? 'end' : 'start'}>
                        <text x={0} y={20}>{`${(percent * 100).toFixed(0)}%`}</text>
                    </g>
                );
        };

        let previewColor = {
            width: "30px",
            height: "15px",
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: "5px",
            border: "1px solid #ccc"
        };

        let analyticsCard = {
            analyticsMain: {
                minHeight: "15vh",
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                boxShadow: "0 1px 2px 0 #22242626",
                background: "#ffffff",
                paddingBottom: "4%"
            },
            statistic: {
                margin: "4% auto"
            },
            divider: {
                width: "80%",
                margin: "auto"
            },
            pie: {
                margin: "auto"
            },
            preview1: {
                ...previewColor,
                background: colors[0]
            },
            preview2: {
                ...previewColor,
                background: colors[1]
            },
            preview3: {
                ...previewColor,
                background: colors[2]
            },
            pieHelper: {
                fontSize: "10px",
                padding: "4% 8%"
            }
        }

        if (!this.state.fetchCompleted) return <Loading />;
        else if (this.state.fetchCompleted && !this.state.error) {

            let data01 = [
                { name: 'Completed', value: this.state.progressData && this.state.progressData.completed_courses ? this.state.progressData.completed_courses : 0 },
                { name: 'Started', value: this.state.progressData && this.state.progressData.started_courses ? this.state.progressData.started_courses : 0 },
                { name: 'Not Started', value: this.state.progressData && this.state.progressData.notstarted_courses ? this.state.progressData.notstarted_courses : 0 }
            ];

            let showWidget = this.state.progressData &&
                this.state.progressData.enrolled_courses &&
                parseInt(this.state.progressData.enrolled_courses, 10) !== 0 ? true : false;

            return (
                <div>
                    {showWidget && (
                        <div style={analyticsCard.analyticsMain}>
                            <Statistic horizontal style={analyticsCard.statistic}>
                                <Icon name='clipboard' size="huge" />
                                <Statistic.Value>{this.state.progressData.enrolled_courses}</Statistic.Value>
                                <Statistic.Label>Enrolled <br />Courses</Statistic.Label>
                            </Statistic>
                            <Divider style={analyticsCard.divider} />
                            <PieChart width={290} height={240} style={analyticsCard.pie}>
                                <Pie
                                    dataKey="value"
                                    isAnimationActive={true}
                                    data={data01}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={10}
                                    outerRadius={80}
                                    label={renderLabelContent}
                                >
                                    {
                                        data01.map((entry, index) => (
                                            <Cell key={`slice-${index}`} fill={colors[index % 10]} onClick={() => _this.handleSectionSelection(entry.name)} />
                                        ))
                                    }
                                </Pie>
                                <Tooltip />
                            </PieChart>
                            <div style={analyticsCard.pieHelper}>
                                <b> <div style={analyticsCard.preview1} />Completed </b><br />
                                <b> <div style={analyticsCard.preview2} />Started </b><br />
                                <b> <div style={analyticsCard.preview3} />Not Started </b>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return <div></div>
        }
    }
}
