import React from "react";
import PropTypes from "prop-types";
import { Segment, Modal, Item, Icon, Header } from "semantic-ui-react";
import _ from "lodash";
import NotificationItem from "./../components/NotificationItem.react";
import FlexBox from "globals/flexbox/FlexBox.react";

class NotificationWrapper extends React.Component {
    static propTypes = {
        notifications: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired
    };

    static defaultPropTypes = {
        notifications: []
    };

    render() {
        let isPortrait = window.innerHeight > window.innerWidth;

        let _this = this;

        const segmentStyle = {
            minHeight: "100vh",
            marginBottom: 0,
        };

        const containerStyle = {
            marginTop: isPortrait ? "50px" : "0%",
            width: "100%",
            height: isPortrait ? "100%" : "",
            marginLeft: "0",
            overflow: "auto"
        };

        const centerDivForFlex = {
            height: "calc(100vh - 120px - 1.5em)",
            width: isPortrait ? "auto" : "100%"
        };

        let mainContainerStyle = {
            width: isPortrait ? "80%" : "100%",
            padding: isPortrait ? "0% 0% 0% 20%" : "",
        }
        return (
            <div style={containerStyle}>

                {isPortrait && (
                    <Segment
                        basic
                        style={segmentStyle}
                        loading={this.props.loading}
                    >
                        <Item.Group divided unstackable={true}>
                            {!_.isEmpty(this.props.notifications) &&
                                _.map(_.orderBy(this.props.notifications, "createdAt", 'desc'), function (
                                    item,
                                    index
                                ) {

                                    return (
                                        <NotificationItem
                                            key={"noification" + index}
                                            item={item}
                                        />
                                    );
                                })}
                        </Item.Group>
                        {_.isEmpty(this.props.notifications) && (
                            <div style={centerDivForFlex}>
                                <FlexBox>
                                    <Header as="h2" icon textAlign="center">
                                        <Icon name="inbox" circular />
                                        <Header.Content>
                                            You have no notifications right now.
                                            Check after sometime.
                                        </Header.Content>
                                    </Header>
                                </FlexBox>
                            </div>
                        )}
                    </Segment>

                )}

                <div style={mainContainerStyle}>
                    <Modal
                        closeIcon
                        open={this.props.openModal}
                        onClose={this.props.toggleNotification}
                    >
                        <Modal.Content image scrolling>
                            <Item.Group divided unstackable={true}>
                                {!_.isEmpty(this.props.notifications) &&
                                    _.map(_.orderBy(this.props.notifications, "createdAt", 'desc'), function (
                                        item,
                                        index
                                    ) {
                                        return (
                                            <NotificationItem
                                                key={"noification" + index}
                                                item={item}
                                                openModal={_this.props.openModal}
                                                toggleNotification={_this.props.toggleNotification}
                                            />
                                        );
                                    })}
                            </Item.Group>

                            {_.isEmpty(this.props.notifications) && (
                                <div style={centerDivForFlex}>
                                    <FlexBox>
                                        <Header as="h2" icon textAlign="center">
                                            <Icon name="inbox" circular />
                                            <Header.Content>
                                                You have no notifications right now.
                                                Check after sometime.
                                            </Header.Content>
                                        </Header>
                                    </FlexBox>
                                </div>
                            )}
                        </Modal.Content>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default NotificationWrapper;
