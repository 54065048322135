import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { VelocityTransitionGroup } from "velocity-react";
import { resolveImage } from "config/app.helpers";
export default class ImageGroup extends React.Component {
    static propTypes = {
        images: PropTypes.array.isRequired,
        thinLayout: PropTypes.bool,
        effect: PropTypes.string,
        animation: PropTypes.string,
        keyName: PropTypes.string,
        callback: PropTypes.func,
        imageLibrary: PropTypes.array,
    };

    static defaultProps = {
        thinLayout: false,
        effect: "none",
        animation: "fadeIn,1000",
    };

    handleClick = (index) => {
        if (this.props.callback) this.props.callback(index);
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let imageGroupStyle = {
            width: "100%",
            height: "0",
            paddingBottom: this.props.thinLayout ? "50%" : "75%",
            overflow: "hidden",
        };
        let imageStyle = {
            width: this.props.thinLayout
                ? "100%"
                : this.props.images.length * 100 + "%",
            height: "auto",
        };

        let animation = this.props.animation.split(",");
        let getImageList = _.map(this.props.images, (image, index) => {
            let imageContainerStyle = {
                position: "absolute",
                top: "0",
                left: parseInt(100 / this.props.images.length, 0) * index + "%",
                width: parseInt(100 / this.props.images.length, 0) + "%",
                height: isPortrait ? "100%" : "",
                overflow: "hidden",
            };
            return (
                <div key={"image-" + index} style={imageContainerStyle}>
                    <img
                        className={this.props.effect}
                        src={resolveImage(image.id, this.props.imageLibrary)}
                        style={imageStyle}
                        onClick={() => this.handleClick(index)}
                        alt="imageGroup"
                    />
                </div>
            );
        });

        return (
            <div style={imageGroupStyle}>
                <VelocityTransitionGroup
                    enter={{
                        animation: animation[0],
                        duration: animation[1],
                        stagger: animation[2],
                    }}
                    leave={{ animation: "fadeOut", duration: "0" }}
                    runOnMount
                >
                    {getImageList}
                </VelocityTransitionGroup>
            </div>
        );
    }
}
