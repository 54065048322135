// Authentication Triggers
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SESSION = "SESSION";
export const FORGOTPASSW = "FORGOTPASSW";
export const OMNIAUTHLOGIN = "OMNIAUTHLOGIN";
export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const INITIAL_PASSWORD_UPDATE = "INITIAL_PASSWORD_UPDATE";

// Session Triggers
export const SYNC_SESSION = "SYNC_SESSION";
export const CREATE_SESSION = "CREATE_SESSION";
export const RESUME_SESSION = "RESUME_SESSION";

// App Level Triggers
export const FETCH_CONFIG = "FETCH_CONFIG";
export const ACCEPT_TERMS = "ACCEPT_TERMS";
export const VIEWED_PROMO = "VIEWED_PROMO";

// Course Calculation Triggers
export const FETCH_LIBRARY = "FETCH_LIBRARY";
export const FETCH_DECK = "FETCH_DECK";
export const UPDATE_ATTEMPT = "UPDATE_ATTEMPT";
export const COMPLETE_ATTEMPT = "COMPLETE_ATTEMPT";
export const INCOMPLETE_ATTEMPT = "INCOMPLETE_ATTEMPT";
export const AGGREGATE_TOPICS = "AGGREGATE_TOPICS";
export const AGGREGATE_COURSES = "AGGREGATE_COURSES";
export const RESET_DECK_ATTEMPT = "RESET_DECK_ATTEMPT";
export const TERMS_CONDITION_ACCEPTED = "TERMS_CONDITION_ACCEPTED";
export const UPDATE_LEARNER_STATUS = "UPDATE_LEARNER_STATUS";
export const UPDATE_LEARNER_DETAILS = "UPDATE_LEARNER_DETAILS";
export const FETCH_IMAGE = "FETCH_IMAGE";
export const FETCH_AUDIO = "FETCH_AUDIO";

// Program Triggers
export const AGGREGATE_PROGRAM = "AGGREGATE_PROGRAM";
export const SET_LAST_NODE = "SET_LAST_NODE";

// Course Triggers
export const AGGREGATE_SCORM = "AGGREGATE_SCORM";
export const PUSH_SCORM_STATE = "PUSH_SCORM_STATE";
export const SET_LAST_LOCATION = "SET_LAST_LOCATION";
export const SUBMIT_RATING = "SUBMIT_RATING";
export const COURSE_RATED = "COURSE_RATED";

// Offline Handlers
export const FETCH_COURSE = "FETCH_COURSE";
export const DOWNLOAD_COURSE = "DOWNLOAD_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";
export const FETCH_DECK_OFFLINE = "FETCH_DECK_OFFLINE";
export const FETCH_ASSESSMENT_OFFLINE = "FETCH_ASSESSMENT_OFFLINE";

// HelpDesk and Notifications
export const FETCH_TICKET_CENTER = "FETCH_TICKET_CENTER";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATION_COUNT = "FETCH_NOTIFICATION_COUNT";
export const MARK_READ_NOTIFICATIONS = "MARK_READ_NOTIFICATIONS";
export const FETCH_TERMS_CONDITION = "FETCH_TERMS_CONDITION";
export const FETCH_UNREAD_NOTIFICATION_COUNT = "FETCH_UNREAD_NOTIFICATION_COUNT";

// Social Triggers
export const FETCH_COMMENTS = "FETCH_COMMENTS";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const FETCH_FEED = "FETCH_FEED";
export const FETCH_SINGLE_FEED = "FETCH_SINGLE_FEED";
export const MODIFY_FEED = "MODIFY_FEED";
export const TOGGLE_LIKE = "TOGGLE_LIKE";

// Blog Triggers
export const FETCH_BLOG = "FETCH_BLOG";
export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_ADS = "FETCH_ADS";
export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const UPDATE_ARTICLE_EXTRAS = "UPDATE_ARTICLE_EXTRAS";
export const UPDATE_ARTICLE_VIEW = "UPDATE_ARTICLE_VIEW";
export const COMPLETE_ARTICLE_VIEW = "COMPLETE_ARTICLE_VIEW";
export const ADD_ARTICLE_COMMENT = "ADD_ARTICLE_COMMENT";
export const DELETE_ARTICLE_COMMENT = "DELETE_ARTICLE_COMMENT";
export const START_ARTICLE = "START_ARTICLE";
export const FETCH_RECENT_VIEWED = "FETCH_RECENT_VIEWED";

//Gamifix Tiggers
export const BADGE_EARNED = "BADGE_EARNED";
export const MEDAL_EARNED = "MEDAL_EARNED";
export const REWARD_EARNED = "REWARD_EARNED";
export const CLIENT_LOGO = "CLIENT_LOGO";
export const PERFORMANCE_ACCESS = "PERFORMANCE_ACCESS";