import React, { Component } from "react";
import { Icon, Header, Feed } from "semantic-ui-react";
import FlexBox from "globals/flexbox/FlexBox.react";
import _ from "lodash";
import { hashHistory } from "react-router";
import { Button } from "config/initializers/styles.app";
import NotificationContainer from "mobile/notification/NotificationContainer.react";

export class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchCompleted: false,
            error: "",
            notifications: null,
            displayNotification: false
        };
    }

    componentDidMount() {
        this.props.fetchNotifications(this.props.auth.user.id, this.props.content.count)
            .then(data => {
                this.setState({
                    fetchCompleted: true,
                    notifications: data.data
                });
            })
            .catch(error => {
                this.setState({
                    fetchCompleted: true,
                    error: error.response.message
                });
            });
    }

    handleClickEvent = (item) => {
        if (
            item && item.link !== undefined &&
            item.link !== ""
        ) {
            hashHistory.push(item.link);
        }
    }

    toggleDisplayNotification = () =>
        this.setState({ displayNotification: !this.state.displayNotification });

    goToViewAllPage = () => {
        let isPortrait = window.innerHeight > window.innerWidth;
        if (isPortrait) {
            this.props.goToPage(this.props.content.seemoreLink)
        } else {
            this.toggleDisplayNotification();
        }
    }

    render() {
        let _this = this;
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        return (
            <div>
                {!_.isEmpty(this.state.notifications) && (
                    <div style={{ height: "34.8vh", overflow: "hidden" }}>
                        <div style={{ height: "29vh", overflowY: "scroll", width: "273px" }}>
                            {_.map(_.orderBy(this.state.notifications, "createdAt", 'desc'), function (
                                item,
                                index
                            ) {
                                let feedStyle = {
                                    cursor: item.link ? "pointer" : "default",
                                    fontSize: "10px",
                                    lineHeight: "1.6",
                                    borderRadius: "5px",
                                    background: "#ffffff",
                                    border: "1px solid #d3d3d3"
                                };
                                return (
                                    <Feed
                                        key={`feed-${index}`}
                                        size="small"
                                        style={feedStyle}
                                        onClick={() => _this.handleClickEvent(item)}>
                                        <Feed.Event>
                                            <Feed.Label>
                                                <img
                                                    alt="notification"
                                                    src={`assets/images/notificationsImages/${item.type}.jpg`}
                                                    style={{
                                                        width: "70px",
                                                        height: "100%",
                                                        borderRadius: "5px"
                                                    }} />
                                            </Feed.Label>
                                            <Feed.Content style={{ width: "60%", margin: "5% 5% 5% 24%" }}>
                                                <Feed.Summary>{item.title}
                                                    <Feed.Date style={{ textAlign: "end" }}>
                                                        {new Date(item.created_at ?
                                                            item.created_at
                                                            : item.createdAt
                                                        ).toLocaleDateString(undefined, options)}
                                                    </Feed.Date>
                                                </Feed.Summary>
                                                <Feed.Extra>
                                                    {item.description}
                                                </Feed.Extra>
                                            </Feed.Content>
                                        </Feed.Event>
                                    </Feed >
                                );
                            })}
                        </div>
                        <Button
                            style={{ width: "100%" }}
                            content="VIEW ALL NOTIFICATIONS"
                            onClick={_this.goToViewAllPage}
                        />
                    </div>
                )}
                {_.isEmpty(this.state.notifications) && (
                    <FlexBox>
                        <Header as="h4" icon textAlign="center">
                            <Icon name="inbox" circular />
                            <Header.Content>
                                You have no notifications right now.
                            </Header.Content>
                        </Header>
                    </FlexBox>
                )}

                {this.state.displayNotification && (
                    <NotificationContainer
                        openModal={this.state.displayNotification}
                        toggleNotification={this.toggleDisplayNotification}
                    />
                )}
            </div>
        );
    }
}
