import React from "react";
import PropTypes from "prop-types";
import Text from "../../contentBlocks/Text.react";
import { resolveImage } from "config/app.helpers";
import FlexBox from "globals/flexbox/FlexBox.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";

export default class BackgroundImage extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            paragraph: PropTypes.string,
            position: PropTypes.string,
            background: PropTypes.object,
        }).isRequired,
        slideId: PropTypes.number,
        imageLibrary: PropTypes.array.isRequired,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let textPosition =
            this.props.data.position === undefined
                ? "center center"
                : this.props.data.position;
        let position = textPosition.split(" ");
        let topPos =
            position[0] === "top" ? (isPortrait ? "20px" : "40px") : "auto";
        let bottomPos =
            position[0] === "bottom" ? (isPortrait ? "20px" : "80px") : "auto";
        let leftPos = position[1] === "left" ? "0" : "auto";
        let rightPos = position[1] === "right" ? "0" : "auto";

        let defaultStyle = {
            margin: "auto",
            width: "98%",
            textAlign: "center",
            height: isPortrait ? "calc(100% - 50px)" : "calc(84% - 50px)",
            position: "relative",
        };

        let backgroundStyle = {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundImage:
                "url(" +
                resolveImage(
                    this.props.data.background === undefined
                        ? this.props.data.image.id
                        : this.props.data.background.id,
                    this.props.imageLibrary
                ) +
                ")",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        };

        let textAreaStyle = {
            position: "absolute",
            top: topPos,
            left: leftPos,
            bottom: bottomPos,
            right: rightPos,
            width: "80%",
            textAlign: isPortrait ? "center" : "left",
            background: this.props.colors.base,
            padding: "15px",
            opacity:
                this.props.data.paragraph === undefined
                    ? this.props.data.subtitle.length > 0
                        ? 0.85
                        : 0
                    : this.props.data.paragraph.length > 0
                    ? 0.85
                    : 0,
        };
        return (
            <div style={backgroundStyle}>
                <div>
                    <SlideHeader
                        {...this.props}
                        title={this.props.data.title}
                    />
                </div>
                <div style={defaultStyle}>
                    <FlexBox height={"110%"}>
                        <div style={textAreaStyle}>
                            <Text
                                keyName={"subtitle-" + this.props.slideId}
                                text={
                                    this.props.data.paragraph === undefined
                                        ? this.props.data.subtitle
                                        : this.props.data.paragraph
                                }
                                color={this.props.colors.baseText}
                                animation="transition.shrinkIn,500,500"
                                fontSize="large"
                            />
                        </div>
                    </FlexBox>
                </div>
            </div>
        );
    }
}
