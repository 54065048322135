import React from "react";
import { Button } from "config/initializers/styles.app";
import { Grid } from "semantic-ui-react";
import { hashHistory } from "react-router";
import { Loading } from "mobile/appComponents/loading/Loading.react";
import { getAppConfig } from "config/client.config";

export default class ErrorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showErrorPage: false,
        };
    }

    static defaultProps = {
        msgHeader: getAppConfig().error.headerMsg,
        msg: "",
        isPortraitView: false,
        logo: true,
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showErrorPage: true });
        }, 2000);
    }

    goBack = () => {
        if (getAppConfig().error.backLink.indexOf("http") === -1) {
            hashHistory.push(getAppConfig().error.backLink);
        } else {
            window.location = getAppConfig().error.backLink;
        }
    };


    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let PV = this.props.isPortraitView;
        let showPv = isPortrait || PV;
        let errorImgStyleD = {
            width: "100%",
            height: "auto"
        };
        let errorImgStyleM = {
            width: PV ? "70%" : "100%",
            height: "auto",
            margin: PV ? "1em auto" : "3em auto",
            display: "flex"
        };
        let logoImgStyleD = {
            height: "5em",
            marginBottom: PV ? "1em" : "2em"
        };
        let logoImgStyleM = {
            height: "3.5em",
            top: "0",
        };
        let errorMsgStyle = {
            width: "auto",
            fontSize: isPortrait ? "1.3em" : "1.15em",
            fontWeight: "bold",
            margin: "1.5em 0 0.5em 0",
            lineHeight: "1.2",
            color: "#00000090",
            display: isPortrait ? "flex" : "",
            textAlign: showPv ? "center" : "",
            justifyContent: isPortrait ? "center" : "",
            fontFamily: getAppConfig().fonts.custom.families[4],
        };
        let errorDefaultMsgStyle = {
            width: "auto",
            fontSize: isPortrait ? "1.3em" : "1.15em",
            fontWeight: "bold",
            marginBottom: isPortrait ? "1em" : PV ? "2em" : "3em",
            lineHeight: "1.2",
            color: "#00000090",
            display: isPortrait ? "flex" : "",
            textAlign: showPv ? "center" : "",
            justifyContent: isPortrait ? "center" : "",
            fontFamily: getAppConfig().fonts.custom.families[4],
        };
        let errorMsgHeaderStyle = {
            width: "auto",
            margin: "10px auto",
            fontSize: isPortrait ? "2.4em" : "2.2em",
            letterSpacing: "0.1px",
            lineHeight: "1.2",
            display: isPortrait ? "flex" : "",
            justifyContent: isPortrait ? "center" : "",
            textAlign: showPv ? "center" : "",
            fontFamily: getAppConfig().fonts.custom.families[3],
        };
        let containerStyle = {
            position: "absolute",
            top: isPortrait ? "10%" : "22%", left: isPortrait ? "" : "5%",
            padding: "1em"
        }
        let containerStylePV = {
            position: "absolute",
            top: "0",
            left: "0",
            padding: "1em"
        }

        if (this.state.showErrorPage)
            return (
                <div style={{ height: PV ? "80vh" : "100vh", position: "relative", backgroundColor: "#fff" }}>
                    <div style={PV ? containerStylePV : containerStyle}>
                        <Grid verticalAlign='middle' centered >
                            <Grid.Row centered>
                                <Grid.Column mobile={16} tablet={PV ? 16 : 6} computer={PV ? 16 : 6}>
                                    <Grid.Row >
                                        <Grid.Column >
                                            {this.props.logo && <div style={{ display: "flex", justifyContent: isPortrait ? "center" : PV ? "center" : "start", alignItems: "center" }}>
                                                <img
                                                    src={
                                                        this.props.img
                                                            ? this.props.img
                                                            : getAppConfig().error.logo
                                                    }
                                                    alt="logo"
                                                    style={isPortrait ? logoImgStyleM : logoImgStyleD}
                                                />
                                            </div>}
                                        </Grid.Column>
                                    </Grid.Row>
                                    {showPv && <Grid.Row>
                                        <Grid.Column>
                                            <img
                                                src={
                                                    this.props.img
                                                        ? this.props.img
                                                        : getAppConfig().error.image === ""
                                                            ? "assets/images/defaults/error.png"
                                                            : getAppConfig().error.image
                                                }
                                                alt="Error Time"
                                                style={errorImgStyleM}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>}
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div style={errorMsgHeaderStyle}>{this.props.msgHeader}</div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div style={errorMsgStyle}>{this.props.msg}</div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div style={errorDefaultMsgStyle}>{getAppConfig().error.message}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <div style={{ display: "flex", justifyContent: showPv ? "center" : "start", width: showPv ? "100%" : "80%" }}>
                                        <Button
                                            background="#ffbc3f"
                                            size={isPortrait ? "tiny" : "large"}
                                            onClick={() => {
                                                hashHistory.push("/help");
                                            }}
                                            style={{ marginRight: "1em", width: "9em" }}
                                        >
                                            {getAppConfig().error.buttonHelp}
                                        </Button>
                                        <Button
                                            invertedbuttons="true"
                                            background="#ffbc3f"
                                            size={isPortrait ? "tiny" : "large"}
                                            onClick={this.goBack}
                                            style={{ width: "9em" }}
                                        >
                                            {getAppConfig().error.buttonHome}
                                        </Button>
                                    </div>
                                </Grid.Column>

                                <Grid.Column mobile={16} tablet={PV ? 16 : 10} computer={PV ? 16 : 10} style={{ height: "auto" }}>
                                    {!showPv && <img
                                        src={
                                            this.props.img
                                                ? this.props.img
                                                : getAppConfig().error.image === ""
                                                    ? "assets/images/defaults/error.png"
                                                    : getAppConfig().error.image
                                        }
                                        alt="Error Time"
                                        style={errorImgStyleD}
                                    />}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div >
                </div >
            );
        else return <Loading />;
    }
}
