import React from "react";
import Text from "../../contentBlocks/Text.react";
import VideoBox from "globals/videobox/VideoBox.react";
import PropTypes from "prop-types";

export default class VideoFullTitle extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            video: PropTypes.string,
        }).isRequired,
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
        }).isRequired,
    };
    render() {
        let layoutStyle = {
            height: "100%",
            width: "100%",
            position: "relative",
        };

        let videoboxStyle = {
            left: "0",
            width: "100%",
            height: "100%",
            position: "relative",
            overflow: "hidden",
        };

        let overlayStyle = {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: this.props.colors.primary,
            opacity: "0.65",
        };

        let headerStyle = {
            position: "absolute",
            top: "50%",
            left: "0",
            width: "100%",
            textAlign: "center",
            background: this.props.colors.base,
            color: this.props.colors.baseText,
            transform: "translate(0,-50%)",
            padding: "20px",
            opacity: this.props.data.title.length > 0 ? 0.85 : 0,
        };

        return (
            <div className="full-height">
                <div style={layoutStyle} className="full-video-main">
                    <div style={videoboxStyle}>
                        <VideoBox
                            url={this.props.data.video}
                            autoplay={true}
                            loop={true}
                            hideButtons={true}
                        />
                    </div>
                    <div style={overlayStyle} />
                    <div style={headerStyle}>
                        <Text
                            keyName="header"
                            text={this.props.data.title}
                            color={this.props.colors.baseText}
                            fontSize="huge"
                            animation="fadeIn,100"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
