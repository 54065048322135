const Translations = [
	{
		language: 'en',
		fonts: ['Roboto', 'Oswald', 'sans-serif'],
		mainMenu: {
			home: 'Home Page',
			library: 'Learning Library',
			offline: 'Offline Courses',
			feed: 'News Feed',
			notification: 'Notifications',
            progressReport: "Progress Report",
			helpdesk: 'Help Desk',
			logout: 'Logout',
			login: 'Login',
			sync: 'Sync',
			workshop: 'Workshop',
		},
		selectedLanguage: 'Select Language',
		openBtn: 'Click to Open',
		startBtn: 'Play',
		overlay: {
			correct: 'Correct!',
			incorrect: 'Incorrect!',
			thanks: 'Thank You!',
			close: 'Continue',
			deckFinished: 'You finished the deck on ',
			testPassed: 'You passed the test:',
			testFailed: 'You failed the test:',
			deckEnd: 'Submit and Continue',
			returnDeck: 'Or return to the deck',
			returnTest: 'Or take the test again',
			completion: 'All Complete!',
			passed: 'Well Done! You Passed!',
			failed: 'Ouch! You Failed!',
			next: 'Next',
			knowmore: 'Know More',
			resume: 'Resume',
			cancel: 'Cancel',
			syncScore: 'Syncing your data, please wait..',
            timesUp: "Time's Up!!"
		},
		templateActions: {
			checkAnswer: 'Check Answer',
			submitAnswer: 'Submit Answer',
			thanks: 'Thanks for your response',
			go: 'Go',
			gotolink:'Go To Link'
		},
		loginPage: {
			email: {
				placeholder: 'Enter Uniq Key',
				text: 'Uniq Key',
			},
			password: {
				placeholder: 'Enter Password',
				text: 'Password',
			},
			login: 'Login',
			forgotPassword: 'Forgot Password?',
            logintext: "You are still logged in!",
            logout: "Log Out"
		},
		forgotPasswordPage: {
			email: {
				placeholder: 'Enter Uniq Key',
				text: 'Uniq Key',
			},
			login: 'Login?',
			send_email: 'Send Mail',
		},
		programPage: {
			noContent: 'There is no content available for you under this header',
		},
		feedPage: {
			noPost: 'No post available for you under this header',
		},
         emptyNotificationText: {
            text: "You have no notifications right now. Check after some time."
        },
		termsandcondition:{
			text: 'Hello'
		},
        progressReportText: {
            header: "Progress Report",
            timeSpent: "Time Spent",
            score: "SCORE",
            courses: "COURSES",
            completed: "Completed",
            started: "Started",
            notAttempted: "Courses not attempted yet",
            certCollected: "Certificates Collected",
            scourses: "courses",
            viewPercent: "Viewed Percentage",
            inProgress: "In Progress",
            complete: "Complete"
        }
	},
	{
		language: 'hi',
		fonts: ['Roboto', 'Oswald', 'sans-serif'],
		mainMenu: {
			home: 'मुख्य मेनू',
			library: 'ज्ञान संग्रह',
			offline: 'ऑफ़लाइन पढ़ें',
			feed: 'बातचीत',
			notification: 'सूचनाएं',
            progressReport: "प्रगति रिपोर्ट",
			logout: 'लोग आउट',
		},
		selectedLanguage: 'चयनित भाषा',
		openBtn: 'कोर्स खोलें',
		startBtn: 'आगे बढ़ें',
		overlay: {
			correct: 'सही उत्तर!',
			incorrect: 'गलत जवाब!',
			thanks: 'धन्यवाद!',
			close: 'बंद करे',
			next: 'आगे बढ़ें',
			knowmore: 'अधिक जानिए',
			resume: 'आगे बढ़ें',
			cancel: 'बंद करे',
			syncScore: 'कृपया प्रतीक्षा करें',
			deckend: 'सबमिट करें और जारी रखें',
			score: 'स्कोर',
			completion: 'समापन',
			passed: 'उत्तीर्ण',
			failed: 'अनुत्तीर्ण',
		},
		templateActions: {
			checkAnswer: 'अपना उत्तर जाँच लें',
			submitAnswer: 'अपना जवाब सबमिट करें',
			thanks: 'आपके उत्तर के लिए धन्यवाद',
			go: 'आगे बढ़ें',
			gotolink:'लिंक पर जाएं',

		},
		loginPage: {
			email: {
				placeholder: 'ईमेल दर्ज करें',
				text: 'ईमेल',
			},
			password: {
				placeholder: 'पासवर्ड दर्ज करें',
				text: 'पासवर्ड',
			},
			login: 'लॉग इन',
			forgotPassword: 'पासवर्ड भूल गए',
            logintext: "आप अभी भी लॉग इन हैं!",
            logout: "लॉग आउट"
		},
		forgotPasswordPage: {
			email: {
				placeholder: 'ईमेल दर्ज करें',
				text: 'ईमेल',
			},
			login: 'लॉग इन?',
			send_email: 'मेल भेजे',
		},
		programPage: {
			noContent: 'इस शीर्ष लेख के अंतर्गत आपके लिए कोई भी सामग्री उपलब्ध नहीं है',
		},
        feedPage: {
            noPost: "इस हेडर के तहत आपके लिए कोई पोस्ट उपलब्ध नहीं है।"
        },
        emptyNotificationText: {
            text: "आपके लिए कोई सूचना नहीं है। कुछ समय बाद चेक करे।"
        },
        progressReportText: {
            header: "प्रगति रिपोर्ट",
            timeSpent: "समय बिताया",
            score: "स्कोर",
            courses: "कोर्सस",
            completed: "पुरे किये हुए कोर्स",
            started: "शुरू किये हुए कोर्स",
            notAttempted: "शुरू करने बाकी है",
            certCollected: "आपके सर्टिफिकेट्स",
            scourses: "कोर्सस",
            viewPercent: "% कोर्स ख़तम"
        }
	},
    {
        language: "kn",
        fonts: ["Roboto", "Oswald", "sans-serif"],
        mainMenu: {
            home: "ಹೋಮ್ ಪೇಜ್",
            library: "ಲರ್ನಿಂಗ್ ಲೈಬ್ರರಿ",
            offline: "ಆಫ್ಲೈನ್ ಕೋರ್ಸ್ಗಳು",
            feed: "ಸುದ್ದಿ ಫೀಡ್",
            notification: "ನೋಟಿಫಿಕೇಷನ್ಸ್",
            progressReport: "ಪ್ರೋಗ್ರೆಸ್ ರಿಪೋರ್ಟ್",
            helpdesk: "ಸಹಾಯವಾಣಿ ಕೇಂದ್ರ",
            logout: "ಲಾಗ್ ಔಟ್",
            login: "ಲಾಗಿನ್ ಮಾಡಿ",
            sync: "ಸಿಂಕ್",
            workshop: "ಕಾರ್ಯಾಗಾರ"
        },
        selectedLanguage: "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
        openBtn: "ತೆರೆಯಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
        startBtn: "ಪ್ಲೇ",
        overlay: {
            correct: "ಸರಿ!",
            incorrect: "ತಪ್ಪು!",
            thanks: "ಧನ್ಯವಾದ!",
            close: "ಮುಂದುವರಿಸಿ",
            deckFinished: "ನೀವು ಡೆಕ್ ಅನ್ನು ಮುಗಿಸಿದ್ದೀರಿ ",
            testPassed: "ನೀವು ಪರೀಕ್ಷೆಯನ್ನು ರವಾನಿಸಿದ್ದೀರಿ:",
            testFailed: "ನೀವು ಪರೀಕ್ಷೆಯನ್ನು ವಿಫಲಗೊಳಿಸಿದ್ದೀರಿ:",
            deckEnd: "ಸಲ್ಲಿಸಿ ಮತ್ತು ಮುಂದುವರಿಸಿ",
            returnDeck: "ಅಥವಾ ಡೆಕ್ಗೆ ಹಿಂತಿರುಗಿ",
            returnTest: "ಅಥವಾ ಮತ್ತೆ ಪರೀಕ್ಷೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ",
            completion: "ಎಲ್ಲಾ ಪೂರ್ಣಗೊಂಡಿದೆ!",
            passed: "ಚೆನ್ನಾಗಿ ಮುಗಿದಿದೆ! ನೀವು ದಾಟಿದೆ!",
            failed: "ಓಹ್! ನೀವು ವಿಫಲವಾಗಿದೆ!",
            next: "ಮುಂದೆ",
            knowmore: "ಇನ್ನಷ್ಟು ತಿಳಿಯಿರಿ",
            resume: "ಪುನರಾರಂಭಿಸು",
            cancel: "ರದ್ದುಮಾಡಿ",
            syncScore:
                "ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಸಿಂಕ್ ಮಾಡಲಾಗುತ್ತಿದೆ, ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ .."
        },
        templateActions: {
            checkAnswer: "ಉತ್ತರವನ್ನು ಪರಿಶೀಲಿಸಿ",
            submitAnswer: "ಉತ್ತರವನ್ನು ಸಲ್ಲಿಸಿ",
            thanks: "ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಗೆ ಧನ್ಯವಾದಗಳು",
            go: "ಹೋಗಿ"
        },
        loginPage: {
            email: {
                placeholder: "ಇಮೇಲ್ ನಮೂದಿಸಿ",
                text: "ಇಮೇಲ್"
            },
            password: {
                placeholder: "ಪಾಸ್ವರ್ಡ್ ನಮೂದಿಸಿ",
                text: "ಪಾಸ್ವರ್ಡ್"
            },
            login: "ಲಾಗಿನ್ ಮಾಡಿ",
            forgotPassword: "ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ?",
            logintext: "ನೀವು ಇನ್ನೂ ಲಾಗಿನ್ ಆಗಿರುವಿರಿ!",
            logout: "ಲಾಗ್ ಔಟ್"
        },
        forgotPasswordPage: {
            email: {
                placeholder: "ನಿಮ್ಮ ಇ ಮೇಯ್ಲ್ ಲನ್ನೂ ನಮೂದಿಸಿ",
                text: "ಇಮೇಲ್"
            },
            login: "ಲಾಗಿನ್ ಮಾಡಿ",
            send_email: "ಮೇಲ್ ಕಳುಹಿಸಿ"
        },
        programPage: {
            noContent: "ಈ ಶಿರೋನಾಮೆಯ ಅಡಿಯಲ್ಲಿ ನಿಮಗೆ ಯಾವುದೇ ವಿಷಯ ಲಭ್ಯವಿಲ್ಲ"
        },
        feedPage: {
            noPost: "ಈ ಶಿರೋನಾಮೆಯ ಅಡಿಯಲ್ಲಿ ನಿಮಗೆ ಯಾವುದೇ ಪೋಸ್ಟ್ ಲಭ್ಯವಿಲ್ಲ"
        },
        emptyNotificationText: {
            text:
                "ಇದೀಗ ನೀವು ಯಾವುದೇ ಅಧಿಸೂಚನೆಗಳನ್ನು ಹೊಂದಿಲ್ಲ. ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪರಿಶೀಲಿಸಿ."
        },
        progressReportText: {
            header: "ಪ್ರೋಗ್ರೆಸ್ ರಿಪೋರ್ಟ್",
            timeSpent: "ಕಳೆದ ಸಮಯ",
            score: "ಸ್ಕೋರ್",
            courses: "ಕೋರ್ಸ್ಗಳು",
            completed: "ಪೂರ್ಣಗೊಂಡಿದೆ",
            started: "ಪ್ರಾರಂಭಿಸಿದೆ",
            notAttempted: "ಕೋರ್ಸ್ಗಳು ಇನ್ನೂ ಪ್ರಯತ್ನಿಸಲಿಲ್ಲ",
            certCollected: "ಸರ್ಟಿಫಿಕೇಟ್ ಗಳನ್ನು ಸಂಗ್ರಹಿಸಲಾಗಿದೆ",
            scourses: "ಕೋರ್ಸ್ಗಳು",
            viewPercent: "ವೀಕ್ಷಿಸಿದ ಶೇಕಡಾವಾರು"
        }
    },
    {
        language: "te",
        fonts: ["Roboto", "Oswald", "sans-serif"],
        mainMenu: {
            home: "హోం పేజి",
            library: "లెర్నింగ్ లైబ్రరీ",
            offline: "ఆఫ్లైన్ కోర్సులు",
            feed: "న్యూస్ ఫీడ్",
            notification: "నోటిఫికెషన్స్ ",
            progressReport: "ప్రోగ్రెస్ రిపోర్ట్",
            helpdesk: "డెస్క్ సహాయం",
            logout: "లాగ్ అవుట్",
            login: "లాగిన్",
            sync: "సమకాలీకరణ",
            workshop: "వర్క్షాప్"
        },
        selectedLanguage: "భాషను ఎంచుకోండి",
        openBtn: "తెరవడానికి క్లిక్ చేయండి",
        startBtn: "ప్లే",
        overlay: {
            correct: "సరైన!",
            incorrect: "సరికాని!",
            thanks: "ధన్యవాదాలు!",
            close: "కొనసాగించు",
            deckFinished: "మీరు డెక్ను ముగించారు",
            testPassed: "మీరు పరీక్షను ఆమోదించారు:",
            testFailed: "మీరు పరీక్ష విఫలమయ్యారు:",
            deckEnd: "సమర్పించండి మరియు కొనసాగండి",
            returnDeck: "లేదా డెక్ తిరిగి",
            returnTest: "లేదా పరీక్షను మళ్లీ తీసుకోండి",
            completion: "మొత్తం పూర్తయింది!",
            passed: "బాగా పూర్తయింది! మీరు పాస్ అయ్యారు!",
            failed: "ఔచ్! నీవు విఫలము అయ్యావు!",
            next: "తరువాత",
            knowmore: "మరింత తెలుసుకోండి",
            resume: "పునఃప్రారంభం",
            cancel: "రద్దు",
            syncScore: "మీ డేటాను సమకాలీకరిస్తోంది, దయచేసి వేచి ఉండండి .."
        },
        templateActions: {
            checkAnswer: "జవాబు తనిఖీ చేయండి",
            submitAnswer: "సమాధానం సమర్పించండిr",
            thanks: "మీ ప్రతిస్పందన కు ధన్యవాధాలు",
            go: "వెళ్ళండి"
        },
        loginPage: {
            email: {
                placeholder: "మీ ఇ-మెయిల్ను ఎంటర్ చెయ్యండి",
                text: "ఇ-మెయిల్"
            },
            password: {
                placeholder: "మీ పాస్వర్డ్ ని నమోదుచేయండి",
                text: "పాస్వర్డ్"
            },
            login: "లాగిన్",
            forgotPassword: "పాస్వర్డ్ మర్చిపోయారా?",
            logintext: "మీరు ఇప్పటికీ లాగిన్ అయ్యారు!",
            logout: "లాగ్ అవుట్"
        },
        forgotPasswordPage: {
            email: {
                placeholder: "మీ ఇ-మెయిల్ను ఎంటర్ చెయ్యండి",
                text: "ఇ-మెయిల్"
            },
            login: "లాగిన్",
            send_email: "మెయిల్ పంపండి"
        },
        programPage: {
            noContent: "ఈ శీర్షిక క్రింద మీకు ఏ కంటెంట్ అందుబాటులో లేదు"
        },
        feedPage: {
            noPost: "ఈ శీర్షికలో మీకు ఏ పోస్ట్ అందుబాటులో లేదు"
        },
        emptyNotificationText: {
            text:
                "మీకు ప్రస్తుతం నోటిఫికేషన్లు లేవు. కొంత సమయం తర్వాత తనిఖీ చేయండి."
        },
        progressReportText: {
            header: "ప్రోగ్రెస్ రిపోర్ట్",
            timeSpent: "గడిపిన సమయం",
            score: "స్కోరు",
            courses: "కోర్సులు",
            completed: "పూర్తయింది",
            started: "ప్రారంభ",
            notAttempted: "కోర్సులు ఇంకా ప్రయత్నించలేదు",
            certCollected: "సర్టిఫికెట్లు సేకరించబడ్డాయి",
            scourses: "కోర్సులు",
            viewPercent: "చూసిన శాతం"
        }
    }
];

export { Translations };
