import React from "react";
import Caption from "../../contentBlocks/Caption.react";
import PropTypes from "prop-types";
import Zoomable from "../../contentBlocks/Zoomable.react";
import FullScreenComponent from "../../contentBlocks/FullScreenComponent.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

export default class ImageZoom extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            image: PropTypes.object,
            caption: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        toggleFullscreen: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.state = {
            fullScreen: false,
        };
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.conditionalRender = this.conditionalRender.bind(this);
    }
    toggleFullscreen(visible) {
        this.setState({ fullScreen: visible });
    }
    conditionalRender() {
        if (this.state.fullScreen) {
            return (
                <FullScreenComponent
                    type="image"
                    toggleFullscreen={this.toggleFullscreen}
                >
                    <Zoomable
                        {...this.props}
                        keyName={"image-" + this.props.slideId}
                        image={this.props.data.image}
                        animation="fadeIn,100"
                        fullScreen={this.state.fullScreen}
                        toggleFullscreen={this.toggleFullscreen}
                    />
                </FullScreenComponent>
            );
        } else {
            return (
                <Zoomable
                    {...this.props}
                    keyName={"image-" + this.props.slideId}
                    image={this.props.data.image}
                    animation="fadeIn,100"
                    toggleFullscreen={this.toggleFullscreen}
                    fullScreen={this.state.fullScreen}
                />
            );
        }
    }
    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "-20px auto",
            width: "100%",
            height: "100%",
            textAlign: "center",
        };
        let defaultImageStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            height: "0",
            paddingBottom: "75%",
            overflow: "hidden",
            position: "relative",
        };
        let captionStyle = {
            position: "relative",
            width: "90%",
            margin: isPortrait ? "-20px auto" : "0 auto",
            textAlign: "center",
        };
        let defaultLandscapeStyle = {
            margin: "0px auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
        };

        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div style={defaultStyle}>
                            <div style={defaultImageStyle}>
                                {this.conditionalRender()}
                            </div>
                            <div style={captionStyle}>
                                <Caption
                                    keyName="caption"
                                    text={this.props.data.caption}
                                    colors={this.props.colors}
                                    animation="transition.bounceUpIn,400,750"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingLeft: "0",
                                    paddingBottom: "3%",
                                }}
                            >
                                <div style={defaultLandscapeStyle}>
                                    <div style={defaultImageStyle}>
                                        {this.conditionalRender()}
                                    </div>
                                    <div style={captionStyle}>
                                        <Caption
                                            keyName="caption"
                                            text={this.props.data.caption}
                                            colors={this.props.colors}
                                            animation="transition.bounceUpIn,400,750"
                                        />
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
