import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Carousel from "globals/carousel/Carousel.react";
import Caption from "../../contentBlocks/Caption.react";
import { resolveImage } from "config/app.helpers";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

export default class Gallery extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            caption: PropTypes.string,
            cards: PropTypes.arrayOf(
                PropTypes.shape({
                    image: PropTypes.object.isRequired,
                    text: PropTypes.string,
                })
            ),
        }).isRequired,
        slideId: PropTypes.number,
        callback: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    }

    handleClick = (index) => {
        this.setState({ index: index });
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "-20px auto",
            width: "100%",
            textAlign: "center",
            height: "100%",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            height: "0",
            paddingBottom: "40%",
            position: "relative",
            float: "right",
        };
        let sliderContainerStyle = {
            position: "relative",
            width: "100%",
            height: "0",
            paddingBottom: "67%",
            background: this.props.colors.secondary,
        };
        let sliderStyle = {
            position: "absolute",
            top: "5%",
            left: "10%",
            width: "80%",
            height: "96%",
        };
        let captionStyle = {
            position: "relative",
            textAlign: "center",
            background: "#666",
            padding: "10px 20px 20px 20px",
            color: "#fff",
        };

        let carouselData = _.map(this.props.data.cards, (elm) => {
            return resolveImage(elm.image.id, this.props.imageLibrary);
        });

        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div
                            style={
                                isPortrait
                                    ? defaultStyle
                                    : defaultLandscapeStyle
                            }
                        >
                            <div style={sliderContainerStyle}>
                                <div
                                    style={sliderStyle}
                                    className="animated_list"
                                >
                                    <Carousel
                                        carouselItems={carouselData}
                                        autoPlay={false}
                                        arrows={true}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                        callback={this.handleClick}
                                    />
                                </div>
                            </div>
                            <div style={captionStyle}>
                                <Caption
                                    text={
                                        this.props.data.cards[this.state.index]
                                            .text
                                    }
                                    colors={{ baseText: "#fff" }}
                                    animation="fadeIn,100"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    backgroundColor: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingLeft: "0",
                                    paddingBottom: "3%",
                                }}
                            >
                                <div
                                    style={
                                        isPortrait
                                            ? defaultStyle
                                            : defaultLandscapeStyle
                                    }
                                >
                                    <div style={sliderContainerStyle}>
                                        <div
                                            style={sliderStyle}
                                            className="animated_list"
                                        >
                                            <Carousel
                                                carouselItems={carouselData}
                                                autoPlay={false}
                                                arrows={true}
                                                slidesToShow={1}
                                                slidesToScroll={1}
                                                callback={this.handleClick}
                                            />
                                        </div>
                                    </div>
                                    <div style={captionStyle}>
                                        <Caption
                                            text={
                                                this.props.data.cards[
                                                    this.state.index
                                                ].text
                                            }
                                            colors={{ baseText: "#fff" }}
                                            animation="fadeIn,100"
                                        />
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
