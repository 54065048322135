import React from "react";
import PropTypes from "prop-types";

import { Button } from "config/initializers/styles.app";
import { Card, Icon } from "semantic-ui-react";

import { getAppConfig } from "config/client.config";
import { Translations } from "config/app.translations";
import _ from "lodash";

export default class FeedbackCard extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        selectedIndex: PropTypes.number.isRequired,
        gotoNextSlide: PropTypes.func.isRequired,
        closeOverlay: PropTypes.func.isRequired,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let overlayTranslations = _.find(Translations, {
            language: "en",
        }).overlay;
        let refinedFeedback = _.map(this.props.data.feedback, (fb, index) => {
            /* istanbul ignore next - Request and Pure passthrough */
            var res = {
                header: "",
                icon: "trophy",
                text: fb,
                color: "green",
            };
            /* istanbul ignore next - Request and Pure passthrough */
            if (index === 0) {
                res = {
                    ...res,
                    header: overlayTranslations.correct,
                    icon: "check circle",
                    color: "green",
                };
            } else if (index === 1) {
                res = {
                    ...res,
                    header: overlayTranslations.incorrect,
                    icon: "remove circle",
                    color: "red",
                };
            } else {
                res = {
                    ...res,
                    header: overlayTranslations.thanks,
                    icon: "thumbs up",
                    color: "yellow",
                };
            }
            /* istanbul ignore next - Request and Pure passthrough */
            return res;
        });

        let cardStyle = {
            borderRadius: 0,
            boxShadow: "none",
            textAlign: "center",
            width: isPortrait ? "100%" : "50%",
            minWidth: isPortrait ? "0" : "500px",
            margin: "0 auto",
            padding: "30px",
        };

        let headerStyle = {
            ...getAppConfig().headerStyle,
            fontSize: "4em",
        };
        let textStyle = {
            ...getAppConfig().textStyle,
            fontSize: "1.5em",
            lineHeight: "1.1",
        };

        let cardList = () => {
            /* istanbul ignore next - Request and Pure passthrough */
            return _.map(refinedFeedback, (feedback, index) => {
                return (
                    <Card
                        className={
                            this.props.selectedIndex === index ? "show" : "hide"
                        }
                        style={cardStyle}
                        key={"card_" + index}
                    >
                        <Card.Content>
                            <Card.Header style={headerStyle}>
                                {feedback["header"]}
                            </Card.Header>
                            <Card.Description>
                                <br />
                                <Icon
                                    name={feedback["icon"]}
                                    size={"huge"}
                                    color={feedback["color"]}
                                />
                                <br />
                                <br />
                                <div style={textStyle}>{feedback["text"]}</div>
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <div className="ui two buttons">
                                <Button
                                    style={{ fontSize: "1rem" }}
                                    onClick={this.props.gotoNextSlide}
                                >
                                    {overlayTranslations.close}
                                </Button>
                            </div>
                        </Card.Content>
                    </Card>
                );
            });
        };
        return <Card.Group>{cardList()}</Card.Group>;
    }
}
