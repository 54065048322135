import React from "react";
import PropTypes from "prop-types";
import Text from "../../contentBlocks/Text.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import { Button } from "config/initializers/styles.app";

import { getAppConfig } from "config/client.config";
import { Translations } from "config/app.translations";
import _ from "lodash";
import Separator from "../../contentBlocks/Separator.react";
import { VelocityTransitionGroup } from "velocity-react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";

import { Grid } from "semantic-ui-react";

export default class Detailer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answer: "",
        };
    }

    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            answer: PropTypes.string,
            purpose: PropTypes.string,
            question: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        trackInteraction: PropTypes.func.isRequired,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    handleClick = () => {
        this.props.trackInteraction(this.state.answer);
    };

    changeText = (event) => {
        if (event.target.value !== this.state.answer)
            this.setState({ answer: event.target.value });
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: "80%",
            textAlign: "center",
            paddingTop: window.innerWidth > 1400 ? "50px" : "25px",
            height: "100%",
            overflow: "hidden",
        };
        let defaultLandscapeStyle = {
            margin: "60px auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
            padding: "30px",
            overflow: "hidden",
        };
        let answer = this.props.data.answer
            .toString()
            .trim()
            .replace(/[a-z0-9&_]/gi, "*");
        let templateTranslations = _.find(Translations, {
            language: "en",
        }).templateActions;
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        return (
            <div className="full-height">
                {isPortrait && (
                    <SlideHeader
                        {...this.props}
                        title={title}
                        subtitle={subtitle}
                    />
                )}
                {isPortrait && (
                    <div
                        style={
                            isPortrait ? defaultStyle : defaultLandscapeStyle
                        }
                    >
                        <Text
                            keyName={"question-" + this.props.slideId}
                            text={this.props.data.question}
                            color={this.props.colors.baseText}
                            fontSize="large"
                            animation="transition.expandIn,500,500"
                        />
                        <Separator
                            keyName="separator"
                            color={this.props.colors.primary}
                            animation="transition.bounceLeftIn,500,500"
                        />
                        <VelocityTransitionGroup
                            enter={{
                                animation: "transition.bounceUpIn",
                                duration: "500",
                                delay: "500",
                            }}
                            leave={{ animation: "fadeOut", duration: "0" }}
                            runOnMount
                        >
                            <input
                                type="text"
                                style={getAppConfig().textInputStyle}
                                placeholder={answer}
                                onChange={this.changeText}
                            />
                            <br />
                            <Button
                                style={{
                                    marginTop: "10px",
                                }}
                                size={isPortrait ? "medium" : "big"}
                                onClick={this.handleClick}
                            >
                                {this.props.data.purpose === "quiz"
                                    ? templateTranslations.checkAnswer
                                    : templateTranslations.submitAnswer}
                            </Button>
                        </VelocityTransitionGroup>
                    </div>
                )}
                {!isPortrait && (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    backgroundColor: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div
                                    style={
                                        isPortrait
                                            ? defaultStyle
                                            : defaultLandscapeStyle
                                    }
                                >
                                    <Text
                                        keyName={
                                            "question-" + this.props.slideId
                                        }
                                        text={this.props.data.question}
                                        color={this.props.colors.baseText}
                                        fontSize="large"
                                        animation="transition.expandIn,500,500"
                                    />
                                    <Separator
                                        keyName="separator"
                                        color={this.props.colors.primary}
                                        animation="transition.bounceLeftIn,500,500"
                                    />
                                    <VelocityTransitionGroup
                                        enter={{
                                            animation: "transition.bounceUpIn",
                                            duration: "500",
                                            delay: "500",
                                        }}
                                        leave={{
                                            animation: "fadeOut",
                                            duration: "0",
                                        }}
                                        runOnMount
                                    >
                                        <input
                                            type="text"
                                            style={{
                                                ...getAppConfig()
                                                    .textInputStyle,
                                                fontSize: "2em",
                                                textAlign: "center",
                                            }}
                                            placeholder={answer}
                                            onChange={this.changeText}
                                        />
                                        <br />
                                        <Button
                                            style={{
                                                marginTop: "10px",
                                            }}
                                            size={isPortrait ? "medium" : "big"}
                                            onClick={this.handleClick}
                                        >
                                            {this.props.data.purpose === "quiz"
                                                ? templateTranslations.checkAnswer
                                                : templateTranslations.submitAnswer}
                                        </Button>
                                    </VelocityTransitionGroup>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
