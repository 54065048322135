import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Image } from "semantic-ui-react";
import Slider from "globals/carousel/HTMLSlider.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

import { getAppConfig } from "config/client.config";
import { resolveImage } from "config/app.helpers";

export default class AnimatedBulletList extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            bullets: PropTypes.array,
            pattern: PropTypes.string,
            image: PropTypes.object,
        }).isRequired,
        imageLibrary: PropTypes.array,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: isPortrait ? "-20px auto" : "0 auto",
            width: "100%",
            textAlign: "center",
            height: "100%",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: isPortrait ? "50%" : "100%",
            textAlign: "center",
            height: "0",
            paddingBottom: isPortrait ? "75%" : "",
            position: "relative",
            float: "right",
        };
        let sliderContainerStyle = {
            position: "relative",
            width: "100%",
            height: "0",
            paddingBottom: "75%",
            background: this.props.colors.secondary,
        };

        let imageStyle = {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
        };
        let sliderStyle = {
            position: "absolute",
            top: "10%",
            left: "10%",
            width: "80%",
            height: "96%",
        };
        let sliderContentStyle = {
            width: "80%",
            height: "0",
            marginLeft: "10%",
            paddingBottom: "80%",
            position: "relative",
        };
        let sliderInnerStyle = {
            ...getAppConfig().textStyle,
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "80%",
            transform: "translate(-50%,-50%)",
        };
        let sliderElm = _.map(this.props.data.bullets, (bullet, index) => {
            return (
                <div
                    className={
                        this.props.data.theme + " " + this.props.data.pattern
                    }
                    style={sliderContentStyle}
                >
                    <div style={sliderInnerStyle}>{bullet}</div>
                </div>
            );
        });
        let backImage = () => {
            if (this.props.data.image !== undefined) {
                return (
                    <Image
                        src={resolveImage(
                            this.props.data.image.id,
                            this.props.imageLibrary
                        )}
                        style={imageStyle}
                    />
                );
            }
        };

        return (
            <div className="full-height" style={{ width: "100%" }}>
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div style={defaultStyle}>
                            <div style={sliderContainerStyle}>
                                {backImage()}
                                <div
                                    style={sliderStyle}
                                    className="animated_list"
                                >
                                    <Slider
                                        key={"carousel-" + this.props.slideSeq}
                                        sliderItems={sliderElm}
                                        autoPlay={false}
                                        arrows={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    backgroundColor: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div
                                    style={
                                        isPortrait
                                            ? defaultStyle
                                            : defaultLandscapeStyle
                                    }
                                >
                                    <div style={sliderContainerStyle}>
                                        {backImage()}
                                        <div
                                            style={sliderStyle}
                                            className="animated_list"
                                        >
                                            <Slider
                                                key={
                                                    "carousel-" +
                                                    this.props.slideSeq
                                                }
                                                sliderItems={sliderElm}
                                                autoPlay={false}
                                                arrows={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
