import React from "react";
import ImageGroup from "../../contentBlocks/ImageGroup.react";
import Caption from "../../contentBlocks/Caption.react";

import PropTypes from "prop-types";
export default class IconCaption extends React.Component {
   static propTypes = {
       colors: PropTypes.shape({
           base: PropTypes.string.isRequired,
           primary: PropTypes.string.isRequired,
           primaryVariation: PropTypes.string.isRequired,
           secondary: PropTypes.string.isRequired,
           baseText: PropTypes.string.isRequired,
           primaryText: PropTypes.string.isRequired,
           secondaryText: PropTypes.string.isRequired
       }).isRequired,
       data: PropTypes.shape({
           title: PropTypes.string,
           subtitle: PropTypes.string,
           image: PropTypes.string,
           caption: PropTypes.string
       }).isRequired,
       slideId: PropTypes.number
   };

   render() {
       let isPortrait =
           this.props.mobileView || window.innerHeight > window.innerWidth;
       let defaultStyle = {
           margin: "10% auto 5% auto",
           width: "50%",
           textAlign: "center",
           height: "0",
           paddingBottom: "38%",
           overflow: "hidden",
           position: "relative"
       };
       let defaultLandscapeStyle = {
           margin: "auto",
           width: "40%",
           textAlign: "center",
           height: "0",
           paddingBottom: "34%",
           position: "relative",
       };
       let captionStyle = {
           position: "relative",
           top: "inherit",
           width: "100%",
           margin: "0 auto",
           textAlign: "center",
           fontSize: "1em",
           padding: "10px"
       };
       return (
           <div className="full-height">
               <div style={isPortrait ? defaultStyle : defaultLandscapeStyle}>
                   <ImageGroup
                       {...this.props}
                       keyName={"image-" + this.props.slideId}
                       size="medium"
                       images={[
                           this.props.data.image === undefined
                               ? this.props.data.images
                               : this.props.data.image
                       ]}
                       animation="transition.expandIn,300,100"
                       style={{ height: "100%" }}
                   />
                   {/* {!isPortrait && (
                       <div style={captionStyle}>
                           <Caption
                               keyName={"caption-" + this.props.slideId}
                               text={this.props.data.caption}
                               colors={this.props.colors}
                               animation="transition.bounceUpIn,400,750"
                           />
                       </div>
                   )} */}
               </div>
               {/* {isPortrait && ( */}
                   <div style={captionStyle}>
                       <Caption
                           keyName={"caption-" + this.props.slideId}
                           text={this.props.data.caption}
                           colors={this.props.colors}
                           animation="transition.bounceUpIn,400,750"
                       />
                   </div>
               {/* )}  */}
           </div>
       );
   }
} 