import React from "react";
import { connect } from "react-redux";
import _ from "lodash"

import { MyProfileAction } from "../../redux/MyProfile.action";
import ErrorPage from "globals/error/ErrorPage.react"
import { Loading } from "mobile/appComponents/loading/Loading.react";
import ProfileWrapper from "./wrappers/ProfileWrapper.react";

export class ProfileContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            error: false,
            errorMessage: [],
            profileData: []
        };
    }
    componentDidMount() {
        this.props.actions.fetchMyProfile(this.props.auth.user.id)
            .then(historyData => {
                this.setState({
                    fetched: true,
                    profileData: historyData
                });
            })
            .catch(error => {
                this.setState({
                    fetched: true,
                    error: true,
                    errorMessage: error.errors
                });
            });
    }
    getErrors = () => {
        let error = ""
        if (this.state.error && this.state.errorMessage.length > 0)
            error = _.join(this.state.errorMessage, "\n");
        return error
    }

    render() {
        if (!this.state.fetched) return <Loading showFull={true} />;
        else if (this.state.fetched && !this.state.error) {
            return (
                <div style={{ overflow: "hidden" }}>
                    <ProfileWrapper
                        {...this.props}
                        historyData={this.state.profileData}
                        loading={!this.state.fetched}
                    />
                </div>
            )
        }
        else
            return <ErrorPage msg={this.getErrors()} />;

    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        courses: state.courses

    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            fetchMyProfile: (learnerId) => {
                return dispatch(MyProfileAction.fetchMyProfile(learnerId));
            }
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileContainer);
