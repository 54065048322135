import React from "react";
import PropTypes from "prop-types";
import { getAppConfig } from "config/client.config";

import _ from "lodash";
import { VelocityTransitionGroup } from "velocity-react";
import { ComponentInputs } from "schema/FormSchema.react";

export default class Bullets extends React.Component {
    static propTypes = {
        list: PropTypes.array,
        color: PropTypes.string,
        animation: PropTypes.string,
    };

    static defaultProps = {
        color: "#000",
        animation: "fadeIn, 500",
    };

    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let animation = this.props.animation.split(",");
        let getListItems = _.map(this.props.list, (item, index) => {
            let textStyle = {
                ...getAppConfig().textStyle,
                color: this.props.color,
                marginLeft: isPortrait ? "-20px" : "-15px",
                fontSize: isPortrait
                    ? item && item.length > ComponentInputs.bullets.idealLength
                        ? "1.15em"
                        : "1.5em"
                    : "1.5em",
                marginBottom: "10px",
                lineHeight: "1.2",
            };
            return (
                <li key={"Bullet-List-Item-" + index} style={textStyle}>
                    {item}
                </li>
            );
        });
        return (
            <div>
                <ul style={{ textAlign: "left" }}>
                    <VelocityTransitionGroup
                        enter={{
                            animation: animation[0],
                            duration: animation[1],
                            stagger: animation[2],
                        }}
                        leave={{ animation: "fadeOut", duration: "0" }}
                        runOnMount
                    >
                        {getListItems}
                    </VelocityTransitionGroup>
                </ul>
            </div>
        );
    }
}
