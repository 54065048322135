import React from "react";
import PropTypes from "prop-types";
import { Divider, Feed } from "semantic-ui-react";
import { hashHistory } from "react-router";

class NotificationItem extends React.Component {

    static propTypes = {
        item: PropTypes.object.isRequired,
    };

    handleClickEvent = () => {
        if (
            this.props.item.link !== undefined &&
            this.props.item.link !== ""
        ) {
            hashHistory.push(this.props.item.link);
            if (this.props.openModal)
                this.props.toggleNotification();
        }
    }

    render() {
        let isPortrait = window.innerHeight > window.innerWidth;

        let feedStyle = isPortrait ? {
            fontSize: "10px",
            lineHeight: "1.6",
            borderRadius: "5px",
            background: "#ffffff",
            border: "1px solid #d3d3d3",
            cursor: this.props.item.link ? "pointer" : "default",
            width: "92vw"
        } : {
            cursor: this.props.item.link ? "pointer" : "default",
            minWidth: "46vw",
        };

        let feedRowStyle = {
            background: this.props.item.read === false ? "#D3D3D3" : "#ffffff"
        };

        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return (
            <Feed size="small" style={feedStyle} onClick={this.handleClickEvent}>
                <Feed.Event style={feedRowStyle}>
                    <Feed.Label>
                        <img
                            alt="notification"
                            src={`assets/images/notificationsImages/${this.props.item.type}.jpg`}
                            style={{
                                width: "70px",
                                height: isPortrait ? "100%" : "103%",
                                borderRadius: "5px"
                            }} />
                    </Feed.Label>
                    <Feed.Content style={{ margin: isPortrait ? "auto 0% auto 16%" : "auto 7%" }}>
                        <Feed.Summary>{this.props.item.title}
                            <Feed.Date style={{ textAlign: "end" }}>
                                {new Date(this.props.item.created_at ?
                                    this.props.item.created_at
                                    : this.props.item.createdAt
                                ).toLocaleDateString(undefined, options)}
                            </Feed.Date>
                        </Feed.Summary>
                        <Feed.Extra>
                            {this.props.item.description}
                        </Feed.Extra>
                    </Feed.Content>
                </Feed.Event>
                {!isPortrait && (
                    <Divider />
                )}
            </Feed >
        );
    }
}

export default NotificationItem;
