import React from "react";
import PropTypes from "prop-types";
import Text from "../../contentBlocks/Text.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import { Image } from "semantic-ui-react";
import { Button } from "config/initializers/styles.app";
import _ from "lodash";

import { getAppConfig } from "config/client.config";
import { resolveImage } from "config/app.helpers";
import { Translations } from "config/app.translations";
import { VelocityTransitionGroup } from "velocity-react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";

import { Grid } from "semantic-ui-react";

export default class FeedbackParagraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answer: "",
            filter: this.props.data.filter,
        };
    }

    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            answer: PropTypes.string,
            purpose: PropTypes.string,
            question: PropTypes.string,
            filter: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        trackInteraction: PropTypes.func.isRequired,
        imageLibrary: PropTypes.array.isRequired,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    handleClick = () => {
        this.props.trackInteraction(this.state.answer);
    };

    changeText = (event) => {
        this.setState({ answer: event.target.value });
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: "100%",
            height: "100%",
            textAlign: "center",
            position: "relative",
            zIndex: "1",
            overflow: "hidden",
        };
        let questionSectionStyle = {
            width: "90%",
            margin: isPortrait ? "0 auto" : "5px auto",
        };
        let answer = this.props.data.answer
            .toString()
            .trim()
            .replace(/[a-z0-9&_]/gi, "*");
        let templateTranslations = _.find(Translations, {
            language: "en",
        }).templateActions;
        let buttonStyle = {
            marginTop: "5px",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
            overflow: "hidden",
        };
        let imageStyle = {
            position: "relative",
            left: "15%",
            width: "70%",
        };
        let imagePortraitStyle = {};
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        return (
            <div className="full-height">
                {isPortrait && (
                    <SlideHeader
                        {...this.props}
                        title={title}
                        subtitle={subtitle}
                    />
                )}
                {isPortrait && (
                    <div
                        style={
                            isPortrait ? defaultStyle : defaultLandscapeStyle
                        }
                    >
                        <div
                            style={isPortrait ? imagePortraitStyle : imageStyle}
                        >
                            <Image
                                src={resolveImage(
                                    this.props.data.image === undefined
                                        ? this.props.data.images
                                        : this.props.data.image.id,
                                    this.props.imageLibrary
                                )}
                                className={this.props.data.filter}
                                fluid={true}
                                onClick={this.handleClick}
                            />
                        </div>
                        <div style={questionSectionStyle}>
                            <Text
                                keyName={"question-" + this.props.slideId}
                                text={this.props.data.question}
                                color={this.props.colors.baseText}
                                fontSize="large"
                                animation="transition.expandIn,500,500"
                            />
                            <br />
                            <VelocityTransitionGroup
                                enter={{
                                    animation: "transition.bounceUpIn",
                                    duration: "500",
                                    delay: "500",
                                }}
                                leave={{ animation: "fadeOut", duration: "0" }}
                                runOnMount
                            >
                                <input
                                    type="text"
                                    placeholder={answer}
                                    style={getAppConfig().textInputStyle}
                                    onChange={this.changeText}
                                />
                                <br />
                                <Button
                                    style={buttonStyle}
                                    size={isPortrait ? "medium" : "big"}
                                    onClick={this.handleClick}
                                >
                                    {this.props.data.purpose === "quiz"
                                        ? templateTranslations.checkAnswer
                                        : templateTranslations.submitAnswer}
                                </Button>
                            </VelocityTransitionGroup>
                        </div>
                    </div>
                )}
                {!isPortrait && (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    backgroundColor: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingLeft: "0",
                                    paddingBottom: "3%",
                                }}
                            >
                                <div
                                    style={
                                        isPortrait
                                            ? defaultStyle
                                            : defaultLandscapeStyle
                                    }
                                >
                                    <div
                                        style={
                                            isPortrait
                                                ? imagePortraitStyle
                                                : imageStyle
                                        }
                                    >
                                        <Image
                                            src={resolveImage(
                                                this.props.data.image ===
                                                    undefined
                                                    ? this.props.data.images
                                                    : this.props.data.image.id,
                                                this.props.imageLibrary
                                            )}
                                            className={this.props.data.filter}
                                            fluid={true}
                                            onClick={this.handleClick}
                                        />
                                    </div>
                                    <div style={questionSectionStyle}>
                                        <Text
                                            keyName={
                                                "question-" + this.props.slideId
                                            }
                                            text={this.props.data.question}
                                            color={this.props.colors.baseText}
                                            fontSize="large"
                                            animation="transition.expandIn,500,500"
                                        />
                                        <br />
                                        <VelocityTransitionGroup
                                            enter={{
                                                animation:
                                                    "transition.bounceUpIn",
                                                duration: "500",
                                                delay: "500",
                                            }}
                                            leave={{
                                                animation: "fadeOut",
                                                duration: "0",
                                            }}
                                            runOnMount
                                        >
                                            <input
                                                type="text"
                                                placeholder={answer}
                                                style={{
                                                    ...getAppConfig()
                                                        .textInputStyle,
                                                    fontSize: "2em",
                                                    textAlign: "center",
                                                }}
                                                onChange={this.changeText}
                                            />
                                            <br />
                                            <Button
                                                style={buttonStyle}
                                                size={
                                                    isPortrait
                                                        ? "medium"
                                                        : "big"
                                                }
                                                onClick={this.handleClick}
                                            >
                                                {this.props.data.purpose ===
                                                "quiz"
                                                    ? templateTranslations.checkAnswer
                                                    : templateTranslations.submitAnswer}
                                            </Button>
                                        </VelocityTransitionGroup>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
