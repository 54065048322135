import React, { PureComponent } from "react";
import { Segment, Dropdown } from "semantic-ui-react";
import _ from "lodash";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";

import { Loading } from "../../../appComponents/loading/Loading.react.jsx";
import ErrorPage from "common/globals/error/ErrorPage.react.jsx";

import QuoRadialProgressChart from "../charts/QuoRadialProgressChart.react";
import QuoScatterChart from "../charts/QuoScatterChart.react";
import CoursePerformanceSubPanel from "./CoursePerformanceSubPanel.react";

export default class PerformanceAnalytics extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fetchCompleted: false,
            error: null,
            performanceData: null,
            coursePerformance: null,
            courseList: []
        };
    }
    componentDidMount() {
        this.props.actions
            .fetchLearnerPerformance(this.props.user.id)
            .then(data => {
                this.props.actions
                    .fetchLearnerCompletedCourses(this.props.user.id)
                    .then(courseList => {
                        this.setState({
                            fetchCompleted: true,
                            performanceData: data,
                            courseList: courseList
                        });
                    })
                    .catch(
                        /* istanbul ignore next - tested in the render */ e => {
                            this.setState({
                                fetchCompleted: true,
                                error: true
                            });
                        }
                    );
            });
    }
    updateCoursePerformance = (e, { value }) => {
        this.props.actions
            .fetchLearnerCoursePerformance(this.props.user.id, value)
            .then(data => {
                this.setState({
                    coursePerformance: data
                });
            })
            .catch(
                /* istanbul ignore next - tested in the render */ e => {
                    this.setState({
                        fetchCompleted: true,
                        error: true
                    });
                }
            );
    };

    customTooltip = ({ active, payload, label }) => {
        let tooltip = {
            base: {
                background: "#ffffff",
                padding: "15px",
                border: "1px solid #ccc",
                zIndex: "99999999",
                textAlign: "left"
            }
        };
        if (active) {
            return (
                <div style={tooltip.base}>
                    <h3 style={{ marginBottom: "5px" }}>
                        {payload[0] !== undefined &&
                            payload[0].payload !== undefined &&
                            _.find(this.state.courseList, {
                                value: payload[0].payload.id
                            }) !== undefined
                            ? _.find(this.state.courseList, {
                                value: payload[0].payload.id
                            }).text
                            : ""}
                    </h3>
                    <strong>Completion:</strong> {payload[0].value}%
                    <br />
                    <strong>Score:</strong>{" "}
                    {payload[0] !== undefined &&
                        payload[0].payload !== undefined
                        ? payload[0].payload.score
                        : ""}
                    %
                </div>
            );
        }

        return null;
    };
    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let container = {
            height: "100vh",
            overflow: "hidden scroll",
            marginTop: "50px"
        };
        let pageStyle = {
            courseSection: {
                marginLeft: isPortrait ? "0" : "25px",
                width: isPortrait ? "100%" : "720px",
                height: isPortrait ? "auto" : "555px"
            },
            aggregate: {
                base: {
                    width: isPortrait ? "100%" : "760px",
                    marginTop: isPortrait ? "0" : "80px",
                    textAlign: "center",
                    padding: "0"
                },
                scatter: {
                    width: isPortrait ? "100%" : "500px",
                    display: "inline-block"
                },
                h3Header: {
                    margin: "0"
                },
                radial: {
                    width: isPortrait ? "160px" : "230px",
                    display: "inline-block"
                }
            }
        };
        let defaultCourseListOpt = [{ text: "Select a course to get performance details", value: "" }];

        if (!this.state.fetchCompleted) return <Loading />;
        else if (this.state.fetchCompleted && !this.state.error) {
            return (
                <Segment basic style={isPortrait ? container : {}}>
                    <Segment basic style={pageStyle.aggregate.base}>
                        <Segment basic style={pageStyle.aggregate.radial}>
                            {isPortrait ?
                                <h4 style={pageStyle.aggregate.h3Header}>
                                    Average Course <br /> Final Score
                                </h4>
                                : <h3 style={pageStyle.aggregate.h3Header}>
                                    Average Course <br /> Final Score
                                </h3>
                            }
                            <QuoRadialProgressChart
                                width={isPortrait ? 130 : 200}
                                height={isPortrait ? 110 : 180}
                                color={getAppConfig().colors.accents.primary}
                                value={Math.round(
                                    this.state.performanceData
                                        .average_final_score
                                )}
                                top={55}
                                left={67}
                            />
                        </Segment>
                        {isPortrait ?
                            <div>
                                <Segment raised style={pageStyle.aggregate.scatter}>
                                    <h3 style={pageStyle.aggregate.h3Header}>
                                        Score vs Content Coverage Analysis
                                    </h3>
                                    <QuoScatterChart
                                        width={isPortrait ? 350 : 450}
                                        color={getAppConfig().colors.accents.primary}
                                        data={
                                            this.state.performanceData.score_completion
                                        }
                                        series={[
                                            {
                                                type: "number",
                                                dataKey: "completion",
                                                name: "Coverage",
                                                unit: "%"
                                            },
                                            {
                                                type: "number",
                                                dataKey: "score",
                                                name: "Score",
                                                unit: "%"
                                            }
                                        ]}
                                        customTooltip={this.customTooltip}
                                    />
                                </Segment>
                            </div>
                            : <Segment raised style={pageStyle.aggregate.scatter}>
                                <h3 style={pageStyle.aggregate.h3Header}>
                                    Score vs Content Coverage Analysis
                            </h3>
                                <QuoScatterChart
                                    width={isPortrait ? 350 : 450}
                                    color={getAppConfig().colors.accents.primary}
                                    data={
                                        this.state.performanceData.score_completion
                                    }
                                    series={[
                                        {
                                            type: "number",
                                            dataKey: "completion",
                                            name: "Coverage",
                                            unit: "%"
                                        },
                                        {
                                            type: "number",
                                            dataKey: "score",
                                            name: "Score",
                                            unit: "%"
                                        }
                                    ]}
                                    customTooltip={this.customTooltip}
                                />
                            </Segment>
                        }
                    </Segment>
                    <Segment raised style={pageStyle.courseSection}>
                        <Dropdown
                            placeholder="Select a course to get performance details"
                            search
                            fluid
                            selection
                            options={[defaultCourseListOpt[0], ...this.state.courseList]}
                            onChange={this.updateCoursePerformance}
                        />
                        <CoursePerformanceSubPanel
                            data={this.state.coursePerformance}
                        />
                    </Segment>
                </Segment>
            );
        } else {
            return <ErrorPage msg="Sorry, could not fetch the content" />;
        }
    }
}
