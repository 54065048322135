import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { VelocityTransitionGroup } from "velocity-react";

import { resolveImage } from "config/app.helpers";

export default class ImageGrid extends React.Component {
    static propTypes = {
        images: PropTypes.array.isRequired,
        animation: PropTypes.string,
        callback: PropTypes.func,
        imageLibrary: PropTypes.array
    };

    static defaultProps = {
        animation: "fadeIn,1000"
    };

    handleClick = index => {
        if (this.props.callback) this.props.callback(index);
    };

    render() {
        let imageGroupStyle = {
            width: "100%",
            height: "0",
            paddingBottom: "75%",
            overflow: "hidden",
            position: "relative"
        };
        let imageStyle = {
            width: "100%",
            height: "100%"
        };

        let animation = this.props.animation.split(",");
        let getImageList = _.map(this.props.images, (image, index) => {
            let imageContainerStyle = {
                position: "absolute",
                top: Math.floor(index / 2) * 50 + "%",
                left: 50 * (index % 2) + "%",
                width: "50%",
                height: "50%",
                overflow: "hidden"
            };
            return (
                <div key={"image-" + index} style={imageContainerStyle}>
                    <img
                        src={resolveImage(image.id, this.props.imageLibrary)}
                        style={imageStyle}
                        onClick={() => this.handleClick(index)}
                        alt="imageGroup"
                    />
                </div>
            );
        });

        return (
            <div style={imageGroupStyle}>
                <VelocityTransitionGroup
                    enter={{
                        animation: animation[0],
                        duration: animation[1],
                        stagger: animation[2]
                    }}
                    leave={{ animation: "fadeOut", duration: "0" }}
                    runOnMount
                >
                    {getImageList}
                </VelocityTransitionGroup>
            </div>
        );
    }
}
