import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell } from "recharts";

export default class QuoRadialProgressChart extends PureComponent {
    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        color: PropTypes.string,
        value: PropTypes.number.isRequired,
        top: PropTypes.number,
        left: PropTypes.number
    };
    static defaultProps = {
        width: 200,
        height: 200,
        color: "#8884d8"
    };
    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let data = [
            {
                name: isNaN(this.props.value)
                    ? "-"
                    : Math.round(this.props.value) + "%",
                value: isNaN(this.props.value)
                    ? 0
                    : Math.round(this.props.value)
            },
            {
                name: "",
                value: isNaN(this.props.value)
                    ? 100
                    : 100 - Math.round(this.props.value)
            }
        ];
        return (
            <div style={{ position: "relative" }}>
                <PieChart width={this.props.width} height={this.props.height}>
                    <Pie
                        data={data}
                        cx={"50%"}
                        cy={"50%"}
                        innerRadius={this.props.width / 2 - 40}
                        outerRadius={this.props.width / 2 - 20}
                        fill={this.props.color}
                        paddingAngle={5}
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={
                                    index === 0 ? this.props.color : "#cccccc"
                                }
                            />
                        ))}
                    </Pie>
                </PieChart>
                <div
                    style={{
                        position: "absolute",
                        top: isPortrait ? this.props.top : "50%",
                        left: isPortrait ? this.props.left : "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: isPortrait ? "1.2em" : "1.5em",
                        color: "#454545"
                    }}
                >
                    {data[0].name}
                </div>
            </div>
        );
    }
}
