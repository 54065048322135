import React from "react";
import PropTypes from "prop-types";
import { Image } from "semantic-ui-react";
import { Button } from "config/initializers/styles.app";
import _ from "lodash";
import { VelocityComponent, VelocityTransitionGroup } from "velocity-react";
import { resolveImage } from "config/app.helpers";
import { PinchView } from "react-pinch-zoom-pan";

export default class Zoomable extends React.Component {
    constructor() {
        super();
        this.state = {
            zoomIndex: 1,
            disable: true,
            showInfo: true,
        };
        this.hidePinchImage = this.hidePinchImage.bind(this);
    }

    static propTypes = {
        image: PropTypes.object.isRequired,
        animation: PropTypes.string,
        imageLibrary: PropTypes.array,
    };

    static defaultProps = {
        animation: "fadeIn,1000",
    };

    componentDidMount() {
        this.hidePinchImage();
    }

    hidePinchImage() {
        var $this = this;
        setTimeout(function () {
            /*istanbul ignore next - Initialization function only */
            $this.setState(_.merge($this.state, { showInfo: false }));
        }, 1000);
    }
    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;

        let imageWrapperStyle = {
            width: "100%",
            height: "0",
            paddingBottom: "75%",
            overflow: "hidden",
            position: "relative",
        };
        let imageStyle = {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            overflow: "auto",
        };
        let pinchImage = {
            width: "50%",
            height: "auto",
            zIndex: "2",
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: this.state.showInfo ? "block" : "none",
        };
        let btnWrapperStyle = {
            position: "absolute",
            left: "10px",
            top: "10px",
            zIndex: "1",
            fontSize: "1.5em",
            display:
                /*istanbul ignore next - Initialization function only */
                this.props.fullScreen === true ? "none !important" : "block",
        };
        //let isPortrait = window.isPortrait;
        let animation = this.props.animation.split(",");
        return (
            <VelocityTransitionGroup
                enter={{
                    animation: animation[0],
                    duration: animation[1],
                    delay: animation[2],
                }}
                leave={{ animation: "fadeOut", duration: "0" }}
                runOnMount
            >
                {!this.props.fullScreen && isPortrait ? (
                    /*istanbul ignore next - Initialization function only */
                    <div style={btnWrapperStyle}>
                        <Button
                            icon="expand"
                            onClick={
                                /* istanbul ignore next - react-persist function */
                                () => this.props.toggleFullscreen(true)
                            }
                        />
                    </div>
                ) : null}
                <div style={imageWrapperStyle}>
                    <Image
                        src="assets/images/defaults/pinch.png"
                        style={pinchImage}
                    />
                    <div style={imageStyle}>
                        <PinchView
                            backgroundColor="#666"
                            maxScale={4}
                            containerRatio={(400 / 600) * 100}
                        >
                            <div
                                style={{
                                    display: "block",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                <VelocityComponent
                                    ref="velocity"
                                    key="image"
                                    animation={{ scale: this.state.zoomIndex }}
                                    duration={500}
                                    runOnMount
                                >
                                    <Image
                                        src={resolveImage(
                                            this.props.image.id,
                                            this.props.imageLibrary
                                        )}
                                        fluid
                                    />
                                </VelocityComponent>
                            </div>
                        </PinchView>
                    </div>
                </div>
            </VelocityTransitionGroup>
        );
    }
}
