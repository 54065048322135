import React from "react";
import { Card, Image, Divider } from "semantic-ui-react";
import { Button } from "config/initializers/styles.app";

import { getAppConfig } from "config/client.config";
import { resolveImage } from "config/app.helpers";
import { Translations } from "config/app.translations";
import _ from "lodash";
import PropTypes from "prop-types";


export default class ImageOverlay extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            image: PropTypes.object,
            paragraph: PropTypes.string
        }),
        imageLibrary: PropTypes.array,
        selectedIndex: PropTypes.number,
        gotoNextSlide: PropTypes.func
    };

    render() {
        let overlayTranslations = _.find(Translations, {
            language: "en"
        }).overlay;
        /* istanbul ignore next - Request and Pure passthrough */
        let { feedback } = this.props.data,
            feedbackArr =
                !feedback || feedback.length === 0
                    ? ["", "", overlayTranslations.thanks]
                    : feedback;

        let refinedFeedback = _.map(feedbackArr, (fb, index) => {
            var res = {
                header: "",
                text: fb,
                color: "green"
            };
            /* istanbul ignore next - Request and Pure passthrough */
            if (feedback && feedback.length > 0) {
                if (index === 0) {
                    res = {
                        ...res,
                        header: overlayTranslations.correct,
                        icon: "check circle",
                        color: "green"
                    };
                } else if (index === 1) {
                    res = {
                        ...res,
                        header: overlayTranslations.incorrect,
                        icon: "remove circle",
                        color: "red"
                    };
                } else {
                    res = {
                        ...res,
                        header: overlayTranslations.thanks,
                        icon: "thumbs up",
                        color: "yellow"
                    };
                }
            }
            return res;
        });
        let cardStyle = {
            borderRadius: 0,
            boxShadow: "none",
            textAlign: "center"
        };

        let headerStyle = {
            ...getAppConfig().headerStyle,
            fontSize: "4em"
        };
        let textStyle = {
            ...getAppConfig().textStyle,
            fontSize: "2em",
            lineHeight: "1.2"
        };
        let cardList = () => {
            return _.map(refinedFeedback, (feedback, index) => {
                return (
                    <Card
                        className={
                            /* istanbul ignore next - Request and Pure passthrough */
                            this.props.selectedIndex === index ? "show" : "hide"
                        }
                        style={cardStyle}
                        key={"card_" + index}
                    >
                        <Card.Content>
                            <Card.Header style={headerStyle}>
                                {feedback["header"]}
                            </Card.Header>
                            <Image
                                src={resolveImage(
                                    this.props.data.image === undefined
                                        ? this.props.data.images
                                        : this.props.data.image.id,
                                    this.props.imageLibrary
                                )}
                            />
                            {(() => {
                                /* istanbul ignore next - Request and Pure passthrough */
                                if (this.props.data.paragraph !== undefined) {
                                    return (
                                        <div>
                                            <Card.Description style={textStyle}>
                                                {this.props.data.paragraph}
                                            </Card.Description>
                                            <Divider fitted />
                                        </div>
                                    );
                                }
                            })()}
                            <Card.Description style={textStyle}>
                                {feedback["text"]}
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Button onClick={this.props.gotoNextSlide} style={{ display: "initial" }}>
                                {overlayTranslations.close}
                            </Button>
                        </Card.Content>
                    </Card>
                );
            });
        };
        return (
            <div>
                <Card.Group>{cardList()}</Card.Group>
            </div>
        );
    }
}
