import _ from "lodash";
import { TemplateList } from "schema/TemplateSchema.react";
import { defaultImages } from "appRedux/helpers/defaultImages";

//=====================================================================================================
// Define different Deck Types for the Aggregation
//=====================================================================================================

const fyStartQtr = 2; // This is the calendar quarter used to determine financial year

const slideTypeDecks = [
    "qdf",
    "tutorial",
    "survey",
    "assessment",
    "quiz",
    "casestudy",
    "docdeck",
    "document",
];
const quizTypeDecks = ["assessment", "quiz", "casestudy"];
const scoreTypeDecks = [
    "assessment",
    "quiz",
    "casestudy",
    "scormdeck",
    "game",
    "dialogue",
];
const pointTypeDecks = ["game"];

const slidable = (readerType) => slideTypeDecks.includes(readerType);
const quizable = (readerType) => quizTypeDecks.includes(readerType);
const scorable = (readerType) => scoreTypeDecks.includes(readerType);
const pointable = (readerType) => pointTypeDecks.includes(readerType);

//=====================================================================================================
// Initialize Deck Attempt when starting a Deck
//=====================================================================================================

export const initAttempt = (input) => {
    // Get the Deck data that is being imported into the attempt
    const deckData = input.deck;
    const readerType = deckData.readerType;
    const deckContent = deckData.content;

    // Check if content is an array of slide objects
    const hasContentArray =
        (deckContent !== undefined && Array.isArray(deckContent)) ||
        (deckContent !== undefined &&
            deckContent.content !== undefined &&
            Array.isArray(deckContent.content));

    // Get number of questions in the deck based on their template
    const questionCount = hasContentArray
        ? _.filter(
            Array.isArray(deckContent) ? deckContent : deckContent.content,
            (slide) => {
                return TemplateList[slide.template].scorable;
            }
        ).length
        : 0;
    const initialDeckState = Object.assign({}, deckData, {
        sessionId: input.sessionId,
        courseId: deckData.courseId,
        attemptTime: input.attemptTime,
        timeSpent: 0,
        viewedPercentage: 0,
        passingPercentage: getNonNull(deckData.passingPercentage),
        completion: getNonNull(input.completion),
        aggregated: false,

        //---------------------------------------------------------------------------
        // Slidable Deck Types
        //---------------------------------------------------------------------------
        viewedSlides: defif(slidable(readerType), []),
        slideCount: defif(
            slidable(readerType) && hasContentArray,
            deckContent == null ? [] : deckContent.length
        ),
        lastSlide: defif(slidable(readerType), 0),
        images: defif(
            ["qdf", "assessment", "survey", "game"].includes(readerType),
            deckData.images === undefined ? defaultImages : deckData.images
        ),

        //---------------------------------------------------------------------------
        // Quizable Deck Types
        //---------------------------------------------------------------------------
        questionsTotal: defif(
            quizable(readerType) && hasContentArray,
            questionCount
        ),
        questionsAttempted: defif(quizable(readerType), 0),
        questionsCorrect: defif(quizable(readerType), 0),

        //---------------------------------------------------------------------------
        // Scorable Deck Types
        //---------------------------------------------------------------------------
        score: defif(scorable(readerType), 0),
        scoreStats: defif(scorable(readerType), {}),
        isFinalExam: defif(
            scorable(readerType),
            deckData.isFinalExam === undefined ? false : deckData.isFinalExam
        ),
        isTopicTest: defif(
            scorable(readerType),
            deckData.isTopicTest === undefined ? false : deckData.isTopicTest
        ),
        subStates: {}
    });
    return initialDeckState;
};

//=====================================================================================================
// Update Deck Attempt while going through a Deck
//=====================================================================================================

export const updateAttempt = (state, input) => {
    const readerType = state.readerType;
    const sTimeSpent = state.timeSpent,
        sQuestionsAttempted = state.questionsAttempted,
        sQuestionsCorrect = state.questionsCorrect,
        sQuestionsTotal = state.questionsTotal,
        sViewedSlides = state.viewedSlides == null ? [] : state.viewedSlides;

    let sContentLength =
        state.content != null && Array.isArray(state.content)
            ? state.content.length
            : 0;
    sContentLength =
        state.content != null &&
            state.content.content != null &&
            Array.isArray(state.content.content)
            ? state.content.content.length
            : sContentLength;

    const iCompletion = input.completion,
        iTimeSpent = input.timeSpent,
        iCurrentSlide = input.currentSlide,
        iNextSlide = input.nextSlide;

    //---------------------------------------------------------------------------
    // Viewed Slides
    // => If Deck Type has slides, add slide to viewed slides,
    //---------------------------------------------------------------------------
    const oViewedSlides =
        iCurrentSlide == null || iNextSlide == null
            ? []
            : [...sViewedSlides, iNextSlide];

    //---------------------------------------------------------------------------
    // Viewed Percentage
    // => If Deck Type has slides, return unique slides viewed as a percentage of total slides
    // => Else return Completion Percentage
    //---------------------------------------------------------------------------
    const oViewedPercentage =
        oViewedSlides.length > 0 && sContentLength > 0
            ? getPercent(_.uniq(oViewedSlides).length, sContentLength)
            : getNonNull(readerType === "videck" || "video" ? iCompletion : iCompletion ? 100 : 0);

    //---------------------------------------------------------------------------
    // Scoring Calculations
    // => App sends incrementScore trigger as per readerType
    // => Increment attempted and correct question counts if triggered and calculate score
    // => incrementScore trigger works for scoring only if Testing Understanding
    // => incrementScore trigger contains the score for games and scorms, so used directly in such cases
    // => incrementScore trigger is null for non scorable decks and is captured as is
    //---------------------------------------------------------------------------
    const oQuestionsAttempted =
        input.incrementScore == null
            ? sQuestionsAttempted
            : sQuestionsAttempted + 1;
    const oQuestionsCorrect =
        input.incrementScore === true
            ? sQuestionsCorrect + 1
            : sQuestionsCorrect;
    const oScore =
        sQuestionsTotal > 0
            ? getPercent(oQuestionsCorrect, sQuestionsTotal)
            : input.incrementScore;

    const response = {
        completion: getNonNull(iCompletion),
        timeSpent: sTimeSpent + iTimeSpent,
        viewedPercentage: oViewedPercentage,

        viewedSlides: defif(slidable(readerType), oViewedSlides),
        lastSlide: defif(slidable(readerType), iNextSlide),

        questionsAttempted: defif(
            quizable(readerType),
            getNonNull(oQuestionsAttempted)
        ),
        questionsCorrect: defif(
            quizable(readerType),
            getNonNull(oQuestionsCorrect)
        ),
        score: defif(scorable(readerType), oScore),
    };

    return response;
};

//=====================================================================================================
// Update Deck State after completing a Deck
//=====================================================================================================

export const aggregateDeckState = (state, input) => {
    const readerType = input.readerType;
    const courseType = input.courseType;
    const sTimeSpent = getNonNull(state.timeSpent),
        sCompletion = state.completion,
        sCompletionDate = state.completionDate,
        sViewedPercentage = state.viewedPercentage,
        sViewedSlides = state.subStates ? state.subStates.viewedSlides || [] : [],
        sFullAttempts = getNonNull(state.subStates.fullAttempts),
        sViewAttempts = getNonNull(state.viewAttempts),
        sScore = state.score,
        sScoreStats = state.subStates ? state.subStates.scoreStats || {} : {},
        sPoints = state.points || 0,
        sGamePoints = state.gamePoints;

    const iCompletion = input.completion,
        iScore = input.score,
        iTimeSpent = input.timeSpent,
        iSlideCount = input.slideCount,
        iPassingPercentage = input.passingPercentage,
        iViewedPercentage = input.viewedPercentage,
        iViewedSlides = input.viewedSlides,
        iPoints = parseInt(input.points, 0);

    const eventDate = Date.now();

    const oViewedSlides = _.flatten([...sViewedSlides, iViewedSlides]);
    const oViewedPercentage =
        oViewedSlides.length > 0 && iSlideCount != null
            ? getPercent(_.uniq(_.flatten(oViewedSlides)).length, iSlideCount)
            : Math.max(sViewedPercentage, iViewedPercentage);
    const oScore = courseType === "exam" ?
        iViewedPercentage > 0 && iScore != null
            ? Math.max(sScore, iScore)
            : sScore :
        iViewedPercentage === 100 && iScore != null
            ? Math.max(sScore, iScore)
            : sScore;

    let oScoreStats = sScoreStats;
    if (iScore != null) {
        oScoreStats = {
            min:
                sScoreStats.min == null
                    ? iScore
                    : Math.min(sScoreStats.min, iScore),
            max:
                sScoreStats.max == null
                    ? iScore
                    : Math.max(sScoreStats.max, iScore),
            average:
                sScoreStats.average == null
                    ? iScore
                    : Math.round(
                        (sScoreStats.average * sFullAttempts + iScore) /
                        (sFullAttempts + 1)
                    ),
            first: sScoreStats.first === null ? iScore : sScoreStats.first,
            last: iScore,
            track:
                sScoreStats.track == null
                    ? [iScore]
                    : [...sScoreStats.track, iScore],
        };
    }

    const oCompletion = () => {
        switch (input.readerType) {
            case "qdf":
            case "survey":
            case "docdeck":
            case "certdeck":
            case "scormdeck":
            case "badgedeck":
            case "medaldeck":
            case "rewarddeck":
                return iPassingPercentage
                    ? oViewedPercentage >= iPassingPercentage
                    : oViewedPercentage === 100;
            case "videck":
                return iPassingPercentage
                    ? oViewedPercentage >= iPassingPercentage
                    : oViewedPercentage >= 100;
            case "quiz":
            case "casestudy":
                return iViewedPercentage === 100
                    ? iScore >= iPassingPercentage
                    : false;
            case "assessment":
                if (courseType === "exam") {
                    return iViewedPercentage > 0
                        ? iScore >= iPassingPercentage
                        : false;
                } else {
                    return iViewedPercentage === 100
                        ? iScore >= iPassingPercentage
                        : false;
                }
            case "game":
            case "dialogue":
            default:
                return iCompletion;
        }
    };
    const oCompletionDate = oCompletion() && sCompletionDate == null
        ? eventDate
        : sCompletionDate;

    const oPoints = oCompletionDate !== null && sGamePoints === 0
        ? iPoints
        : sGamePoints;


    return {
        viewedPercentage: oViewedPercentage,
        viewAttempts: sViewAttempts + 1,
        timeSpent: sTimeSpent + iTimeSpent,
        score: oScore,
        points:
            pointable(readerType) && oCompletion()
                ? getNonNull(sPoints) + 100
                : 0,
        completion: sCompletion || oCompletion(),
        completionDate: oCompletionDate,
        lastPosition: input.lastSlide,

        subStates: {
            viewedSlides: defif(slidable(readerType), oViewedSlides),
            response: defif(
                ["qdf", "survey"].includes(input.readerType),
                _.merge(state.response, input.response)
            ),
            fullAttempts: defif(
                quizable(readerType),
                iViewedPercentage === 100 || oCompletion()
                    ? sFullAttempts + 1
                    : sFullAttempts
            ),
            passedAttempts: defif(
                quizable(readerType),
                oCompletion() ? state.passedAttempts + 1 : state.passedAttempts
            ),
            scoreStats: defif(
                scorable(readerType),
                courseType === "exam" ? oScoreStats
                    : iViewedPercentage !== 100 && input.readerType !== "scormdeck"
                        ? sScoreStats
                        : oScoreStats
            ),
        },
        gamePoints: oPoints
    };
};

//=====================================================================================================
// Aggregate History Object
//=====================================================================================================

const aggregateUnitHistory = (unitHistory, data) => {
    if (unitHistory)
        return {
            viewAttempts: getNonNull(unitHistory.viewAttempts) + 1,
            timeSpent:
                getNonNull(unitHistory.timeSpent) + getNonNull(data.timeSpent),
            points: pointable(data.readerType)
                ? getNonNull(unitHistory.points) + 100
                : 0,
        };
    else
        return {
            viewAttempts: 1,
            timeSpent: getNonNull(data.timeSpent),
            points: pointable(data.readerType) ? 100 : 0,
        };
};
export const aggregateHistory = (sHistory, input) => {
    let oHistory = _.cloneDeep(sHistory) || {};
    let dateObj = new Date(input.eventDate);

    // Select the appropriate History keys to update
    //-----------------------------------------------
    let month = dateObj.getMonth() + 1; //months from 1-12
    let quarter = Math.ceil(month / 3);
    let year = dateObj.getFullYear();
    let finYear = quarter < fyStartQtr ? year : year + 1;

    //-----------------------------------------------

    if (oHistory.f == null) {
        oHistory.f = {};
    }
    if (oHistory.y == null) {
        oHistory.y = {};
    }
    if (oHistory.q == null) {
        oHistory.q = {};
    }
    if (oHistory.m == null) {
        oHistory.m = {};
    }

    oHistory.f[finYear] = aggregateUnitHistory(
        oHistory.f[finYear],
        input.attempt
    );
    oHistory.y[year] = aggregateUnitHistory(oHistory.y[year], input.attempt);
    oHistory.q[year + "Q" + quarter] = aggregateUnitHistory(
        oHistory.q[year + "Q" + quarter],
        input.attempt
    );
    oHistory.m[year + "M" + month] = aggregateUnitHistory(
        oHistory.m[year + "M" + month],
        input.attempt
    );

    return oHistory;
};

//=====================================================================================================
// Update Topic State and History after completing a Deck
//=====================================================================================================

export const aggregateTopicStateAndHistory = (state, input) => {
    const topicId = input.topicId;
    const thisTopic = Object.assign({}, state[topicId]);

    if (thisTopic.currentState == null) {
        thisTopic.currentState = {
            subStates: [],
            timeSpent: 0,
            completion: false,
            completionDate: null,
            points: 0,
            viewAttempts: 0,
            gamePoints: 0
        };
    }
    const sSubStates = thisTopic.currentState.subStates,
        sTimeSpent = thisTopic.currentState.timeSpent || 0,
        sCompletion = thisTopic.currentState.completion,
        sCompletionDate = thisTopic.currentState.completionDate,
        sPoints = thisTopic.currentState.points || 0,
        sViewAttempts = thisTopic.currentState.viewAttempts,
        sGamePoints = thisTopic.currentState.gamePoints || 0;

    const iTimeSpent = input.attempt.timeSpent,
        iCompletion = input.completion,
        iCompletionDate = input.eventDate,
        iSequence = input.sequence,
        iGamePoints = thisTopic.points;

    //---------------------------------------------------------------------------
    // Get the Current State of Subcomponents
    //---------------------------------------------------------------------------

    let oSubStates = sSubStates;
    oSubStates[input.deckId] = iCompletion;
    const completedDecks = _.filter(oSubStates, (deck) => deck === true);

    //---------------------------------------------------------------------------
    // Viewed percentage of topic is percentage of completed subcomponents
    //---------------------------------------------------------------------------
    const oViewedPercentage = getPercent(
        completedDecks.length,
        _.keys(oSubStates).length
    );

    //---------------------------------------------------------------------------
    // Completion of topic is completion of topic test or final exam or percentage of completed decks
    //---------------------------------------------------------------------------
    const oCompletion =
        input.isTopicTest || input.isFinalExam
            ? iCompletion
            : oViewedPercentage === 100;

    const oGamePoints = oCompletion !== false && sGamePoints === 0 ? iGamePoints : sGamePoints;
    //---------------------------------------------------------------------------
    // Calculate overall state
    //---------------------------------------------------------------------------
    thisTopic.currentState = {
        viewedPercentage: oViewedPercentage,
        viewAttempts: sViewAttempts + 1,
        timeSpent: sTimeSpent + iTimeSpent,
        score: oCompletion ? 100 : oViewedPercentage,
        points:
            pointable(input.attempt.readerType) && oCompletion
                ? sPoints + 100
                : sPoints,
        completion: sCompletion || oCompletion,
        completionDate:
            oCompletion && sCompletionDate == null
                ? iCompletionDate
                : sCompletionDate,
        lastPosition: iSequence,
        subStates: oSubStates,
        gamePoints: oGamePoints
    };

    //---------------------------------------------------------------------------
    // Update History
    //---------------------------------------------------------------------------
    thisTopic.history = aggregateHistory(thisTopic.history, input);

    let newState = Object.assign({}, state);
    newState[topicId] = thisTopic;
    return Object.assign({}, state, newState);
};

//=====================================================================================================
// Update Course State and History after completing a Deck
//=====================================================================================================

export const aggregateCourseStateAndHistory = (state, input) => {
    const thisCourse = Object.assign({}, input.course);

    if (thisCourse.currentState == null) {
        thisCourse.currentState = {
            subStates: [],
            timeSpent: 0,
            completion: false,
            completionDate: null,
            score: null,
            points: 0,
            viewAttempts: 0,
            gamePoints: 0,
            totalPoints: 0
        };
    }

    const sSubStates = thisCourse.currentState.subStates,
        sTimeSpent = thisCourse.currentState.timeSpent || 0,
        sCompletion = thisCourse.currentState.completion,
        sCompletionDate = thisCourse.currentState.completionDate,
        sScore = thisCourse.currentState.score || null,
        sPoints = thisCourse.currentState.points || 0,
        sViewAttempts = thisCourse.currentState.viewAttempts,
        sGamePoints = thisCourse.currentState.gamePoints || 0,
        sTotalGamePoints = thisCourse.currentState.totalPoints || 0;

    const iTimeSpent = input.attempt.timeSpent,
        iCompletion = input.topic.currentState.completion,
        iCompletionDate = input.decks[input.deckId].currentState.completionDate,
        iScore = input.score,
        iSequence = input.sequence,
        iGamePoints = thisCourse.points;

    //---------------------------------------------------------------------------
    // Get the Current State of Subcomponents
    //---------------------------------------------------------------------------

    let oSubStates = sSubStates;
    oSubStates[input.topic._id] = iCompletion;

    //---------------------------------------------------------------------------
    // Viewed percentage of Course is percentage of completed decks in the course
    //---------------------------------------------------------------------------

    const decksInCourse = _.flatten(
        _.map(thisCourse.locations, (location) => {
            return _.map(location.contentList, "_id");
        })
    );
    const decksComplete = _.filter(
        decksInCourse,
        (deckId) => input.decks[deckId].currentState.completion
    );

    const oViewedPercentage = getPercent(
        decksComplete.length,
        decksInCourse.length
    );

    //---------------------------------------------------------------------------
    // Completion of course is completion of final exam or percentage of completed decks
    //---------------------------------------------------------------------------
    const oCompletion = input.isFinalExam
        ? iCompletion
        : oViewedPercentage === 100;
    //---------------------------------------------------------------------------
    // If this deck is a final exam, score of course is higher of current score and past score
    //---------------------------------------------------------------------------
    const oScore = input.isFinalExam ? Math.max(sScore, iScore) : sScore;

    const oCompletionDate = oCompletion && sCompletionDate == null
        ? iCompletionDate
        : sCompletionDate;

    const oGamePoints = oCompletionDate !== null && sGamePoints === 0 ? iGamePoints : sGamePoints;

    const oTotalGamePoints = sTotalGamePoints;

    //---------------------------------------------------------------------------
    // Calculate overall state
    //---------------------------------------------------------------------------
    thisCourse.currentState = {
        viewedPercentage: oViewedPercentage,
        viewAttempts: sViewAttempts + 1,
        timeSpent: sTimeSpent + iTimeSpent,
        score: oScore,
        points:
            pointable(input.attempt.readerType) && oCompletion
                ? sPoints + 100
                : sPoints,
        completion: sCompletion || oCompletion,
        completionDate: oCompletionDate,
        lastPosition: iSequence,
        subStates: oSubStates,
        gamePoints: oGamePoints,
        totalPoints: oTotalGamePoints
    };

    //---------------------------------------------------------------------------
    // Update History
    //---------------------------------------------------------------------------
    thisCourse.history = aggregateHistory(thisCourse.history, input);

    let newState = Object.assign({}, state);
    newState[thisCourse._id] = thisCourse;
    return Object.assign({}, state, newState);
};

//=====================================================================================================
// Update Program State and History after completing a Deck
//=====================================================================================================

export const aggregateProgramStateAndHistory = (state, input) => {
    const thisProgram = Object.assign({}, input.program);

    const sSubStates = thisProgram.currentState.subStates,
        sTimeSpent = thisProgram.currentState.timeSpent || 0,
        sCompletion = thisProgram.currentState.completion,
        sCompletionDate = thisProgram.currentState.completionDate,
        sPoints = thisProgram.currentState.points || 0,
        sViewAttempts = thisProgram.currentState.viewAttempts;

    const iTimeSpent = input.attempt.timeSpent,
        iCompletion = input.topic.completion,
        iCompletionDate = input.eventDate,
        iSequence = input.course.sequence;

    //---------------------------------------------------------------------------
    // Get the Current State of Subcomponents
    //---------------------------------------------------------------------------

    let oSubStates = sSubStates;
    oSubStates[input.course._id] = iCompletion;

    //---------------------------------------------------------------------------
    // Viewed percentage of Program is percentage of completed courses in the program
    //---------------------------------------------------------------------------

    const oViewedPercentage = getPercent(
        _.filter(oSubStates, (course) => course === true).length,
        _.keys(oSubStates).length
    );

    //---------------------------------------------------------------------------
    // Completion of Program and score is percentage of completed courses
    //---------------------------------------------------------------------------
    const oCompletion = oViewedPercentage === 100;
    const oScore = oViewedPercentage;

    //---------------------------------------------------------------------------
    // Calculate overall state
    //---------------------------------------------------------------------------
    thisProgram.currentState = {
        viewedPercentage: oViewedPercentage,
        viewAttempts: sViewAttempts + 1,
        timeSpent: sTimeSpent + iTimeSpent,
        score: oScore,
        points:
            pointable(input.attempt.readerType) && oCompletion
                ? sPoints + 100
                : sPoints,
        completion: sCompletion || oCompletion,
        completionDate:
            oCompletion && sCompletionDate == null
                ? iCompletionDate
                : sCompletionDate,
        lastPosition: iSequence,
        subStates: oSubStates,
    };

    //---------------------------------------------------------------------------
    // Update History
    //---------------------------------------------------------------------------
    thisProgram.history = aggregateHistory(thisProgram.history, input);

    let newState = Object.assign({}, state);
    newState[thisProgram._id] = thisProgram;
    return Object.assign({}, state, newState);
};

//=====================================================================================================
// Update Learner State and History after completing a Deck
//=====================================================================================================

export const aggregateLearnerStateAndHistory = (state, input) => {
    const thisUser = Object.assign({}, input.user);

    const sTimeSpent =
        (thisUser.currentState && thisUser.currentState.timeSpent) || 0,
        sPoints = (thisUser.currentState && thisUser.currentState.points) || 0;

    const iTimeSpent = input.attempt.timeSpent,
        iCompletion = input.completion,
        iTotalSessions = input.sessions.totalSessions;

    const currentState = {
        sessions: iTotalSessions,
        timeSpent: sTimeSpent + iTimeSpent,
        points:
            pointable(input.attempt.readerType) && iCompletion
                ? sPoints + 100
                : sPoints,
    };

    const history = aggregateHistory(thisUser.history, input);
    let newState = Object.assign({}, state);
    newState.user.currentState = currentState;
    newState.user.history = history;
    return Object.assign({}, state, newState);
};

//=====================================================================================================
// Update Session History on Authentication
//=====================================================================================================

export const aggregateSessionHistory = (history, eventDate) => {
    let newHistory = _.cloneDeep(history);
    let dateObj = new Date(eventDate);

    let year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1; //months from 1-12
    let quarter = Math.ceil(month / 4);

    let finYear = quarter < fyStartQtr ? year : year + 1;

    delete newHistory.f["NaN"];
    delete newHistory.y["NaN"];
    delete newHistory.q["NaNQNaN"];
    delete newHistory.m["NaNMNaN"];

    newHistory.f[finYear] =
        newHistory.f[finYear] === undefined ? 1 : newHistory.f[finYear] + 1;

    newHistory.y[year] =
        newHistory.y[year] === undefined ? 1 : newHistory.y[year] + 1;

    newHistory.q[year + "Q" + quarter] =
        newHistory.q[year + "Q" + quarter] === undefined
            ? 1
            : newHistory.q[year + "Q" + quarter] + 1;

    newHistory.m[year + "M" + month] =
        newHistory.m[year + "M" + month] === undefined
            ? 1
            : newHistory.m[year + "M" + month] + 1;

    return newHistory;
};

const getPercent = (x, y) => Math.min(100, Math.round((x * 100) / y));
const getNonNull = (value) => (value == null ? 0 : value);
const defif = (value, result) => (value ? result : undefined);
