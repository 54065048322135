import React from "react";
import YouTube from "react-youtube";
import PropTypes from "prop-types";
// import { Icon } from "semantic-ui-react";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css";
import Vimeo from "@u-wave/react-vimeo";
import { Icon, } from "semantic-ui-react";
export default class VideoBox extends React.Component {
    constructor(props) {
        super(props);
        this._onPlay = this._onPlay.bind(this);
        this._onEnd = this._onEnd.bind(this);
        this._onReady = this._onReady.bind(this);
        this._onPause = this._onPause.bind(this);
        this.loadPlayer = this.loadPlayer.bind(this);
        this.state = {
            hasError: false,
            fullScreenOpen: false,
            showControl: false,
            isYouTube: false,
            isVimeo: false,
        };
    }
    static propTypes = {
        url: PropTypes.string,
        autoplay: PropTypes.bool,
        automute: PropTypes.bool,
        loop: PropTypes.bool,
        _onPlay: PropTypes.func,
        _onEnd: PropTypes.func,
        _onReady: PropTypes.func,
        _onPause: PropTypes.func,
    };
    static defaultProps = {
        url: "https://www.youtube.com/watch?v=DjXp7AFgSdk",
        autoplay: false,
        mobileView: false,
        automute: true,
        rel: 0,
    };

    componentDidMount() {
        if (
            this.props.url.indexOf("youtu.be") !== -1 ||
            this.props.url.indexOf("youtube") !== -1
        ) {
            this.setState({ isYouTube: true });
        } else if (this.props.url.indexOf("vimeo") !== -1) {
            this.setState({ isVimeo: true });
        } else {
            this.refs.player.subscribeToStateChange(
                this.handleStateChange.bind(this)
            );
        }
    }

    toggleYtFullscreen = () =>
        this.setState({ fullScreenOpen: !this.state.fullScreenOpen });

    handleStateChange(state, prevState) {
        let event = {
            target: this.refs.player,
        };
        let isPortrait = window.innerHeight > window.innerWidth;
        if (state.readyState && !prevState.readyState) {
            if (this.props._onReady) this.props._onReady(event);
        }
        if (state.hasStarted && !prevState.hasStarted) {
            if (this.props._onPlay) this.props._onPlay(event);
        }
        if (state.paused && !prevState.paused) {
            if (this.props._onPause) this.props._onPause(event);
        }
        if (state.ended && !prevState.ended) {
            if (this.props._onEnd) this.props._onEnd(event);
        }
        if (state.isFullscreen && !prevState.isFullscreen) {
            this.setState(
                {
                    fullScreenOpen: !this.state.fullScreenOpen,
                },
                () => (isPortrait ? this.refs.player.toggleFullscreen() : null)
            );
        }
    }

    getVideoId = (url) => {
        let videoId = "";
        if (url.indexOf("v=") === -1) {
            var video_array = url.split("/");
            videoId = video_array[video_array.length - 1];
        } else {
            videoId = url.split("v=")[1];
            var ampersandPosition = videoId.indexOf("&");
            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
        }
        return videoId;
    };

    _onReady(event) {
        if (this.props.autoplay) {
            event.target.playVideo();
        }

        if (this.props._onReady) this.props._onReady(event);
    }

    _onPlay = (event) => { };

    _onEnd = (event) => {
        if (this.props.loop) event.target.playVideo();
        if (this.props._onEnd) this.props._onEnd(event);
    };

    _onPause = (event) => {
        if (this.props._onPause) this.props._onPause(event);
    };

    _onError = (event) => {
        this.setState({ hasError: true });
    };

    loadPlayer = () => {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        if (this.state.hasError) {
            return (
                <div>
                    {" "}
                    Error While loading video.. please make sure that you are
                    connected to internet and try again later{" "}
                </div>
            );
        } else {
            const opts = {
                playerVars: {
                    autoplay: this.props.autoplay ? 1 : 0,
                    loop: this.props.loop ? 1 : 0,
                    rel: 0,
                    controls: this.props.hideControls ? 0 : 1,
                },
                width: "100%",
                height: "100%",
            };

            let videoMobile = {
                width: this.state.fullScreenOpen ? "100vh" : "100%",
                height: this.state.fullScreenOpen ? "100vw" : "100%",
            };
            let isYoutubeVideoWrap = {
                width: this.state.fullScreenOpen ? "100vh" : "100%",
                height: this.state.fullScreenOpen ? "100vw" : "100%",
                padding: 0
            }
            let videoDesktop = {
                width: "100%",
                height: "100%",
            };
            let videoWrap = isPortrait ? videoMobile || isYoutubeVideoWrap : videoDesktop;

            if (this.state.isVimeo) {
                return (
                    <div
                        style={videoWrap}
                        onClick={this.setShowControl}
                        className={isPortrait ? "video-responsive" : "video-responsive vimeo-embed-container"}
                    >
                        <Vimeo
                            video={this.props.url}
                            autoplay={true}
                            playerOptions={{ width: window.innerWidth }}
                            onReady={this._onReady}
                            onPlay={this._onPlay}
                            onEnd={this._onEnd}
                            onError={this._onError}
                            onPause={this._onPause}
                        />
                    </div>
                );
            } else if (this.state.isYouTube) {
                return (
                    <div
                        className="video-responsive"
                        style={videoWrap}
                        onClick={this.setShowControl}
                    >
                        <YouTube
                            videoId={this.getVideoId(this.props.url)}
                            opts={opts}
                            onReady={this._onReady}
                            onPlay={this._onPlay}
                            onEnd={this._onEnd}
                            onError={this._onError}
                            onPause={this._onPause}
                        />
                    </div>
                );
            } else {
                return (
                    <div
                        className="video-responsive"
                        style={isPortrait ? isYoutubeVideoWrap : videoWrap}
                        onClick={this.setShowControl}
                    >
                        <Player
                            src={this.props.url}
                            autoplay={this.props.autoplay}
                            ref={"player"}
                        >
                            <BigPlayButton position="center" />
                        </Player>
                    </div>
                );
            }
        }
    };
    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let playerWrapper = {
            height: "100%",
            width: "100%",
            overflow: "hidden",
        };
        let modalStyle = {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
            padding: "0",
            margin: "0",
            zIndex: "1003",
            background: "#666666",
        };
        let normalStyle = {
            display: "block",
            height: "100%",
            overflow: "hidden",
        };
        let ytFullStylePortrait = {
            position: "absolute",
            bottom: "20px",
            right: "15px",
            fontSize: "1.1em",
        };
        let ytFullStyleLandscape = {
            position: "absolute",
            top: "16px",
            right: "10px",
            fontSize: "1.1em",
            zIndex: "1111",
            display: "block"
        };
        let player = this.loadPlayer();
        if (this.state.hasError) {
            return null;
        } else {
            return (
                <div style={playerWrapper}>
                    <div
                        style={
                            this.state.fullScreenOpen && isPortrait
                                ? modalStyle
                                : normalStyle
                        }
                    >
                        <div
                            className={
                                this.state.fullScreenOpen && isPortrait
                                    ? "rotated"
                                    : "normalStyle"
                            }
                        >
                            {player}
                        </div>
                    </div>
                    { isPortrait && (
                        <div
                            style={
                                this.state.fullScreenOpen
                                    ? ytFullStyleLandscape
                                    : ytFullStylePortrait
                            }
                            onClick={this.toggleYtFullscreen}
                        >
                            <Icon
                                name="expand"
                                circular
                                inverted
                                color="yellow"
                                onClick={this.toggleYtFullscreen}
                            />
                        </div>
                    )}
                </div>
            );
        }
    }
}
