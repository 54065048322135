import _ from "lodash";
import { REHYDRATE } from "redux-persist/constants";
import {
    LOGIN,
    LOGOUT,
    SESSION,
    OMNIAUTHLOGIN,
    UPDATE_CONFIG,
    UPDATE_LEARNER_DETAILS,
    UPDATE_LEARNER_STATUS,
    CLIENT_LOGO,
    INITIAL_PASSWORD_UPDATE,
} from "appRedux/helpers/reduxConstants";
import { aggregateLearnerStateAndHistory } from "./helpers/aggregatorFunctions";

const initialAuthState = {
    error: {},
    status: "",
    token: null,
    user: {
        client: {
            appConfiguration: {},
        },
        currentState: {},
        history: {
            f: {},
            y: {},
            q: {},
            m: {},
        },
    },
    isUserLoggedIn: false,
    sessions: [],
};

const AuthenticationReducer = (state = initialAuthState, action) => {
    let newState;
    switch (action.type) {
        case REHYDRATE:
            let auth = action.payload.auth;
            if (auth) {
                return { ...state, ...auth };
            } else {
                return state;
            }
        case LOGIN:
            if (action.status === "authenticating") {
                return { ...state, status: action.status, error: {} };
            } else if (action.status === "success") {
                return {
                    ...state,
                    status: action.status,
                    user: {
                        ...action.data,
                        id: action.data.id,
                        currentState: {},
                        history: {
                            f: {},
                            y: {},
                            q: {},
                            m: {},
                        },
                    },
                    isUserLoggedIn: true,
                    error: {},
                };
            } else if (action.status === "error")
                return { ...state, status: action.status, error: action.err };
            else {
                return state;
            }
        case LOGOUT:
            if (action.status === "authenticating") {
                return { ...state, status: action.status, error: {} };
            } else if (action.status === "success") {
                return {
                    ...state,
                    status: action.status,
                    user: null,
                    isUserLoggedIn: false,
                    error: {},
                };
            } else if (action.status === "error")
                return {
                    ...state,
                    status: action.status,
                    user: null,
                    isUserLoggedIn: false,
                    error: action.err,
                };
            else {
                return state;
            }
        case SESSION:
            let sessions = [...state.sessions];
            let startTime = new Date().getTime();
            let sessionId = 0;
            const userId = JSON.stringify(state.user.id);
            if (sessions.length) {
                let lastSession = _.pullAt(sessions, sessions.length - 1);
                lastSession.isActive = false;
                lastSession.endTime = startTime;
                sessions.push(lastSession);
                sessionId = _.replace(lastSession.sessionId, userId, "");
            }
            let sessionData = {
                sessionId: userId + sessionId,
                userId: userId,
                startTime: startTime,
                isActive: true,
                endTime: undefined,
            };
            sessions.push(sessionData);
            return { ...state, sessions: sessions };
        case OMNIAUTHLOGIN:
            if (action.status === "success") {
                return {
                    ...state,
                    status: action.status,
                    user: {
                        ...action.data,
                        id: action.data.id,
                    },
                    isUserLoggedIn: true,
                    error: {},
                };
            } else {
                return state;
            }
        case UPDATE_CONFIG:
            if (action.status === "success") {
                newState = _.cloneDeep(state);
                if (action.data.appConfig !== null)
                    newState.user.client.appConfiguration = Object.assign(
                        {},
                        newState.user.client.appConfiguration,
                        action.data.appConfig
                    );
                if (action.data.profileImage !== null)
                    newState.user.profile.profile_image.url =
                        action.data.profileImage;
                if (action.data.firstName !== null)
                    newState.user.first_name = action.data.firstName;
                if (action.data.lastName !== null)
                    newState.user.last_name = action.data.lastName;
                return newState;
            } else {
                return state;
            }
        case UPDATE_LEARNER_DETAILS:
            newState = _.cloneDeep(state);
            newState.user.details = Object.assign(
                {},
                newState.user.details,
                action.data.details
            );
            return newState;
        case CLIENT_LOGO:
            let clientDetails = {
                ...state,
                clientLogo: action.data.clientLogo,
                loginUrl: action.data.loginUrl,
                courseId: action.data.courseId,
                inductor: action.data.inductor
            };
            return clientDetails;
        case UPDATE_LEARNER_STATUS:
            return aggregateLearnerStateAndHistory(state, action.data);
        case INITIAL_PASSWORD_UPDATE:
            newState = _.cloneDeep(state);
            newState.user.initialUpdatePassword = action.data.initialUpdatePassword
            return newState;
        default:
            return state;
    }
};

export { AuthenticationReducer };
