// Import from NPM
// -------------------------------------
import React, { Component } from "react";
import localforage from "localforage";
import _ from "lodash";
import { Router, Route, hashHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import loadable from "@loadable/component";
import "semantic-ui-css/semantic.min.css";
import "config/overrule.css";
import { Helmet } from "react-helmet";
// import firebase from "./firebase";
// import '@firebase/messaging';

// Import from Config
// -------------------------------------
import {
    Store,
    persistStore,
    persistStoreConfig,
} from "backend/storage/mobile/mobile.store";
import { LOGOUT } from "appRedux/helpers/reduxConstants";
import { getAppConfig } from "config/client.config";
import { appConfig } from "config/initializers/config.app";
import { Loading } from "mobile/appComponents/loading/Loading.react";
import DesktopNavbar from "mobile/appComponents/navbar/DesktopNavbar.react";
import Navbar from "mobile/appComponents/navbar/Navbar.react.jsx";
import InductorNavbar from "mobile/appComponents/navbar/InductorNavbar.react";
import ErrorPage from "globals/error/ErrorPage.react";
import Footer from "mobile/appComponents/navbar/Footer.react";
// Import Routes
// -------------------------------------
const AuthenticationContainer = loadable(
    () => import("mobile/authentication/AuthenticationContainer.react"),
    {
        fallback: <Loading />,
    }
);
const QdfLoader = loadable(
    () => import("mobile/appComponents/navbar/QdfLoader.react"),
    {
        fallback: <Loading />,
    }
);
const Home = loadable(() => import("mobile/home/HomeContainer.react"), {
    fallback: <Loading />,
});
const Library = loadable(
    () => import("mobile/library/LibraryContainer.react"),
    {
        fallback: <Loading />,
    }
);
const Program = loadable(
    () => import("mobile/program/ProgramContainer.react"),
    {
        fallback: <Loading />,
    }
);
const Course = loadable(() => import("mobile/course/CourseContainer.react"), {
    fallback: <Loading />,
});
const Leaderboard = loadable(
    () => import("mobile/leaderboard/LeaderboardContainer.react"),
    {
        fallback: <Loading />,
    }
);
const Deck = loadable(() => import("mobile/deck/DeckContainer.react"), {
    fallback: <Loading />,
});
const Reports = loadable(
    () => import("mobile/analytics/AnalyticsContainer.react.jsx"),
    {
        fallback: <Loading />,
    }
);
const Feed = loadable(() => import("mobile/feed/FeedContainer.react"), {
    fallback: <Loading />,
});
const NotificationContainer = loadable(
    () => import("mobile/notification/NotificationContainer.react"),
    {
        fallback: <Loading />,
    }
);
const Rewards = loadable(
    () => import("mobile/course/components/AwardTabs.react"),
    {
        fallback: <Loading />,
    }
);
const HelpDesk = loadable(
    () => import("mobile/appComponents/helpDesk/HelpDeskContainer.react"),
    {
        fallback: <Loading />,
    }
);
const Offline = loadable(
    () => import("mobile/offline/OfflineContainer.react"),
    {
        fallback: <Loading />,
    }
);
const ForceUpdateContainer = loadable(
    () => import("mobile/home/ForceUpdateContainer.react"),
    {
        fallback: <Loading />,
    }
);
const SessionContainer = loadable(
    () => import("mobile/home/SessionContainer.react"),
    {
        fallback: <Loading />,
    }
);
const TermsContainer = loadable(
    () => import("mobile/home/TermsContainer.react"),
    {
        fallback: <Loading />,
    }
);
const Dashboard = loadable(
    () => import("mobile/blog/DashboardContainer.react"),
    {
        fallback: <Loading />,
    }
);
const Blog = loadable(() => import("mobile/blog/BlogContainer.react"), {
    fallback: <Loading />,
});
const Article = loadable(() => import("mobile/blog/ArticleContainer.react"), {
    fallback: <Loading />,
});
const EditProfile = loadable(
    () => import("mobile/home/EditProfileContainer.react"),
    {
        fallback: <Loading />,
    }
);
const ThemeContainer = loadable(() => import("mobile/blog/ThemeContainer.react"), {
    fallback: <Loading />,
});
const ProfileContainer = loadable(() => import("mobile/profile/ProfileContainer.react"), {
    fallback: <Loading />,
})
const ResetPassword = loadable(
    () => import("mobile/home/ResetPasswordContainer.react"),
    {
        fallback: <Loading />,
    }
);

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rehydrated: false,
        };
        this.requireLogin = this.requireLogin.bind(this);
        this.alreadyLoggedIn = this.alreadyLoggedIn.bind(this);
        this.manageStore = window.manageStore = this.manageStore.bind(this);
        this.appType = window.appType = "mobile";
        this.manageStore();
    }

    // componentDidMount() {
    //     const messaging = firebase.messaging();
    //     messaging
    //     .requestPermission()
    //     .then(() => {
    //         return messaging.getToken();
    //     })
    //     .then(token => {
    //         console.log("token....", token)
    //     })
    //     .catch(error => {
    //         console.log(error);
    //     });
    // }

    manageStore = (purge) => {
        if (purge === "purge") {
            console.log("Purging Store...");
            persistStore(Store, persistStoreConfig).purge();
        } else {
            if (window.localStorage) {
                persistStore(Store, persistStoreConfig, () => {
                    let existing_user = window.localStorage.getItem(
                        "reduxPersist:auth"
                    );
                    if (existing_user) {
                        localforage.setItem("reduxPersist:auth", existing_user);
                    } else {
                        console.log("Session initiated");
                    }
                    this.setState({ rehydrated: true });
                });
            }
        }
    };

    requireLogin(nextState, replace, callback) {
        let targetPath = window.location.href.split("#/")[1]; // Get the URL that you are trying to get to
        //--------------------------------------------------------
        // If the user is not logged in and there is no token in the url (Redirect to Login Page)
        //--------------------------------------------------------
        if (
            !Store.getState().auth.isUserLoggedIn &&
            !_.includes(targetPath, "token")
        ) {
            console.log("Unauthorized. Redirect to Login Page");
            // Redirect to local login path if login path is local
            if (appConfig.auth.routes.login.indexOf("http") === -1)
                replace(appConfig.auth.routes.login);
            // Redirect to external login path if login path is external
            // with the target path as the referrer
            else {
                if (appConfig.auth.externalURLParam && appConfig.auth.externalURLParam !== "") {
                    window.location = appConfig.auth.routes.login.replace(
                        appConfig.auth.externalURLParam,
                        targetPath.split("?")[0]
                    );
                } else {
                    window.location = appConfig.auth.routes.login;
                }
            }
            callback();
        }

        //--------------------------------------------------------
        // If the user is not logged in and there is a token in the url (Start Login Process)
        //--------------------------------------------------------
        else if (
            !Store.getState().auth.isUserLoggedIn &&
            _.includes(targetPath, "token")
        ) {
            console.log("Unauthorized. Token Login process started.");
            if (
                _.includes(targetPath, "login") ||
                _.includes(targetPath, "logout") ||
                _.includes(targetPath, "session")
            )
                targetPath = "home";
            localforage
                .setItem("lastLocation", targetPath.split("?")[0])
                .then(() => {
                    replace("/login?token=" + targetPath.split("?token=")[1]);
                    callback();
                })
                .catch((error) => {
                    console.log("Error: Unable to set last location", error);
                });
        }
        //--------------------------------------------------------
        // If the user is logged in and there is a token in the url (Honour Token Process)
        //--------------------------------------------------------
        else if (
            Store.getState().auth.isUserLoggedIn &&
            _.includes(targetPath, "token") &&
            !_.includes(targetPath, "session")
        ) {
            let newToken = targetPath.split("?token=")[1];
            console.log("Unauthorized. Honour Token process started.");
            localforage
                .removeItem("tokenHash", null)
                .then(() => {
                    Promise.resolve(
                        Store.dispatch({ type: LOGOUT, status: "success" })
                    )
                        .then(() => {
                            if (
                                targetPath === "" ||
                                _.includes(targetPath, "login") ||
                                _.includes(targetPath, "logout") ||
                                _.includes(targetPath, "session")
                            )
                                targetPath = "home";
                            localforage
                                .setItem(
                                    "lastLocation",
                                    targetPath.split("?")[0]
                                )
                                .then(() => {
                                    replace("/login?token=" + newToken);
                                    callback();
                                })
                                .catch((error) => {
                                    console.log(
                                        "Error: Unable to set last location",
                                        error
                                    );
                                });
                        })
                        .catch((error) => {
                            console.log(
                                "Error: Unable to dispatch logout",
                                error
                            );
                        });
                })
                .catch((error) => {
                    console.log("Error: Unable to remove tokenHash", error);
                });
        }
        //--------------------------------------------------------
        // If the user is logged in and there is no token in the url (Okay to Proceed)
        //--------------------------------------------------------
        else {
            callback(); // Proceed as usual
        }
    }

    alreadyLoggedIn(nextState, replace, callback) {
        if (Store.getState().auth.isUserLoggedIn)
            replace(getAppConfig().auth.routes.afterSignIn);
        callback();
    }

    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let returnValue = (
            <div
                style={{
                    ...getAppConfig().appStyle,
                    background: "#f5f5f5",
                    height: "100%",
                    position: "relative",
                }}
            >
                <Helmet>
                    <meta name="theme-color" content="#000000" />
                    <meta name="author" content="QuoDeck Tech" />
                    <meta
                        name="description"
                        content="Start your free game-based mobile learning management system in 10 minutes. QuoDeck helps businesses, startups, teachers and professors to train their audience in a fast, immersive and easy manner."
                    />
                    <meta
                        name="viewport"
                        content="user-scalable=0,
                   initial-scale=1.0,
                   maximum-scale=3.0,
                   minimum-scale=1.0,
                   width=device-width"
                    />
                    <link rel="manifest" href="manifest.json" />
                    <link rel="shortcut icon" href="favicon.ico" />
                    <title>
                        {getAppConfig().appName
                            ? getAppConfig().appName.app
                            : "QuoDeck Learning Center"}
                    </title>
                </Helmet>
                <Router history={syncHistoryWithStore(hashHistory, Store)}>
                    <Route component={AuthenticatedLayout}>
                        <Route
                            exact
                            path="/"
                            component={SessionContainer}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/home"
                            // component={isPortrait ? Home : Library}
                            component={isPortrait ? Home : getAppConfig().themedLXP ? ThemeContainer : Dashboard}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/dashboard"
                            component={Dashboard}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/library"
                            component={Library}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/articles/show/:id"
                            component={Article}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/articles/:id"
                            component={Article}
                            onEnter={this.requireLogin}
                        />
                        {/* Only for Flipkart */}
                        <Route
                            path="/LearningCenter/node/:id"
                            component={Article}
                            onEnter={this.requireLogin}
                        />
                        {/* ------------------- */}
                        <Route
                            path="/articles"
                            component={Blog}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/feed"
                            component={Feed}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/reports"
                            component={Reports}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/offline"
                            component={Offline}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/programs/:id"
                            component={isPortrait ? Program : Library}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/courses/:id(/:offline)"
                            component={Course}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/content/:inductor/:id(/:offline)"
                            component={Course}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/scorms/:id"
                            component={Course}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/courses/:id/leaderboard"
                            component={Leaderboard}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/leaderboard/:course_id"
                            component={Leaderboard}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/decks/:readerType/:id/:courseId(/:offline)"
                            component={Deck}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/notifications"
                            component={NotificationContainer}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/session(/:autoRoute)"
                            component={SessionContainer}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/editProfile"
                            component={EditProfile}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/help"
                            component={HelpDesk}
                        // onEnter={this.requireLogin}
                        />
                        <Route
                            path="/profile"
                            component={ProfileContainer}
                        />
                        <Route
                            path="/rewards/:awards"
                            component={Rewards}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/resetPassword"
                            component={ResetPassword}
                            onEnter={this.requireLogin}
                        />
                    </Route>
                    <Route component={UnauthenticatedLayout}>
                        <Route
                            path="/login"
                            component={AuthenticationContainer}
                            onEnter={this.alreadyLoggedIn}
                        />
                        <Route
                            path="/:id/login"
                            component={AuthenticationContainer}
                            onEnter={this.alreadyLoggedIn}
                        />
                        <Route
                            path="/admin"
                            component={AuthenticationContainer}
                            onEnter={this.alreadyLoggedIn}
                        />
                        <Route
                            path="/logout"
                            component={AuthenticationContainer}
                            onEnter={this.requireLogin}
                        />
                        <Route
                            path="/signup"
                            component={AuthenticationContainer}
                            onEnter={this.alreadyLoggedIn}
                        />
                        <Route
                            path="/forgotPassword"
                            component={AuthenticationContainer}
                            onEnter={this.alreadyLoggedIn}
                        />
                    </Route>

                    <Route
                        path="/forceUpdate"
                        component={ForceUpdateContainer}
                    />
                    <Route path="/terms" component={TermsContainer} />
                    <Route path="/loadQDF" component={QdfLoader} />
                    <Route path="*" exact={true} component={ErrorPage} />
                </Router>
            </div>
        );
        if (!this.state.rehydrated) {
            returnValue = <div />;
        }
        return returnValue;
    }
}

const AuthenticatedLayout = (props) => {
    let isPortrait = window.innerHeight > window.innerWidth;

    let noThemeStyle = {
        paddingBottom: "60px"
    };

    let themeStyle = {
        paddingBottom: "60px",
        backgroundImage: 'url(assets/images/theme/background.jpg)',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        padding: isPortrait ? "0" : "0 0.8%"
    };
    let passwordUpdate = getAppConfig().learningCenter.profileList.initialUpdatePassword &&
        !Store.getState().auth.user.initialUpdatePassword;
   
        if (isPortrait)
        return (
            passwordUpdate
                ? <ResetPassword {...props} />
                :
                <div style={getAppConfig().themedLXP ? themeStyle : noThemeStyle}>
                    {getAppConfig().inductor && Store.getState().auth.user && Store.getState().auth.user.courses.length === 1 ?
                        <InductorNavbar forceLogo={true} backBtn={true} />
                        :
                        <Navbar forceLogo={true} backBtn={true} />
                    }
                    {props.children}
                    {getAppConfig().inductor && Store.getState().auth.user && Store.getState().auth.user.courses.length === 1 && (
                        <Footer style={{ position: "absolute", bottom: "0" }} />
                    )}
                </div>
        );
    else
        return (
            <div>
                {passwordUpdate
                    ? <ResetPassword {...props} />
                    :
                    <div style={getAppConfig().themedLXP ? themeStyle : noThemeStyle}>
                        <DesktopNavbar
                            user={Store.getState().auth.user}
                            location={props.location}
                        />
                        {props.children}
                    </div>
                }
                {!isPortrait &&
                    getAppConfig().footer &&
                    getAppConfig().footer.present && (
                        <div
                            style={{
                                position: "fixed",
                                left: "0",
                                bottom: "0",
                                width: "100%",
                                height: "40px",
                                lineHeight: "40px",
                                background: getAppConfig().footer.bgColor,
                                color: getAppConfig().footer.textColor,
                                textAlign: "center",
                            }}
                        >
                            {getAppConfig().footer.text}
                        </div>
                    )}
            </div>
        );
};

const UnauthenticatedLayout = (props) => <div>{props.children}</div>;

export default App;
