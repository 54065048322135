import React from "react";
import PropTypes from "prop-types";
//import PdfReader from "globals/pdf_reader/PdfReader.react";
// import SlideHeader from "../../contentBlocks/SlideHeader.react";
import PdfScroller from "globals/pdf_reader/PdfTemplate.react";
export default class PdfViewer extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        mobileView: PropTypes.bool,
        handleCompletion: PropTypes.func,
    };

    handleCompletion = (currentPage, totalPages) => {
        //logic for individual pdf slide to be added
    };

    render() {
        let defaultStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            paddingTop: "0",
            position: "relative",
        };
        return (
            <div className="full-height">
                <div style={defaultStyle}>
                    <PdfScroller
                        url={typeof this.props.data.pdf === "object" ? this.props.data.pdf.id.toString() : this.props.data.pdf}
                        content_type="pdf_reader"
                        handleCompletion={this.handleCompletion}
                        deckId={this.props.deckId}
                    />
                </div>
            </div>
        );
    }
}
