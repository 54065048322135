// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import {
    Icon,
    Image,
    Label,
    Grid,
    Menu,
    Segment,
    Sidebar,
} from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";

// Import Wrappers
// -------------------------------------

// Import Components
// -------------------------------------
import Profile from "../components/individualAnalytics/Profile.react";
// import ActivityAnalytics from "../components/individualAnalytics/ActivityAnalytics.react";
import ProgressAnalytics from "../components/individualAnalytics/ProgressAnalytics.react";
import PerformanceAnalytics from "../components/individualAnalytics/PerformanceAnalytics.react";
import ActivityLog from "../components/individualAnalytics/ActivityLog.react";
import GamifixProfileAnalytics from "../components/individualAnalytics/GamifixProfile.react"
import GamifixPerformance from "../components/individualAnalytics/GamifixPerformance.react";
// Constants
// -------------------------------------
const Tabs = ["Profile", "Progress", "Performance"];
const MobileTabs = ["Profile", "Progress", "Performance", "Activity Log"];
if (getAppConfig().apiUrls.gamifixURL && getAppConfig().apiUrls.gamifixURL !== "") {
    MobileTabs.push("Gamifix Profile");
    MobileTabs.push("My Performance");
}


export default class IndividualAnalyticsSubWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: this.props.currentTab ? this.props.currentTab : 0,
            currentUser: this.props.user,
            visible: false
        };
        this.res = this.state.currentUser.first_name + " " + this.state.currentUser.last_name
    }
 
    componentDidMount() {
        this.props.actions.fetchUserDetails(this.props.user.id).then((user) =>
            this.setState({
                currentUser: _.assign({}, user, {
                }),
            })
        );
    }

    changeAnalyticTabs = (index) => this.setState({ currentTab: index, visible: false });


    reportsSidebar = () => {
        let basicTabStyle = {
            padding: "10px 12px",
            fontSize: "1.25em",
            cursor: "pointer",
        };
        let mainMenuStyle = {
            position: "relative",
            width: "100%",
            overflow: this.props.currentTab ? "scroll" : "",
            height: this.props.currentTab ? "100vh" : "",
            paddingBottom: this.props.currentTab ? "20%" : ""
        };
        let SidebarStyle = {
            width: "70%",
            top: "53px"
        }
        let tabberStyle = {
            tab: {
                ...basicTabStyle,
                background: "#cccccc",
                color: "#000000",
            },
            activeTab: {
                ...basicTabStyle,
                background: getAppConfig().colors.accents.primary,
                color: getAppConfig().colors.accents.primaryText,
            },
            teamTab: {
                ...basicTabStyle,
                background: "#999999",
                color: "#ffffff",
            },
            activityTab: {
                ...basicTabStyle,
                background: getAppConfig().colors.accents.secondary,
                color: getAppConfig().colors.accents.secondaryText,
            },
        };
        const setVisible = (visibleData) => {
            this.setState({ visible: visibleData })
        }
        let analyticsPanel = () => {
            switch (this.state.currentTab) {
                case 0:
                    return (
                        <Profile
                            {...this.props}
                            user={this.state.currentUser}
                        />
                    );
                // case 1:
                //     return <ActivityAnalytics {...this.props} />;
                case 1:
                    return <ProgressAnalytics {...this.props} />;
                case 2:
                    return <PerformanceAnalytics {...this.props} />;
                case 3:
                    return <ActivityLog {...this.props} />;
                case 4:
                    return <GamifixProfileAnalytics
                        {...this.props}
                        user={this.state.currentUser}
                    />;
                case 5:
                    return <GamifixPerformance
                        {...this.props}
                        user={this.state.currentUser}
                    />
                default:
                    return null;
            }
        };

        return (
            <Grid columns={1}>
                <Icon
                    style={{ position: "absolute", zIndex: "1", top: "9%", left: "5px", color: "#ffffff" }}
                    link
                    size="large"
                    name={"chevron down"}
                    onClick={() =>
                        setVisible(true)
                    }
                />
                <Grid.Column>
                    <Sidebar.Pushable as={Segment}>
                        {this.state.visible && <Sidebar
                            as={Menu}
                            animation='overlay'
                            icon='labeled'
                            inverted
                            onHide={() => setVisible(false)}
                            vertical
                            visible={this.state.visible}
                            width='thin'
                            style={SidebarStyle}
                        >
                            <Menu.Item as='a'>
                                {_.map(MobileTabs, (tab, index) => {
                                    return (
                                        <div
                                            key={"analytics-tab-" + index}
                                            style={
                                                index === this.state.currentTab
                                                    ? tabberStyle.activeTab
                                                    : tabberStyle.tab
                                            }
                                            onClick={() => this.changeAnalyticTabs(index)}
                                        >
                                            {tab}
                                        </div>
                                    );
                                })}
                            </Menu.Item>
                        </Sidebar>}

                        <Sidebar.Pusher dimmed={this.state.visible}>
                            <div style={mainMenuStyle}>
                                {analyticsPanel()}
                            </div>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Grid.Column >
            </Grid >
        )
    }
    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let dockIcon = {
            width: "100%",
            background: "#d5d5d5",
            padding: isPortrait
                ? "10px 5px"
                : getAppConfig().clientApp
                    ? "10px 15px"
                    : "20px 15px",
            fontSize: isPortrait ? "0.6em" : "0.9em",
            textAlign: "center",
            borderBottom: "1px solid #ffffff",
            cursor: "pointer",
            position: "relative"
        };
        let pageStyle = {
            container: {
                position: "relative",
                width: isPortrait ? "100%" : "800px",
                height: isPortrait ? "100vh" : "1100px",
                margin: isPortrait ? "0" : getAppConfig().themedLXP ? "0 auto" : "20px auto",
                background: "#f5f5f5",
                fontSize: "0.9em",
                boxShadow: getAppConfig().themedLXP ? "0" : "1px 1px 100px 1px #ccc",
            },
            tabber: {
                position: "absolute",
                left: "0",
                top: "10px",
                transformOrigin: "0px 0px",
                transform: "rotate(90deg)",
            },
            tabberFooter: {
                ...dockIcon,
                position: "absolute",
                left: "0",
                top: "",
                transformOrigin: "0px 0px",
                transform: "none",
                bottom: "0",
                zIndex: "10"
            },

            bannerSection: {
                width: "100%",
                height: "0",
                paddingBottom: "12.5%",
                top: isPortrait ? this.props.currentTab ? "0" : "7%" : "",
                position: "relative",
                zIndex: isPortrait ? "1" : ""
            },
        };
        let bannerStyle = {
            image: {
                width: "100%",
            },
            overlay: {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "0",
                paddingBottom: "12.5%",
                background:
                    "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%)",
            },
            profileImage: {
                position: "absolute",
                left: isPortrait ? "28px" : "40px",
                bottom: isPortrait ? "-18px" : "-90px",
                width: isPortrait ? "60px" : "160px",
                height: isPortrait ? "60px" : "160px",
                background: "#ffffff",
                boxShadow: "1px 1px 1px 1px #666666",
            },
            profileName: {
                position: "absolute",
                left: isPortrait ? "28px" : "40px",
                bottom: isPortrait ? "-10px" : "-92px",
                zIndex: "1",
                top: isPortrait ? "10px" : ""
            },
            userName: {
                padding: "5px",
                margin: 0,
            },
            pageTitle: {
                position: "absolute",
                right: "15%",
                top: isPortrait ? "1%" : "28%",
                transform: "translate(0,-60%)",
                color: "#ffffff",
                lineHeight: "0.9",
                textAlign: "right",
            },
            pageTitleMobile: {
                position: "absolute",
                top: isPortrait ? "1%" : "28%",
                transform: "translate(0,-60%)",
                color: "#ffffff",
                lineHeight: "0.9",
                left: "18%"
            },
            actionMenu: {
                position: "absolute",
                right: isPortrait ? "18px" : "40px",
                bottom: isPortrait ? "-54px" : "-80px",
                zIndex: "1",
            },
            logo: {
                width: "12.5%",
                height: "100%",
                padding: "15px",
                background: "#454545",
                position: "absolute",
                top: "0",
                right: isPortrait ? "none" : "0",
            },
            logoImage: {
                width: "70%",
                margin: "15%",
            },
        };
        let basicTabStyle = {
            marginRight: "5px",
            padding: "6px 12px",
            borderRadius: "10px 10px 0 0",
            transform: isPortrait ? "" : "rotate(180deg)",
            fontSize: "1.25em",
            display: "inline-block",
            cursor: "pointer",
            bottom: isPortrait ? "0" : "",
            left: isPortrait ? "0" : ""
        };
        let tabberStyle = {
            tab: {
                ...basicTabStyle,
                background: "#cccccc",
                color: "#000000",
            },
            activeTab: {
                ...basicTabStyle,
                background: getAppConfig().colors.accents.primary,
                color: getAppConfig().colors.accents.primaryText,
            },
            teamTab: {
                ...basicTabStyle,
                background: "#999999",
                color: "#ffffff",
            },
            activityTab: {
                ...basicTabStyle,
                background: getAppConfig().colors.accents.secondary,
                color: getAppConfig().colors.accents.secondaryText,
            },
        };
        let analyticsPanel = () => {
            switch (this.state.currentTab) {
                case 0:
                    return (
                        <Profile
                            {...this.props}
                            user={this.state.currentUser}
                        />
                    );
                // case 1:
                //     return <ActivityAnalytics {...this.props} />;
                case 1:
                    return <ProgressAnalytics {...this.props} />;
                case 2:
                    return <PerformanceAnalytics {...this.props} />;
                case 3:
                    return <ActivityLog {...this.props} />;
                case 4:
                    return <GamifixProfileAnalytics
                        {...this.props}
                        user={this.state.currentUser} />;
                case 5:
                    return <GamifixPerformance
                        {...this.props}
                        user={this.state.currentUser} />
                default:
                    return null;
            }
        };

        return (
            <div>
                <div style={pageStyle.container} className="reportPage">
                    <div style={pageStyle.tabber}>
                        {_.map(Tabs, (tab, index) => {
                            return (
                                <div
                                    key={"analytics-tab-" + index}
                                    style={
                                        index === this.state.currentTab
                                            ? tabberStyle.activeTab
                                            : tabberStyle.tab
                                    }
                                    onClick={() => this.changeAnalyticTabs(index)}
                                >
                                    {tab}
                                </div>
                            );
                        })}
                        <div
                            key={"analytics-tab-" + 98}
                            style={
                                3 === this.state.currentTab
                                    ? tabberStyle.activeTab
                                    : tabberStyle.activityTab
                            }
                            onClick={() => this.changeAnalyticTabs(3)}
                        >
                            Activity Log
                        </div>
                        {getAppConfig().apiUrls.gamifixURL && getAppConfig().apiUrls.gamifixURL !== "" && (
                            <div
                                key={"analytics-tab-" + 99}
                                style={
                                    4 === this.state.currentTab
                                        ? tabberStyle.activeTab
                                        : tabberStyle.tab
                                }
                                onClick={() => this.changeAnalyticTabs(4)}
                            >
                                Gamifix Profile
                            </div>
                        )}
                        {getAppConfig().apiUrls.gamifixURL && getAppConfig().apiUrls.gamifixURL !== "" && (
                            <div
                                key={"analytics-tab-" + 1000}
                                style={
                                    5 === this.state.currentTab
                                        ? tabberStyle.activeTab
                                        : tabberStyle.tab
                                }
                                onClick={() => this.changeAnalyticTabs(5)}
                            >
                                My Performance
                            </div>
                        )}
                    </div>
                    <div style={pageStyle.bannerSection}>
                        <Image
                            src={
                                "assets/images/reportBanners/" +
                                this.props.nameToHex(this.state.currentUser.username)[1] +
                                ".jpg"
                            }
                            style={bannerStyle.image}
                        />
                        <div style={bannerStyle.overlay} />
                        {!isPortrait && <div style={bannerStyle.profileImage}>
                        </div>}
                        {!isPortrait && (<div style={bannerStyle.profileName}>
                            {this.props.getAvatar(this.res, isPortrait ? 60 : 160)}
                            <div>
                                {this.state.currentUser.tags &&
                                    _.map(
                                        this.state.currentUser.tags.slice(0, 3),
                                        (tag, index) => {
                                            return (
                                                <Label
                                                    color="yellow"
                                                    key={"profile-tag-" + index}
                                                >
                                                    {tag}
                                                </Label>
                                            );
                                        }
                                    )}
                                {this.state.currentUser.tags &&
                                    this.state.currentUser.tags.length > 3 ? (
                                    <Label
                                        color="grey"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this.changeAnalyticTabs(0)}
                                    >
                                        ...
                                    </Label>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>)
                        }
                        {!isPortrait && this.state.currentTab < 6 && (
                            <h1 style={bannerStyle.pageTitle}>
                                LEARNER ANALYTICS
                                <br />
                                <small>{Tabs[this.state.currentTab]}</small>
                            </h1>
                        )}
                        {isPortrait && this.state.currentTab < 6 && (
                            <h2 style={bannerStyle.pageTitleMobile}>
                                {MobileTabs[this.state.currentTab]}
                            </h2>
                        )}
                        {this.state.currentTab === 7 && (
                            <h1 style={bannerStyle.pageTitle}>TEAM SUMMARY</h1>
                        )}
                        {!isPortrait && !this.props.syncing && (
                            <div style={bannerStyle.actionMenu}>
                                <Icon
                                    link
                                    circular
                                    inverted
                                    name={"print"}
                                    size={isPortrait ? "large" : "big"}
                                    onClick={() =>
                                        this.props.downloadReportPage(
                                            "learner_analytics_" +
                                            this.state.currentUser.username.replace(
                                                " ",
                                                "_"
                                            ) +
                                            "_" +
                                            Tabs[this.state.currentTab]
                                        )
                                    }
                                />
                            </div>
                        )}
                        <div style={bannerStyle.logo}>
                            {!isPortrait &&
                                (<Image
                                    src={getAppConfig().ui.iconlogo}
                                    style={bannerStyle.logoImage}
                                />)}
                        </div>
                    </div>
                    {isPortrait ?
                        <this.reportsSidebar />
                        :
                        analyticsPanel()
                    }
                </div>
            </div>
        );
    }
}