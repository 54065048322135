import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Section } from "config/initializers/styles.app";
import { Divider } from "semantic-ui-react";

class HTMLSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSlide: 0,
        };
    }
    static propTypes = {
        sliderItems: PropTypes.array,
        initialSlide: PropTypes.number,
        active: PropTypes.number,
        autoPlay: PropTypes.bool,
        arrows: PropTypes.bool,
        dots: PropTypes.bool,
        infinite: PropTypes.bool,
        asNavFor: PropTypes.string,
        enableButtons: PropTypes.bool,
        addSkipToEnd: PropTypes.bool,
        callback: PropTypes.func,
    };

    static defaultProps = {
        sliderItems: ["Slide 1", "Slide 2", "Slide 3"],
        initialSlide: 0,
        active: 0,
        autoPlay: true,
        infinite: false,
        dots: false,
        arrows: false,
        asNavFor: null,
        callback: null,
    };

    prev = () => {
        this.slider.slickPrev();
    };
    next = () => {
        this.slider.slickNext();
    };
    jumpToEnd = () => {
        this.slider.slickGoTo(this.props.sliderItems.length);
    };

    handleCallback = (index) => {
        if (this.props.callback !== undefined && this.props.callback !== null)
            this.props.callback(index);
        this.setState({ activeSlide: index });
    };

    handleClick = (index) => {
        if (this.props.changeImg) this.props.changeImg(index);
    };

    render() {
        let settings = {
            adaptiveHeight: true,
            speed: 500,
            initialSlide: this.props.initialSlide,
            slidesToShow: this.props.slidesToShow || 1,
            slidesToScroll: this.props.slidesToScroll || 1,
            centerMode: false,
            arrows: this.props.arrows,
            dots: this.props.dots,
            autoplay: this.props.autoPlay,
            asNavFor: this.props.asNavFor || null,
            className: "imageCarousel",
            infinite: this.props.infinite,
            swipe:
                !this.props.addSkipToEnd ||
                this.state.activeSlide !== this.props.sliderItems.length - 1,
            beforeChange: (current, next) => {
                this.handleCallback(next);
            },
        };
        let sliderItemStyle = {
            width: "100%",
            height: "100%",
        };
        let activeSliderItemStyle = {
            ...sliderItemStyle,
        };
        let carouselStyle = {
            width: "100%",
            height: "100%",
        };

        return (
            <div style={carouselStyle}>
                <Slider ref={(c) => (this.slider = c)} {...settings}>
                    {this.props.sliderItems.map((content, index) => {
                        return (
                            <div
                                key={"slider-" + index}
                                onClick={() => {
                                    this.handleClick(index);
                                }}
                                style={
                                    this.props.active === index
                                        ? activeSliderItemStyle
                                        : sliderItemStyle
                                }
                            >
                                {content}
                            </div>
                        );
                    })}
                </Slider>
                {this.props.enableButtons &&
                    this.state.activeSlide !==
                        this.props.sliderItems.length - 1 && (
                        <Section
                            centered
                            style={{
                                marginTop: this.props.dots ? "50px" : "0",
                            }}
                        >
                            <Divider />
                            <Button.Group fluid>
                                <Button
                                    icon="chevron left"
                                    labelPosition="left"
                                    onClick={this.prev}
                                />
                                <Button
                                    secondary
                                    content="Skip"
                                    onClick={this.jumpToEnd}
                                />
                                <Button
                                    icon="chevron right"
                                    labelPosition="right"
                                    onClick={this.next}
                                />
                            </Button.Group>
                        </Section>
                    )}
                {this.props.addSkipToEnd &&
                    this.state.activeSlide !==
                        this.props.sliderItems.length - 1 && (
                        <Section
                            centered
                            style={{
                                marginTop: this.props.dots ? "50px" : "0",
                            }}
                        >
                            <Divider />
                            <Button
                                fluid
                                secondary
                                content="Skip"
                                onClick={this.jumpToEnd}
                            />
                        </Section>
                    )}
            </div>
        );
    }
}

export default HTMLSlider;
