// Import from NPM
// -------------------------------------
import React from "react";
import { Icon } from "semantic-ui-react";
import { hashHistory } from "react-router";
import { connect } from "react-redux";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";

export class InductorNavbar extends React.Component {

    goBackOneLevel = /* istanbul ignore next - react-router function */ () => {
        if (this.props.backFn == null) hashHistory.goBack();
        else this.props.backFn();
    };
    handelLogout = () => {
        hashHistory.push("/logout")
    }

    render() {
        let showBackButton = this.props.routing.locationBeforeTransitions.pathname === `/content/${this.props.auth.inductor}/${this.props.auth.user.courses[0]}`
            || this.props.routing.locationBeforeTransitions.pathname === `/courses/${this.props.auth.user.courses[0]}`;

        let courseName = this.props.courses
            && this.props.courses[this.props.auth.user.courses[0]]
            ? this.props.courses[this.props.auth.user.courses[0]].name
            : "No Induction Course";

        let barStyle = {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "50px",
            background: this.props.transparent
                ? "transparent"
                : getAppConfig().colors.menuColor,
            zIndex: "1001",
            color: getAppConfig().colors.iconColor,
        };
        let backBtnStyle = {
            position: "absolute",
            left: "10px",
            top: "15px",
            height: "40px",
            fontSize: "1.5em",
            background: "transparent",
            color: getAppConfig().colors.iconColor,
            cursor: "pointer",
        };
        let headerStyle = {
            fontSize: "1.5em",
            color: getAppConfig().colors.iconColor,
            padding: "13px 0",
            lineHeight: "1.2"
        }
        if (this.props.auth.user && this.props.auth.isUserLoggedIn) {
            return (
                <div style={barStyle}>
                    {!showBackButton === true && (
                        <Icon
                            id="navbar-backBtn"
                            name="chevron left"
                            style={backBtnStyle}
                            onClick={this.goBackOneLevel}
                        />
                    )}
                    <div style={headerStyle}>
                        <span style={{ marginLeft: "40px" }}>
                            {text_truncate(courseName, 22)}
                        </span>
                        <Icon
                            name={"power off"}
                            style={{ float: "right", marginRight: "15px" }}
                            onClick={this.handelLogout}
                        />
                    </div>
                </div>
            );
        }
    }
}

const text_truncate = function (str, length, ending) {
    if (str == null) {
        str = "";
    }
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = "...";
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};
const mapStateToProps = (state) => {
    return {
        courses: state.courses,
        routing: state.routing,
        blog: state.blog,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InductorNavbar);
