import _ from "lodash";
import { getAppConfig } from "config/client.config";
export const CLEAR_TRACKER = "CLEAR_TRACKER";
export const PUSH_STATE = "PUSH_STATE";

const _clearTracker = /* istanbul ignore next - Action Creator */ (
    status,
    data,
    err
) => {
    return {
        type: CLEAR_TRACKER,
        data,
        err,
        status,
    };
};

const showAdmin = (id) => {
    return (dispatch, getState, Request) => {
        return Request.fetch(
            getAppConfig().endpoints.showAdminPath.replace(":id", id)
             )
            .then((response) => {            
                return response.json().then((data) => {
                    return data;
                });
                           
             })
            .catch((err) => {
                console.log(err);
                return null;
            });
    };
};

function addLogsData(data) {
    return (dispatch, getState, Request) => {
        return Request.fetch(getAppConfig().endpoints.sendLogsPath,{
                method: "POST",
                body: JSON.stringify({
                   message: data
                }),
            })
            .then((response) => {
                return response.json();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
}

const pushTrackerToServer = /* istanbul ignore next - Request and Pure passthrough */ () => {
    return (dispatch, getState, Request) => {
        const allDirtyTrackers = getState().trackers;
        if (!_.isEmpty(allDirtyTrackers.queue)) {
            return Request.fetch(getAppConfig().endpoints.sendTrackerPath, {
                method: "POST",
                body: JSON.stringify({
                    trackers: allDirtyTrackers,
                }),
            })
                .then(() => {
                    return Promise.resolve(
                        dispatch(
                            _clearTracker(
                                "clearing",
                                {
                                    lastPushed: Date(),
                                },
                                null
                            )
                        )
                    );
                })
                    .then(() => {
                        return Request.fetch("/api/v2/analytics/reports/stream/update_course_state", {
                            method: "POST",
                            body: JSON.stringify({
                                trackers: allDirtyTrackers,
                            }),
                        });
                    })
                    .then(() => {
                        return Promise.resolve(
                            dispatch(
                                _clearTracker(
                                    "clearing",
                                    {
                                        lastPushed: Date(),
                                    },
                                    null
                                )
                            )
                        );
                    })
                    .catch((err) => {
                        return Promise.resolve(
                            dispatch(_clearTracker("error", null, err))
                        );
                    });
            } else {
                return Promise.resolve(123);
            }
        };
    };

class TrackerAction {
    static pushTrackerToServer = pushTrackerToServer;
    static addLogsData = addLogsData;
    static showAdmin = showAdmin
}

export default TrackerAction;
