import React, { PureComponent } from "react";
import _ from "lodash";
import { Segment, Statistic, Grid, Message } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";

import QuoRadialProgressChart from "../charts/QuoRadialProgressChart.react";
import QuoBarChart from "../charts/QuoBarChart.react";

export default class CoursePerformanceSubPanel extends PureComponent {
    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let pageStyle = {
            noCourseSection: {
                margin: "60px auto",
                width: isPortrait ? "100%" : "60%",
                textAlign: "center"
            },
            statusSection: {
                base: {
                    padding: isPortrait ? "10px 0" : "15px 15px 0 15px"
                },
                grid: {
                    margin: "30px 0",
                    color: "#000000"
                },
                statuslabel: { color: "#666666" },
                status: {
                    fontSize: isPortrait ? "1em" : "1.25em",
                    color: "#000000",
                    fontWeight: "bolder"
                }
            },
            completionStyle: {
                background: "grey",
                padding: "10px"
            },
            highlightsSection: {
                padding: "0"
            },
            accessSection: {
                base: {
                    width: isPortrait ? "100%" : "760px",
                    textAlign: "center",
                    padding: "0 15px",
                    margin: "0"
                },
                message: {
                    width: isPortrait ? "100%" : "660px",
                    fontWeight: "bold",
                    marginTop: isPortrait ? "20px" : ""
                }
            },
            summarySection: {
                base: {
                    width: isPortrait ? "100%" : "660px",
                    textAlign: "center",
                    padding: "0",
                    margin: "0"
                },
                radial: {
                    width: isPortrait ? "130px" : "150px",
                    display: "inline-block",
                    padding: "0"
                },
                h3Header: {
                    margin: "0",
                    textAlign: "center"
                },
                details: {
                    width: isPortrait ? "100%" : "500px",
                    display: "inline-block",
                    padding: "0 0 0 15px",
                    textAlign: "left",
                    fontWeight: "bold"
                },
                detailHeader: {
                    width: "128px",
                    display: "inline-block"
                }
            }
        };
        if (this.props.data === null || this.props.data === undefined) {
            return (
                <div style={pageStyle.noCourseSection}>
                    {isPortrait ?
                        <h3>Select a course to browse the performance data</h3>
                        :
                        <h1>Select a course to browse the performance data</h1>
                    }
                </div>
            );
        } else {
            let hours = Math.floor(this.props.data.time_spent / 3600);
            let minutes = Math.floor(this.props.data.time_spent / 60) % 60;
            let seconds = this.props.data.time_spent % 60;
            let result = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
            return (
                <div>
                    <Segment basic style={pageStyle.statusSection.base}>
                        <h2>{_.toUpper(this.props.data.name)}</h2>
                        {isPortrait ?
                            <div>
                                <div style={{ background: "olive", padding: "10px" }}>
                                    <h3>
                                        <span
                                            style={
                                                pageStyle.statusSection
                                                    .statuslabel
                                            }
                                        >
                                            Status:
                                        </span>
                                        <br />
                                        <span
                                            style={
                                                pageStyle.statusSection.status
                                            }
                                        >
                                            COMPLETE
                                        </span>
                                    </h3>
                                </div>
                                <br />
                                <div style={pageStyle.completionStyle}>
                                    <h3>
                                        {this.props.data.sequential
                                            ? "Sequentially accessed course content"
                                            : "Freely accessed course content"}
                                    </h3>
                                </div>
                                <br />
                                <div style={pageStyle.completionStyle}>
                                    <h3>
                                        {this.props.data.has_final
                                            ? "Final assessment based completion"
                                            : "Coverage based completion"}
                                    </h3>
                                </div>
                            </div>
                            :
                            <Grid
                                columns={3}
                                celled
                                style={pageStyle.statusSection.grid}
                            >
                                <Grid.Row>
                                    <Grid.Column color={"olive"}>
                                        <h3>
                                            <span
                                                style={
                                                    pageStyle.statusSection
                                                        .statuslabel
                                                }
                                            >
                                                Status:
                                            </span>
                                            <br />
                                            <span
                                                style={
                                                    pageStyle.statusSection.status
                                                }
                                            >
                                                COMPLETE
                                            </span>
                                        </h3>
                                    </Grid.Column>
                                    <Grid.Column color="grey">
                                        <h3>
                                            {this.props.data.sequential
                                                ? "Sequentially accessed course content"
                                                : "Freely accessed course content"}
                                        </h3>
                                    </Grid.Column>
                                    <Grid.Column color="grey">
                                        <h3>
                                            {this.props.data.has_final
                                                ? "Final assessment based completion"
                                                : "Coverage based completion"}
                                        </h3>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }

                    </Segment>
                    <Segment basic style={pageStyle.summarySection.base}>
                        <Segment basic style={pageStyle.summarySection.radial}>
                            {isPortrait ?
                                <h4 style={pageStyle.summarySection.h3Header}>
                                    Final Score
                                </h4>
                                :
                                <h3 style={pageStyle.summarySection.h3Header}>
                                    Final Score
                                </h3>
                            }
                            <QuoRadialProgressChart
                                width={isPortrait ? 130 : 150}
                                height={isPortrait ? 110 : 130}
                                color={getAppConfig().colors.accents.primary}
                                value={this.props.data.final_score}
                                top={55}
                                left={67}
                            />
                        </Segment>
                        <Segment basic style={pageStyle.summarySection.details}>
                            <h3 style={pageStyle.summarySection.h3Header}>
                                Assessment Scores
                            </h3>
                            <QuoBarChart
                                width={isPortrait ? 300 : 500}
                                height={130}
                                data={this.props.data.assessment_scores}
                                series={[
                                    {
                                        dataKey: "score",
                                        fill: getAppConfig().colors.accents
                                            .primary
                                    }
                                ]}
                                skipXlabels={true}
                            />
                        </Segment>
                    </Segment>
                    <Segment basic style={pageStyle.accessSection.base}>
                        <Message
                            positive
                            style={pageStyle.accessSection.message}
                        >
                            Course completed on{" "}
                            {new Date(
                                this.props.data.completed_at
                            ).toLocaleString("en-IN")}{" "}
                        </Message>
                    </Segment>
                    <Segment basic style={pageStyle.highlightsSection}>
                        <Statistic.Group widths="three" size={isPortrait ? "tiny" : {}}>
                            <Statistic>
                                <Statistic.Value>
                                    {this.props.data.view_attempts}
                                </Statistic.Value>
                                <Statistic.Label>Views</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value
                                    text
                                    style={{ width: isPortrait ? "" : "180px", margin: "0 auto", paddingTop: "5%" }}
                                >
                                    {result}
                                </Statistic.Value>
                                <Statistic.Label>Time Spent</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {this.props.data.points}
                                </Statistic.Value>
                                <Statistic.Label>
                                    {isPortrait ? "Points" : "Points Collected"}
                                </Statistic.Label>
                            </Statistic>
                        </Statistic.Group>
                    </Segment>
                </div>
            );
        }
    }
}
