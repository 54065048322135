import React from "react";
import PropTypes from "prop-types";
import { Button } from "config/initializers/styles.app";
import { VelocityTransitionGroup } from "velocity-react";

export default class ChoiceGroup extends React.Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        extractFrom: PropTypes.string.isRequired,
        colors: PropTypes.object,
        animation: PropTypes.string,
        slideShow: PropTypes.bool,
    };

    static defaultProps = {
        colors: {
            base: "#ffffff",
            primary: "#cb1f37",
            primaryVariation: "#a41817",
            secondary: "#ffaa00",
            secondaryVariation: "#4db6ac",
            baseText: "#121212",
            primaryText: "#ffffff",
            secondaryText: "#000000",
        },
        animation: "fadeIn, 500",
        slideShow: false,
    };

    handleClick = (index) => {
        if (this.props.callback) this.props.callback(index);
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;

        let buttonStyle = {
            marginBottom: "5px",
        };
        let animation = this.props.animation.split(",");
        let getOptions = (
            <div>
                <Button
                    size={isPortrait ? "medium" : "huge"}
                    style={buttonStyle}
                    fluid
                    onClick={() => this.handleClick(0)}
                >
                    {"" + this.props.options[0][this.props.extractFrom]}
                </Button>
                <Button.Or style={{ margin: "0 auto" }} />
                <Button
                    size={isPortrait ? "medium" : "huge"}
                    style={buttonStyle}
                    fluid
                    onClick={() => this.handleClick(1)}
                >
                    {"" + this.props.options[1][this.props.extractFrom]}
                </Button>
            </div>
        );

        return (
            <Button.Group vertical>
                <VelocityTransitionGroup
                    enter={{
                        animation: animation[0],
                        duration: animation[1],
                        stagger: animation[2],
                    }}
                    leave={{ animation: "fadeOut", duration: "0" }}
                    runOnMount
                >
                    {getOptions}
                </VelocityTransitionGroup>
            </Button.Group>
        );
    }
}
