import React, { PureComponent } from "react";
import { Segment, Dropdown, Grid, Statistic, Icon } from "semantic-ui-react";
// import QuoRadialProgressChart from "../charts/QuoRadialProgressChart.react";
import CourseProgressSubPanel from "./CourseProgressSubPanel.react";
import { Loading } from "../../../appComponents/loading/Loading.react.jsx";
import ErrorPage from "common/globals/error/ErrorPage.react.jsx";
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";

export default class ProgressAnalytics extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fetchCompleted: false,
            error: null,
            progressData: null,
            courseProgress: null,
            courseList: []
        };
    }
    componentDidMount() {
        this.props.actions
            .fetchLearnerProgress(this.props.user.id)
            .then(data => {
                this.props.actions
                    .fetchLearnerCourses(this.props.user.id)
                    .then(courseList => {
                        this.setState({
                            fetchCompleted: true,
                            progressData: data,
                            courseList: courseList
                        });
                    })
                    .catch(
                        /* istanbul ignore next - tested in the render */ e => {
                            this.setState({
                                fetchCompleted: true,
                                error: true
                            });
                        }
                    );
            });
    }
    updateCourseProgress = (e, { value }) => {
        this.props.actions
            .fetchLearnerCourseProgress(this.props.user.id, value)
            .then(data => {
                this.setState({
                    courseProgress: data
                });
            })
            .catch(
                /* istanbul ignore next - tested in the render */ e => {
                    this.setState({
                        fetchCompleted: true,
                        error: true
                    });
                }
            );
    };
    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let pageStyle = {
            courseSection: {
                marginLeft: isPortrait ? "0px" : "25px",
                marginTop: "-25px",
                width: isPortrait ? "100%" : "720px",
                height: isPortrait ? "auto" : "625px",
            },
            baseContainer: {
                height: "100vh",
                overflow: "hidden scroll",
                marginTop: "50px"
            },
            aggregate: {
                base: {
                    width: isPortrait ? "100%" : "725px",
                    marginTop: isPortrait ? "0" : "60px",
                    textAlign: "center",
                    padding: "0",
                },
                radialHeader: {
                    margin: "0"
                },
                radial: {
                    width: isPortrait ? "120px" : "230px",
                    display: "inline-block"
                }
            }
        };

        let data01 = [
            { name: 'Completed', value: this.state.progressData && this.state.progressData.completed_courses ? this.state.progressData.completed_courses : 0 },
            { name: 'Started', value: this.state.progressData && this.state.progressData.started_courses ? this.state.progressData.started_courses : 0 },
            { name: 'Not Started', value: this.state.progressData && this.state.progressData.notstarted_courses ? this.state.progressData.notstarted_courses : 0 }
        ];

        let colors = [
            getAppConfig().colors.accents.primary,
            getAppConfig().colors.accents.secondary,
            "#d5d5d5"
        ];

        let previewColor = {
            width: "30px",
            height: "15px",
            display: "inline-block",
            verticalAlign: "middle",
            margin: "5px",
            border: "1px solid #ccc"
        };

        let analyticsCard = {
            preview1: {
                ...previewColor,
                background: colors[0]
            },
            preview2: {
                ...previewColor,
                background: colors[1]
            },
            preview3: {
                ...previewColor,
                background: colors[2]
            },
            pieHelper: {
                fontSize: "10px",
                position: isPortrait ? "relative" : "absolute",
                textAlign: isPortrait ? "center" : "left",
                bottom: isPortrait ? "none" : "123px",
                left: isPortrait ? "none" : "70%"
            }
        }

        let defaultCourseListOpt = [{ text: "Select a course to get progress details" }];

        let renderLabelContent = (props) => {
            const { percent, x, y, midAngle } = props;
            if (percent !== 0)
                return (
                    <g transform={`translate(${x}, ${y})`} textAnchor={(midAngle < -90 || midAngle >= 90) ? 'end' : 'start'}>
                        <text x={0} y={20}>{`${(percent * 100).toFixed(0)}%`}</text>
                    </g>
                );
        };

        if (!this.state.fetchCompleted) return <Loading />;
        else if (this.state.fetchCompleted && !this.state.error) {
            let showPie = this.state.progressData &&
                this.state.progressData.enrolled_courses &&
                parseInt(this.state.progressData.enrolled_courses, 10) !== 0 ? true : false;
            return (
                <Segment basic style={isPortrait ? pageStyle.baseContainer : {}}>
                    <Segment basic style={pageStyle.aggregate.base}>
                        {!isPortrait && (
                            <Grid>
                                <Grid.Row column={showPie ? 2 : 1}>
                                    <Grid.Column width={showPie ? 7 : 16}>
                                        <Statistic size="huge" style={{ margin: showPie ? "18% 0px 0% 20%" : "0 0 0 10%", fontSize: "1.75em" }}>
                                            <Statistic.Value>
                                                <Icon name='clipboard' />{this.state.progressData.enrolled_courses}
                                            </Statistic.Value>
                                            <Statistic.Label>Enrolled Courses</Statistic.Label>
                                        </Statistic>
                                    </Grid.Column>
                                    {showPie && (
                                        <Grid.Column width={9}>
                                            <div style={analyticsCard.pieHelper}>
                                                <b> <div style={analyticsCard.preview1} />Completed </b>
                                                <br /> <b> <div style={analyticsCard.preview2} />Started </b>
                                                <br /><b> <div style={analyticsCard.preview3} />NotStarted </b>
                                            </div>
                                            <PieChart width={500} height={270} style={{
                                                bottom: "26px",
                                                right: "104px"
                                            }}>

                                                <Pie
                                                    dataKey="value"
                                                    isAnimationActive={true}
                                                    data={data01}
                                                    cx="50%"
                                                    cy="50%"
                                                    innerRadius={30}
                                                    outerRadius={80}
                                                    label={renderLabelContent}
                                                >
                                                    {
                                                        data01.map((entry, index) => (
                                                            <Cell key={`slice-${index}`} fill={colors[index % 10]} />
                                                        ))
                                                    }
                                                </Pie>
                                                <Tooltip />
                                            </PieChart>
                                        </Grid.Column>
                                    )}
                                </Grid.Row>
                            </Grid>
                        )}
                        {isPortrait && (
                            <Grid>
                                <Grid.Column>
                                    <Grid.Row>
                                        <Statistic size="huge" style={{ margin: "auto", fontSize: "1.25em" }}>
                                            <Statistic.Value>
                                                <Icon name='clipboard' />{this.state.progressData.enrolled_courses}
                                            </Statistic.Value>
                                            <Statistic.Label>Enrolled Courses</Statistic.Label>
                                        </Statistic>
                                    </Grid.Row>
                                    {showPie && (
                                        <Grid.Row>
                                            <PieChart width={355} height={270} style={{
                                                right: "6%"
                                            }}>
                                                <Pie
                                                    dataKey="value"
                                                    isAnimationActive={true}
                                                    data={data01}
                                                    cx="50%"
                                                    cy="50%"
                                                    innerRadius={30}
                                                    outerRadius={80}
                                                    label={renderLabelContent}
                                                >
                                                    {
                                                        data01.map((entry, index) => (
                                                            <Cell key={`slice-${index}`} fill={colors[index % 10]} />
                                                        ))
                                                    }
                                                </Pie>
                                                <Tooltip />
                                            </PieChart>
                                            <div style={analyticsCard.pieHelper}>
                                                <b> <div style={analyticsCard.preview1} />Completed</b>
                                                <b> <div style={analyticsCard.preview2} />Started </b>
                                                <b> <div style={analyticsCard.preview3} />Not Started </b>
                                            </div>
                                        </Grid.Row>
                                    )}
                                </Grid.Column>
                            </Grid>
                        )}
                    </Segment>
                    <Segment raised style={pageStyle.courseSection}>
                        <Dropdown
                            placeholder="Select a course to get progress details"
                            search
                            fluid
                            selection
                            options={[defaultCourseListOpt[0], ...this.state.courseList]}
                            onChange={this.updateCourseProgress}
                        />
                        <CourseProgressSubPanel
                            data={this.state.courseProgress}
                        />
                    </Segment>
                </Segment>
            );
        } else {
            return <ErrorPage msg="Sorry, could not fetch the content" />;
        }
    }
}
