import React from "react";
import { Grid, Segment, Message } from "semantic-ui-react";
import Text from "../../contentBlocks/Text.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import UploadField from "../../../globals/uploadField/FileBase64.react";
import { Button } from "config/initializers/styles.app";

const allowedFormats = ["image/png",
    "image/jpeg",
    "text/csv",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel"
];

export default class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thisFile: null,
            validated: false,
            error: false
        };
    }

    handleSubmit = (file) => {
        if (this.validateFile(file)) {
            this.setState({
                error: false,
                validated: true,
                thisFile: file
            });
        } else {
            this.setState({ error: true });
        }
    };

    validateFile = (file) => {
        let type = file.file.type;
        let size = file.file.size;
        if (allowedFormats.includes(type) && size <= 100000) {
            return true;
        }
        return false;
    }

    handleClick = () => {
        this.props.trackInteraction(this.state.thisFile.base64);
    };

    handleDismiss = () => {
        this.setState({ error: false })
    }

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;

        let pageWrapperStyle = { minHeight: "100%" },
            leftSectionStyle = {
                paddingRight: "0",
                backgroundColor: this.props.colors.primary,
            },
            rightsSectionStyle = {
                margin: "auto 0%",
                textAlign: "center"
            },
            notesStyle = { color: "red", margin: "10%" },
            uploadAreaStyle = { margin: "2% auto", width: "80%" },
            buttonStyle = { margin: "10px auto" };

        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;

        return (
            <div
                key={`uploader-${this.props.slideId}`}
                className="full-height">

                {isPortrait && (
                    <div style={{ textAlign: "center" }} >
                        <SlideHeader
                            {...this.props}
                            title={title}
                            subtitle={subtitle}
                        />
                        <div>
                            {this.state.error && (
                                <Message
                                    onDismiss={this.handleDismiss}
                                    error
                                    compact
                                    header="Oops!"
                                    content={"Invalid file! Please check type and size of file!"}
                                />
                            )}
                            <br />
                            <Text
                                keyName={
                                    "question-" + this.props.slideId
                                }
                                text={this.props.data.question}
                                color={this.props.colors.baseText}
                                fontSize="large"
                                animation="transition.expandIn,500,500"
                            />
                            <div>
                                <Segment
                                    style={uploadAreaStyle}
                                >
                                    <UploadField
                                        name="content"
                                        onDone={this.handleSubmit}
                                        accept={allowedFormats.join(",")}
                                    />
                                </Segment>
                                <Button
                                    style={buttonStyle}
                                    size={"medium"}
                                    onClick={this.handleClick}
                                    disabled={!this.state.validated}
                                >
                                    Submit
                                </Button>
                            </div>
                            <p style={notesStyle}>
                                <b>Notes</b>: 1. You can upload up to 100kb file.<br />
                                2. Below files are allowed- Png/Jpeg images, Pdf files, Excel/Csv files.
                            </p>
                        </div>
                    </div>
                )}

                {!isPortrait && (
                    <Grid style={pageWrapperStyle}>
                        <Grid.Row>
                            <Grid.Column
                                width={8}
                                style={leftSectionStyle}
                            >
                                <div className="full-width">
                                    <SlideHeaderSidebar
                                        {...this.props}
                                        title={title}
                                        subtitle={subtitle}
                                    />
                                </div>
                            </Grid.Column>
                            <Grid.Column width={8} style={rightsSectionStyle}>
                                <div >
                                    {this.state.error && (
                                        <Message
                                            onDismiss={this.handleDismiss}
                                            error
                                            compact
                                            header="Oops!"
                                            content={"Invalid file! Please check type and size of file!"}
                                        />

                                    )}
                                    <br />
                                    <Text
                                        keyName={
                                            "question-" + this.props.slideId
                                        }
                                        text={this.props.data.question}
                                        color={this.props.colors.baseText}
                                        fontSize="large"
                                        animation="transition.expandIn,500,500"
                                    />
                                    <div>
                                        <Segment style={uploadAreaStyle}>
                                            <UploadField
                                                name="content"
                                                onDone={this.handleSubmit}
                                                accept={allowedFormats.join(",")}
                                            />
                                        </Segment>

                                        <Button
                                            style={buttonStyle}
                                            size={"big"}
                                            onClick={this.handleClick}
                                            disabled={!this.state.validated}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                    <p style={notesStyle}>
                                        <b>Notes</b>: 1. You can upload up to 100kb file.<br />
                                        2. Below files are allowed- Png/Jpeg images, Pdf files, Excel/Csv files.
                                    </p>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
