import React from "react";
import PropTypes from "prop-types";
import ImageGroup from "../../contentBlocks/ImageGroup.react";
import Caption from "../../contentBlocks/Caption.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";
import { ComponentInputs } from "schema/FormSchema.react";
import _ from "lodash";
export default class Triptych extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            caption: PropTypes.string,
            triptych: PropTypes.array,
        }).isRequired,
        slideId: PropTypes.number,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        let triptych =
            this.props.data.images !== undefined
                ? this.props.data.images
                : this.props.data.triptych;
        let defaultStyle = {
            margin: "-20px auto",
            width: "100%",
            textAlign: "center",
            height: "auto",
            position: "relative",
        };
        let hasPresenter =
            this.props.data.presenter !== undefined &&
            this.props.data.presenter.id !== "default43";
        let speechBubbleStyle = {
            position: "absolute",
            width: "100%",
            top: "-15px",
            left: "0",
            zIndex: "0",
            transform:
                hasPresenter &&
                    (this.props.data.layout === "side by side full" || title === "")
                    ? "scaleY(-1)"
                    : "",
        };
        let captionStyle = {
            position: hasPresenter & isPortrait ? "absolute" : "relative",
            top: hasPresenter ? "75%" : "inherit",
            width: isPortrait
                ? hasPresenter
                    ? "60%"
                    : "90%"
                : hasPresenter
                    ? "40%"
                    : "90%",
            margin: isPortrait
                ? hasPresenter
                    ? "0"
                    : "0 auto"
                : hasPresenter
                    ? "20px 0"
                    : "20px auto",
            textAlign: !isPortrait && hasPresenter ? "center" : "left",
            fontSize: isPortrait
                ? hasPresenter
                    ? "0.65em"
                    : "0.75em"
                : hasPresenter
                    ? "0.65em"
                    : "1.0em",
            padding: hasPresenter ? "0 40px 0 15px" : "0",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            height: "0",
            paddingBottom: "40%",
            position: "relative",
            float: "right",
        };

        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={title}
                            subtitle={subtitle}
                            largePresenter={title === ""}
                        />
                        <div style={defaultStyle}>
                            <ImageGroup
                                {...this.props}
                                keyName={"image-group-" + this.props.slideId}
                                size="medium"
                                images={_.map(triptych, "image")}
                                animation="transition.bounceRightIn,500,500"
                                thinLayout={
                                    this.props.data.layout ===
                                    "side by side full" ||
                                    (hasPresenter &&
                                        this.props.data.caption &&
                                        this.props.data.caption.length >
                                        ComponentInputs.caption.idealLength)
                                }
                                callback={this.props.callback}
                            />
                            <div style={captionStyle}>
                                {hasPresenter &&
                                    this.props.data.caption &&
                                    this.props.data.caption.length <=
                                    ComponentInputs.caption.idealLength && (
                                        <img
                                            src="assets/images/speech_bubble2.png"
                                            alt="Speech bubble"
                                            style={speechBubbleStyle}
                                        />
                                    )}
                                <Caption
                                    keyName="caption"
                                    text={this.props.data.caption}
                                    colors={this.props.colors}
                                    animation="transition.bounceUpIn,400,750"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <ImageGroup
                                        {...this.props}
                                        keyName={
                                            "image-group-" + this.props.slideId
                                        }
                                        size="medium"
                                        images={_.map(triptych, "image")}
                                        animation="transition.bounceRightIn,500,500"
                                        thinLayout={
                                            this.props.data.layout ===
                                            "side by side full"
                                        }
                                        callback={this.props.callback}
                                    />
                                    <div style={captionStyle}>
                                        <Caption
                                            keyName="caption"
                                            text={this.props.data.caption}
                                            colors={this.props.colors}
                                            animation="transition.bounceUpIn,400,750"
                                        />
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
