import React from "react";
import Text from "../../contentBlocks/Text.react";
import IconCarousel from "globals/carousel/IconCarousel.react";
import Flexbox from "globals/flexbox/FlexBox.react";
import { resolveImage } from "config/app.helpers";
import _ from "lodash";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";

export default class IconListCaptions extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            iconlist: PropTypes.array,
            options: PropTypes.array,
            question: PropTypes.string,
        }).isRequired,
        colors: PropTypes.shape({
            baseText: PropTypes.string,
            primary: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        slideShow: PropTypes.number,
    };
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    }

    handleClick = (index) => {
        this.setState({ index: index });
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: "100%",
            height: "100%",
            textAlign: "center",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            height: "0",
            paddingBottom: "40%",
            position: "relative",
            float: "right",
        };
        let captionStyle = {
            position: "relative",
            width: "90%",
            margin: isPortrait ? "30px auto" : "80px auto",
            textAlign: "center",
        };
        let separatorStyle = {
            height: "32px",
            background: "#ffc900",
            width: "100%",
        };
        let cardCarouselStyle = {
            background: "transparent",
            margin: isPortrait
                ? "-" +
                  (48 - (this.props.data.cards.length - 4) * 3) +
                  "px auto"
                : "-65px auto",
            width: Math.min(100, this.props.data.cards.length * 25) + "%",
        };
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        let carouselData = _.map(this.props.data.cards, (elm) => {
            return resolveImage(elm.image.id, this.props.imageLibrary);
        });
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={title}
                            subtitle={subtitle}
                        />
                        <div style={defaultStyle}>
                            <Flexbox height="250px">
                                <div style={captionStyle}>
                                    <Text
                                        fontSize="large"
                                        text={
                                            this.props.data.cards[
                                                this.state.index
                                            ].text
                                        }
                                        colors={this.props.colors}
                                        animation="fadeIn,100"
                                    />
                                </div>
                            </Flexbox>
                            <div style={separatorStyle} />
                            <div style={cardCarouselStyle}>
                                <IconCarousel
                                    key={"slider-" + Math.random()}
                                    carouselItems={carouselData}
                                    autoPlay={false}
                                    arrows={false}
                                    slidesToShow={carouselData.length}
                                    slidesToScroll={1}
                                    changeImg={this.handleClick}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <Flexbox height="250px">
                                        <div style={captionStyle}>
                                            <Text
                                                fontSize="large"
                                                text={
                                                    this.props.data.cards[
                                                        this.state.index
                                                    ].text
                                                }
                                                colors={this.props.colors}
                                                animation="fadeIn,100"
                                            />
                                        </div>
                                    </Flexbox>
                                    <br />
                                    <br />
                                    <div style={separatorStyle} />
                                    <div style={cardCarouselStyle}>
                                        <IconCarousel
                                            key={"slider-" + Math.random()}
                                            carouselItems={carouselData}
                                            autoPlay={false}
                                            arrows={false}
                                            slidesToShow={carouselData.length}
                                            slidesToScroll={1}
                                            changeImg={this.handleClick}
                                        />
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
