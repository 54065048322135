import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { VelocityTransitionGroup } from "velocity-react";

class DividerIcon extends Component {
    static propTypes = {
        colors: PropTypes.object,
        icon: PropTypes.string,
        animation: PropTypes.string,
        keyNames: PropTypes.array.isRequired
    };

    static defaultProps = {
        icon: "",
        color: "#000",
        animation: "fadeIn, 1000",
        colors: {
            base: "white",
            primary: "#ffc107",
            secondary: "teal",
            primaryVariation: "#ffa000",
            baseText: "#212121"
        }
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let boxStyle = {
            height: isPortrait ? "1.5em" : "2em",
            width: "100%",
            float: "none",
            position: "relative"
        };
        let dividerStyle = {
            height: isPortrait ? "1.5em" : "2em",
            width: "100%",
            backgroundColor: this.props.colors.primaryVariation,
            position: "relative",
            zIndex: "988"
        };
        let logoContainerStyle = {
            width: isPortrait ? "100%": "",
            position: "absolute",
            top: isPortrait ? "-1.25em" : "-3em",
            left: isPortrait ? "0" : "80%",
            zIndex: "999",
            visibility: this.props.icon === "" ? "hidden" : "visible"
        };
        let logoStyle = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: isPortrait ? "1.8em" : "2.5em",
            lineHeight: 1,
            height: isPortrait ? "2.2em" : "3em",
            width: isPortrait ? "2.2em" : "3em",
            borderRadius: "50%",
            backgroundColor: this.props.colors.primaryVariation,
            color: this.props.colors.primaryText,
            margin: "0 auto"
        };
        let animation = this.props.animation.split(",");

        return (
            <div className="full-height" style={boxStyle}>
                <VelocityTransitionGroup
                    enter={{
                        animation: animation[0],
                        duration: animation[1],
                        delay: animation[2]
                    }}
                    leave={{ animation: "fadeOut", duration: "0" }}
                    runOnMount
                >
                    <div key={this.props.keyNames[0]} style={dividerStyle} />
                </VelocityTransitionGroup>
                <VelocityTransitionGroup
                    enter={{ animation: animation[2], duration: animation[3] }}
                    leave={{ animation: "fadeOut", duration: "0" }}
                    runOnMount
                >
                    <div
                        key={this.props.keyNames[1]}
                        style={logoContainerStyle}
                    >
                        <div style={logoStyle}>
                            <Icon
                                className={this.props.icon}
                                style={{ margin: 0 }}
                            />
                        </div>
                    </div>
                </VelocityTransitionGroup>
            </div>
        );
    }
}

export default DividerIcon;
