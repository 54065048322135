import React from "react";
import PropTypes from "prop-types";
import Caption from "../../contentBlocks/Caption.react";
import ImageGroup from "../../contentBlocks/ImageGroup.react";
import IconCarousel from "globals/carousel/IconCarousel.react";
import { resolveImage } from "config/app.helpers";
import _ from "lodash";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

export default class IconListGallery extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            cards: PropTypes.arrayOf(
                PropTypes.shape({
                    image: PropTypes.object.isRequired,
                    text: PropTypes.string,
                })
            ),
        }).isRequired,
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        imageLibrary: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    }

    handleClick = (index) => {
        this.setState({ index: index });
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "-20px auto",
            width: "100%",
            height: "100%",
            textAlign: "center",
            position: "relative",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            height: isPortrait ? "0" : "",
            paddingBottom: "",
            position: "relative",
            float: "right",
        };
        let captionStyle = {
            position: "relative",
            width: "90%",
            margin: isPortrait ? "30px auto" : "0 auto",
            textAlign: "center",
            fontSize: "0.95em",
        };
        let separatorStyle = {
            height: "32px",
            background: "#ffc900",
            width: "100%",
            marginTop: "-20px",
        };
        let cardCarouselStyle = {
            background: "transparent",
            margin: isPortrait
                ? "-" +
                (48 - (this.props.data.cards.length - 4) * 3) +
                "px auto"
                : "-65px auto 10px auto",
            width: Math.min(100, this.props.data.cards.length * 25) + "%",
        };
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        let carouselData = _.map(this.props.data.cards, (elm) => {
            return resolveImage(elm.image.id, this.props.imageLibrary);
        });
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={title}
                            subtitle={subtitle}
                        />
                        <div style={defaultStyle}>
                            <ImageGroup
                                {...this.props}
                                images={[
                                    this.props.data.cards[this.state.index]
                                        .image,
                                ]}
                                animation="fadeIn,100"
                            />
                            <div style={separatorStyle} />
                            <div style={cardCarouselStyle}>
                                <IconCarousel
                                    key={"slider-" + Math.random()}
                                    carouselItems={carouselData}
                                    autoPlay={false}
                                    arrows={false}
                                    slidesToShow={carouselData.length}
                                    slidesToScroll={1}
                                    changeImg={this.handleClick}
                                />
                            </div>
                            <br />
                            <div style={captionStyle}>
                                <Caption
                                    text={
                                        this.props.data.cards[this.state.index]
                                            .text
                                    }
                                    colors={this.props.colors}
                                    animation="fadeIn,100"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    backgroundColor: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <ImageGroup
                                        {...this.props}
                                        images={[
                                            this.props.data.cards[
                                                this.state.index
                                            ].image,
                                        ]}
                                        animation="fadeIn,100"
                                    />
                                    <div style={separatorStyle} />
                                    <div style={cardCarouselStyle}>
                                        <IconCarousel
                                            key={"slider-" + Math.random()}
                                            carouselItems={carouselData}
                                            autoPlay={false}
                                            arrows={false}
                                            slidesToShow={carouselData.length}
                                            slidesToScroll={1}
                                            changeImg={this.handleClick}
                                        />
                                    </div>
                                    <div style={captionStyle}>
                                        <Caption
                                            text={
                                                this.props.data.cards[
                                                    this.state.index
                                                ].text
                                            }
                                            colors={this.props.colors}
                                            animation="fadeIn,100"
                                        />
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
