import React, { PureComponent } from "react";
import { Segment } from "semantic-ui-react";
import { getAppConfig } from "config/client.config";

export default class GamifixProfileAnalytics extends PureComponent {

    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let pageStyle = {
            container: {
                textAlign: "center"
            },
        };
        let userId = this.props.auth.user.uid;
        return (
            <Segment style={pageStyle.container}>
                <iframe
                    title="Profile"
                    src={`${getAppConfig().apiUrls.gamifixURL}/api/v1/users/${userId}/display`}
                    width={isPortrait ? 380 : 750}
                    height="800"
                    style={{ marginTop: isPortrait ? "5%" : "10%" }}
                    frameBorder="0"
                />
            </Segment>
        );
    }
}

