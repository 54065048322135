import React from 'react';
import { Icon } from "semantic-ui-react";
import { getAppConfig } from "config/client.config";

const NotificationBadge = (props) => {

    let isPortrait = window.innerHeight > window.innerWidth;
    let badgeStyle = {
        position: "absolute",
        float: "right",
        right: isPortrait ? "13.5%" : "20px",
        color: getAppConfig().colors.iconColor,
        fontSize: "19px",
        top: isPortrait ? "29%" : "27%",
        cursor: "pointer"
    };
    let iconStyle = {
        marginLeft: "11px",
        background: getAppConfig().colors.accents.secondary,
        color: getAppConfig().colors.accents.secondaryText,
        top: "-11px",
        width: isPortrait ? "23px" : "22px",
        position: "absolute",
        borderRadius: "50%",
        fontSize: isPortrait ? "0.56em" : "0.56em",
        border: `1.7px solid ${getAppConfig().colors.accents.secondaryText}`
    };

    return (
        <div style={badgeStyle}>
            <Icon
                name="bell"
                onClick={props.toggleDisplayNotification}
            >
                {props.unreadNotification > 0 && <div
                    style={iconStyle}
                >
                    {props.unreadNotification}
                </div>
                }
            </Icon>
        </div>
    )
}

export default NotificationBadge;