import _ from 'lodash'
import {
    getAppConfig
} from "config/client.config";
export const FETCH_TICKET_USER = 'FETCH_TICKET_USER';
const _fetchAllUserTicket = (status, data, err) => {
    return {
        type: FETCH_TICKET_USER,
        data,
        err,
        status
    }
};
const submitTicket = (ticketData) => {
    return (dispatch, getState, Request) => {
        if (!_.isEmpty(ticketData)) {
            return Request.fetch(getAppConfig().endpoints.ticket, {
                body: JSON.stringify(ticketData),
                method: "POST"
            }).catch((err) => {
                return Promise.reject(err);
            });
        } else {
            return Promise.resolve(false)
        }
    }
}

const checkCaptcha = (token) => {
    return (dispatch, getState, Request) => {
        const checkCaptchaPath = getAppConfig().endpoints.checkCaptchaPath;
        return Request.fetch(checkCaptchaPath, {
            method: "POST",
            body: JSON.stringify({
                token: token,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};

const fetchUserTicket = () => {
    return (dispatch, getState, Request) => {
        let feedPath = getAppConfig().endpoints.getuserTicketsPath.replace(
            ":id",
            getState().auth.user.id
        );
        dispatch(_fetchAllUserTicket("fetching", null, null));
        return Request.fetch(feedPath)
            .then(response => {
                return response.json().then(data => {
                    return dispatch(
                        _fetchAllUserTicket("success", { ticket: data }, null)
                    );
                });
            })
            .catch(err => {
                dispatch(_fetchAllUserTicket("error", null, err));
                return Promise.reject(err);
            });
    };
};

class HelpDeskAction {
    static submitTicket = submitTicket;
    static fetchUserTicket = fetchUserTicket;
    static checkCaptcha = checkCaptcha;
}

export default HelpDeskAction;